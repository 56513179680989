import { ChangeEvent, KeyboardEvent, useEffect, useState } from "react";
import { Alert, Button, Col, Container, Form, InputGroup, ListGroup, OverlayTrigger, Pagination, Popover, Row, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Trace } from "../../../utils/models/trace";
import { downloadTraces, getTrace } from "../../../services/traceService";
import { format } from "date-fns";
import { DEFAULT_DATE_FORMAT, DEFAULT_DATE_TIME_FORMAT2, DEFAULT_HOUR_MINUTES_FORMAT, PAGE_SIZE } from "../../../utils/constants";
import FormSelect from "../../../components/@extends/react-bootstrap/FormSelect";
import { FiletypeCsv, List, Search, XCircle } from "react-bootstrap-icons";
import FormControl from "../../../components/@extends/react-bootstrap/FormControl";
import { isNonNull } from "../../../utils/stringUtils";
import DateRange from "../../../components/DateRange/DateRange";
import { listTraceModule } from "../../../services/referentielService";
import { DateRange as DateRangeModel } from "../../../utils/models/base";

const UserTableRow = styled.tr`
    &.override td {
        color: black;
        background-color: lavender;
        font-style: italic;
    }
`;
const ListGroupItem = styled(ListGroup.Item)`
    border-left: none;
    border-right: none;
    border-top: none;
    cursor: pointer;

    &:last-child {
        border-bottom: none;
    }

    &:hover {
        background-color: #ededed;
    }
`;

export const ListTrace = () => {
    const { t } = useTranslation();

    const [searchDetails, handleSearchChange] = useState<string>("");
    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(1);

    const [traces, setTraces] = useState<Trace[]>([]);

    const [availableTraceModules, setAvailableTraceModules] = useState<string[]>([]);
    const [selectedTraceModule, setSelectedTraceModule] = useState<string>("-1");

    // Obtenir la date actuelle
    const currentDate = new Date();
    // Soustraire un an à la date actuelle
    const lastYearDate = new Date(currentDate);
    lastYearDate.setFullYear(currentDate.getFullYear() - 1);
    const [range, setRange] = useState<DateRangeModel>({
        start: format(lastYearDate, DEFAULT_DATE_FORMAT),
        end: format(currentDate, DEFAULT_DATE_FORMAT),
    });

    // Handling pagination
    const [totalPages, setTotalPages] = useState<number>(1);
    const [totalElements, setTotalElements] = useState<number>(1);
    const [errors, setErrors] = useState<boolean>(false);

    const handleSearchUser = (page: number) => {
        const searchVal = searchDetails.trim();
        handleSearchChange(searchVal);
        setErrors(false);

        innerHandleSearchUser(page - 1, searchVal, selectedTraceModule, pageSize, range);
    };

    const innerHandleSearchUser = (page: number, searchVal: string, selectedTraceModule: string, pageSize: number, range: DateRangeModel) => {
        getTrace({
            module: selectedTraceModule,
            user: searchVal,
            page: page,
            pageSize: pageSize,
            start: range.start,
            end: range.end,
        })
            .then((response) => {
                setTraces(response.content);
                setTotalPages(response.totalPages);
                setTotalElements(response.totalElements);
            })
            .catch(() => {
                //TODO Ramener à la page précédente ou page d'erreur
                setErrors(true);
            });
    };

    const downloadTableTraces = () => {
        downloadTraces({
            module: selectedTraceModule,
            user: searchDetails.trim(),
            start: range.start,
            end: range.end,
        })
            .then((response) => {
                response
                    .blob()
                    .then((blob: any) => {
                        let url = window.URL.createObjectURL(blob);
                        let a = document.createElement("a");
                        a.href = url;
                        a.download = "traces-" + format(new Date(), DEFAULT_DATE_FORMAT + "-" + DEFAULT_HOUR_MINUTES_FORMAT) + ".csv";
                        a.click();
                    })
                    .catch((error: any) => {
                        console.log({ error });
                    });
            })
            .catch((e) => {
                //TODO Ramener à la page précédente ou page d'erreur
                console.log(e);
                setErrors(true);
            });
    };

    const handlePageChange = (page: number) => {
        setPage(page);
        handleSearchUser(page);
    };

    useEffect(() => {
        handleSearchUser(page);
    }, [pageSize]);

    useEffect(() => {
        listTraceModule()
            .then((response) => setAvailableTraceModules(response))
            .then((e) => console.log(e));
    }, []);

    let isPageNumberOutOfRange: boolean = false;
    const pageNumbers = [...new Array(totalPages)].map((_, index) => {
        const pageNumber = index + 1;
        const isPageNumberFirst = pageNumber === 1;
        const isPageNumberLast = pageNumber === totalPages;
        const isCurrentPageWithinMorePageNumbers = Math.abs(pageNumber - page) <= 4;

        if (isPageNumberFirst || isPageNumberLast || isCurrentPageWithinMorePageNumbers) {
            isPageNumberOutOfRange = false;

            if (pageNumber === page) {
                return (
                    <Pagination.Item key={pageNumber} active={true}>
                        {pageNumber}
                    </Pagination.Item>
                );
            } else {
                return (
                    <Pagination.Item key={pageNumber} onClick={() => handlePageChange(pageNumber)}>
                        {pageNumber}
                    </Pagination.Item>
                );
            }
        }

        if (!isPageNumberOutOfRange) {
            isPageNumberOutOfRange = true;
            return <Pagination.Ellipsis key={pageNumber} className="muted" />;
        }

        return null;
    });

    const handlePageSizeChange = (event: ChangeEvent<HTMLSelectElement>): void => {
        setPage(1);
        const selectedOption = event.target.selectedOptions[0].value;
        setPageSize(parseInt(selectedOption));
    };

    function handleSearchKeyUp(event: KeyboardEvent<HTMLInputElement>): void {
        if (event.code === "Enter" && isNonNull(searchDetails)) {
            setPage(1);
            handleSearchUser(1);
        }
    }

    const handleModuleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedOption = event.target.selectedOptions[0];
        const optionValue = selectedOption.value || "0";

        setSelectedTraceModule(optionValue);
    };

    const resetTable = () => {
        setPage(1);
        handleSearchChange("");
        setErrors(false);
        innerHandleSearchUser(0, "", selectedTraceModule, pageSize, range);
    };

    const popoverBottom = (
        <Popover>
            <Popover.Body className="p-0">
                <ListGroup>
                    <ListGroupItem className="bt-0 bx-0" onClick={() => downloadTableTraces()}>
                        <FiletypeCsv className="me-1" />
                        {t("page.administration.traces.table.download")}
                    </ListGroupItem>
                </ListGroup>
            </Popover.Body>
        </Popover>
    );

    return (
        <Container fluid="lg">
            <Row className="text-center mb-3">
                <h1>{t("page.administration.traces.mainTitle")}</h1>
            </Row>

            <Row className="sub-body-container pt-1">
                <Alert variant="danger" show={errors} onClose={() => setErrors(false)} dismissible>
                    {t("errors.display.data")}
                </Alert>

                <Col className="pe-0">
                    <Row className="py-2 me-0">
                        <Col xs={12} md={{ offset: 9, span: 3 }} className="p-2">
                            <OverlayTrigger trigger={["click"]} placement="left" overlay={popoverBottom} rootClose>
                                <List size={22} style={{ cursor: "pointer" }} className="float-end" />
                            </OverlayTrigger>
                        </Col>
                    </Row>

                    <Row>
                        <Form.Group className="mb-3" as={Col} md="3" sm={12}>
                            <Form.Label className="mb-0 fw-bolder">{t("page.administration.traces.forms.field.module.label")}</Form.Label>
                            <Form.Text className="text-muted mt-0 mb-1 d-block">
                                {t("page.administration.traces.forms.field.module.description")}
                            </Form.Text>
                            <FormSelect className="w-100" name="module" onChange={handleModuleChange}>
                                {availableTraceModules.length > 1 && (
                                    <option value="-1">{t("page.administration.traces.forms.field.module.option.all")}</option>
                                )}
                                {availableTraceModules.map((itModule) => (
                                    <option key={itModule} value={itModule}>
                                        {itModule}
                                    </option>
                                ))}
                            </FormSelect>
                        </Form.Group>

                        <Form.Group className="mb-3" as={Col} md="3" sm={12}>
                            <DateRange
                                label={t("page.administration.traces.forms.field.dateRange.label")}
                                description={t("page.administration.traces.forms.field.dateRange.description")}
                                startDate={range.start}
                                endDate={range.end}
                                onRangeChange={(start, end) => setRange({ start, end })}
                            />
                        </Form.Group>

                        <Col xs={12} md={{ offset: 0, span: 6 }}>
                            <Form.Label className="mb-0 fw-bolder"></Form.Label>
                            <Form.Text className="text-muted mt-0 mb-1 d-block">
                                {t("page.administration.traces.forms.field.search.description")}
                            </Form.Text>
                            <InputGroup>
                                <FormControl
                                    className="b-0 no-focus header-search"
                                    placeholder={t("page.administration.traces.forms.field.search.label")}
                                    aria-label={t("page.administration.traces.forms.field.search.label")}
                                    type="text"
                                    name="searchDetails"
                                    value={searchDetails}
                                    onChange={(e) => handleSearchChange(e.target.value)}
                                    onKeyUp={handleSearchKeyUp}
                                />
                                <Button
                                    variant="primary"
                                    onClick={() => {
                                        setPage(1);
                                        handleSearchUser(1);
                                    }}
                                >
                                    <Search className="m-1" />
                                </Button>
                                <Button variant="dark" onClick={() => resetTable()}>
                                    <XCircle className="m-1" />
                                </Button>
                            </InputGroup>
                        </Col>
                    </Row>
                </Col>

                <div className="table-responsive mt-2">
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>{t("page.administration.traces.table.columns.module")}</th>
                                <th>{t("page.administration.traces.table.columns.agent")}</th>
                                <th>{t("page.administration.traces.table.columns.date")}</th>
                                <th>{t("page.administration.traces.table.columns.description")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {traces.map((itTrace, index) => (
                                <UserTableRow key={index}>
                                    <td>{pageSize * (page - 1) + (index + 1)}</td>
                                    <td>{itTrace.module}</td>
                                    <td>{itTrace.userString}</td>
                                    <td>{format(new Date(itTrace.date), DEFAULT_DATE_TIME_FORMAT2)}</td>
                                    <td>{itTrace.details}</td>
                                </UserTableRow>
                            ))}
                        </tbody>
                    </Table>
                </div>

                <Row>
                    <Col md={4}>
                        <Row>
                            <div className="justify-content-start">
                                <FormSelect defaultValue={pageSize} onChange={handlePageSizeChange}>
                                    {PAGE_SIZE.map((itemPage) => (
                                        <option key={itemPage} value={itemPage}>
                                            {itemPage} {t("globals.tables.rows")}
                                        </option>
                                    ))}
                                </FormSelect>
                            </div>
                        </Row>
                        <Row>
                            <div className="justify-content-start">{t("globals.tables.total-rows", { total: totalElements })}</div>
                        </Row>
                    </Col>
                    <Col>
                        <Pagination className="justify-content-end">
                            <Pagination.First disabled={page <= 1} onClick={() => handlePageChange(1)} />
                            <Pagination.Prev disabled={page <= 1} onClick={() => handlePageChange(page - 1)} />

                            {pageNumbers}

                            <Pagination.Next disabled={page >= totalPages} onClick={() => handlePageChange(page + 1)} />
                            <Pagination.Last disabled={page >= totalPages} onClick={() => handlePageChange(totalPages)} />
                        </Pagination>
                    </Col>
                </Row>
            </Row>
        </Container>
    );
};
