import request from "./apiUtils";
import { API_BASE_URL } from '../utils/constants';
import { SearchQuery } from "../utils/models/user";
import { AssignmentProcessing, CreateAssignmentUser } from "../utils/models/assignment";

export const searchAssignments = (searchRequest: SearchQuery) => {
    return request({
        url: API_BASE_URL + "/admin/assignment/search",
        method: 'POST',
        body: JSON.stringify(searchRequest)
    });
}


export const processingRequest = (processingRequest: AssignmentProcessing) => {
    return request({
        url: API_BASE_URL + "/admin/assignment/processing",
        method: 'POST',
        body: JSON.stringify(processingRequest)
    });
}


export const cancelAssignmentUser = (assignmentId: number) => {
    return request({
        url: API_BASE_URL + "/admin/assignment/cancel",
        method: 'POST',
        body: JSON.stringify({id:assignmentId})
    });
}


export const createAssignment = (userRequest: CreateAssignmentUser) => {
    return request({
        url: API_BASE_URL + "/admin/assignment/create",
        method: 'POST',
        body: JSON.stringify(userRequest)
    });
}


export const assignmentUserStatus = (id: string) => {
    return request({
        url: API_BASE_URL + "/admin/assignment/status",
        method: 'POST',
        body: JSON.stringify({id})
    });
}
