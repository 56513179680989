import { Badge, OverlayTrigger, Tooltip } from "react-bootstrap";
import { OverlayInjectedProps } from "react-bootstrap/esm/Overlay";
import { v4 as uuid } from 'uuid';

interface AreaBadgeProps {
    name: string;
}

export const ServiceBadge = ({ name }: AreaBadgeProps) => {

    const unique_id = uuid();
    const renderTooltip = (props: OverlayInjectedProps) => (
        <Tooltip id={unique_id} {...props}>
            Service Reattachment
        </Tooltip>
    );

    return (
        <OverlayTrigger
            placement="bottom"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip}
        >
            {({ ref, ...triggerHandler }) => (
                <Badge bg="primary" {...triggerHandler}>
                    <Badge ref={ref} className="me-1" pill bg="light" text="dark">S</Badge>
                    <span>{name}</span>
                </Badge>

            )}

        </OverlayTrigger>
    );
}