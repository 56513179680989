import { Form } from "react-bootstrap";
import { styled } from "styled-components";

const FormControl = styled(Form.Control)`
    padding: 0.8rem;
    border-radius: 0.375rem 0.375rem 0 0;
    box-shadow: inset 0 -2px 0 0 #3a3a3a;
    background-color: #ededed;
    
    &:not(.textarea) {
      border-right: none;
    }

    &.select-type {
      border-top: 0;
      border-left: 0;
      border-bottom: 0;
      padding: 0.8rem;
    }

    &:focus:not(.no-focus) {
      outline-style: solid !important;
      background-color: #ededed !important;
      box-shadow: inset 0 -2px 0 0 #3a3a3a !important;
      border-color: #dee2e6 !important;
    
      outline-offset: 2px !important;
      outline-width: 2px !important;
      outline-color: #095453 !important;
  }

    &.errors, &.errors:focus:not(.no-focus) {
        outline-style: solid !important;
        background-color: #ededed !important;
        box-shadow: inset 0 -2px 0 0 #3a3a3a !important;
        border-color: #dee2e6 !important;
      
        outline-offset: 2px !important;
        outline-width: 2px !important;
        outline-color: #dc3545 !important;
    }

    &.header-search {
      border: .1px solid var(--bs-border-color);
      box-shadow: inset 0 -2px 0 0 #096665 !important;
      border-bottom: none;
      border-right: none;
      margin-right: 1px;

    }

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
      }
`;



export default FormControl;