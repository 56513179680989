import request from "./apiUtils";
import { API_BASE_URL } from '../utils/constants';
import { TraceDownloadQuery, TraceSearchQuery } from "../utils/models/trace";

export const getTrace = (query: TraceSearchQuery) => {
    return request({
        url: API_BASE_URL + "/admin/trace/search",
        method: 'POST',
        body: JSON.stringify(query)
    });
}


export const downloadTraces = (query: TraceDownloadQuery) => {
    return request({
        url: API_BASE_URL + "/admin/trace/download",
        method: 'POST',
        body: JSON.stringify(query)
    })
};
