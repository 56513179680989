import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { Alert, Badge, Button, Col, Container, Form, Modal, Pagination, Row, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { User } from "../../utils/models/user";
import { isNonEmpty } from "../../utils/stringUtils";
import {
    AppIndicator,
    CheckSquareFill,
    ExclamationCircle,
    EyeFill,
    InfoCircleFill,
    PencilSquare,
    PersonVcard,
    ShieldExclamation,
    XSquareFill,
} from "react-bootstrap-icons";
import { format } from "date-fns";
import { DEFAULT_DATE_FORMAT, DEFAULT_DATE_TIME_FORMAT, GLOBAL_DEFAULT_USER, MAX_PASSWORD, MIN_PASSWORD, PAGE_SIZE } from "../../utils/constants";
import { useUserContext } from "../../context/UserContext";
import { getCurrentUserNotifications, getCurrentUserProfile, updatePass } from "../../services/userService";
import { useNavigate } from "react-router-dom";
import { routes } from "../../utils/routes";
import { Notifications } from "../../utils/models/notification";
import { getOffensesOfAgent } from "../../services/offenseService";
import { OffenseAgent, OffenseType } from "../../utils/models/offense";
import FormSelect from "../../components/@extends/react-bootstrap/FormSelect";
import FormControl from "../../components/@extends/react-bootstrap/FormControl";

const UserAccount = () => {
    const MAX_TITLE_OFFENSE = 50;

    const { t } = useTranslation();
    const navigate = useNavigate();

    const [userFetched, setUserFetched] = useState<User>(GLOBAL_DEFAULT_USER);
    const { user } = useUserContext();

    //Password
    const [editPassword, setEditPassword] = useState<boolean>(false);
    const [hasValidPassForm, setHasValidPassForm] = useState<boolean>(false);
    const [currentPassword, setCurrentPassword] = useState<string>("");
    const [password1, setPassword1] = useState<string>("");
    const [password2, setPassword2] = useState<string>("");

    useEffect(() => {
        if (isNonEmpty(currentPassword) && isNonEmpty(password1) && isNonEmpty(password2)) {
            if (password1 === password2 && password1.length >= MIN_PASSWORD && password1.length <= MAX_PASSWORD) {
                setHasValidPassForm(true);
            } else {
                setHasValidPassForm(false);
            }
        } else {
            setHasValidPassForm(false);
        }
    }, [currentPassword, password1, password2]);

    const [notifications, setNotifications] = useState<Notifications[]>([]);

    const [offenses, setOffenses] = useState<OffenseAgent[]>([]);
    const [offenseOnModal, setOffenseOnModal] = useState<OffenseAgent | null>(null);

    // Handling pagination
    const [totalPages, setTotalPages] = useState<number>(1);
    const [totalElements, setTotalElements] = useState<number>(1);
    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(1);

    const handlePageChange = (page: number) => {
        setPage(page);
        handleGetOffense(page);
    };
    useEffect(() => {
        handleGetOffense(page);
    }, [pageSize]);

    const handlePageSizeChange = (event: ChangeEvent<HTMLSelectElement>): void => {
        const selectedOption = event.target.selectedOptions[0].value;
        setPage(1);
        setPageSize(parseInt(selectedOption));
    };

    const closeNotification = (notification: Notifications) => {
        console.log({ notification });
    };

    const offenseTypesRowSummary = (offenseTypes: OffenseType[]): string => {
        let offenseTitle = "";

        for (const itemOff of offenseTypes) {
            if (offenseTitle.length + itemOff.name.length <= MAX_TITLE_OFFENSE) {
                if (offenseTitle.length !== 0) {
                    offenseTitle += ", ";
                }
                offenseTitle += itemOff.name;
            } else {
                if (offenseTitle.length !== 0) {
                    offenseTitle += ", ";
                }
                offenseTitle += itemOff.name;
                offenseTitle = offenseTitle.slice(0, MAX_TITLE_OFFENSE - 4);
                offenseTitle += "...";
                break;
            }
        }

        return offenseTitle;
    };

    const handleGetOffense = (page: number) => {
        getOffensesOfAgent({
            search: null,
            page: page - 1,
            pageSize: pageSize,
        })
            .then((response) => {
                setOffenses(response.content);
                setTotalPages(response.totalPages);
                setTotalElements(response.totalElements);
            })
            .catch(() => {
                //TODO: handle error
            });
    };

    useMemo(() => {
        if (user) {
            getCurrentUserProfile()
                .then((response) => {
                    setUserFetched(response);
                })
                .catch(() => {
                    //TODO: handle error
                    navigate(routes.notfound.route);
                });

            getCurrentUserNotifications()
                .then((response: Notifications[]) => {
                    setNotifications(response);
                })
                .catch(() => {
                    //TODO: handle error
                });

            handleGetOffense(page);
        }
    }, [user, navigate]);

    let isPageNumberOutOfRange: boolean = false;
    const pageNumbers = [...new Array(totalPages)].map((_, index) => {
        const pageNumber = index + 1;
        const isPageNumberFirst = pageNumber === 1;
        const isPageNumberLast = pageNumber === totalPages;
        const isCurrentPageWithinMorePageNumbers = Math.abs(pageNumber - page) <= 4;

        if (isPageNumberFirst || isPageNumberLast || isCurrentPageWithinMorePageNumbers) {
            isPageNumberOutOfRange = false;

            if (pageNumber === page) {
                return (
                    <Pagination.Item key={pageNumber} active={true}>
                        {pageNumber}
                    </Pagination.Item>
                );
            } else {
                return (
                    <Pagination.Item key={pageNumber} onClick={() => handlePageChange(pageNumber)}>
                        {pageNumber}
                    </Pagination.Item>
                );
            }
        }

        if (!isPageNumberOutOfRange) {
            isPageNumberOutOfRange = true;
            return <Pagination.Ellipsis key={pageNumber} className="muted" />;
        }

        return null;
    });

    const handleEditPassword = (): void => {
        updatePass({
            currentPassword,
            password1: password1,
            password2: password2,
        })
            .then(() => {
                handleCancelEditPassword();
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const handleCancelEditPassword = () => {
        setEditPassword(false);
        setCurrentPassword("");
        setPassword1("");
        setPassword2("");
    };

    return (
        <Container fluid="lg">
            <Row className="text-center mb-3">
                <Col className="m-auto">
                    <h1>{t("page.user-profile.mainTitle")}</h1>
                </Col>
            </Row>

            {userFetched && isNonEmpty(userFetched.id) && (
                <Row>
                    {notifications && (
                        <Row>
                            <Col xs={12} sm={12} md={12}>
                                {notifications.slice(0, 3).map((notif) => (
                                    <Row key={notif.id}>
                                        <Alert dismissible onClose={() => closeNotification(notif)}>
                                            <InfoCircleFill className="me-1" />
                                            {notif.message}
                                        </Alert>
                                    </Row>
                                ))}
                            </Col>
                        </Row>
                    )}

                    <Row>
                        <Col xs={12} sm={12} md={7}>
                            <Row className="sub-body-container">
                                <Row>
                                    <Col className="mb-3">
                                        <div className="border-bottom fs-5">
                                            <PersonVcard className="me-1" /> {t("page.administration.user.card.civil-status")}
                                        </div>
                                    </Col>
                                </Row>

                                <Row>
                                    <Form.Group className="" as={Col} md="6" sm={12}>
                                        <Form.Label className="mb-0 fw-bolder">{t("page.user-profile.forms.field.lastName.label")}</Form.Label>

                                        <p className="d-block mb-0">{userFetched?.lastName}</p>
                                    </Form.Group>

                                    <Form.Group className="" as={Col} md="6" sm={12}>
                                        <Form.Label className="mb-0 fw-bolder">{t("page.user-profile.forms.field.firstName.label")}</Form.Label>

                                        <p className="d-block">{userFetched.firstName}</p>
                                    </Form.Group>
                                </Row>

                                <Row className="">
                                    <Form.Group className="" as={Col} md="12" sm={12}>
                                        <Form.Label className="mb-0 fw-bolder">{t("page.user-profile.forms.field.email.label")}</Form.Label>
                                        <p className="d-block">{userFetched.email}</p>
                                    </Form.Group>
                                </Row>

                                <Row className="">
                                    {userFetched?.reattachmentArea?.name && (
                                        <Form.Group className="" as={Col} md="6" sm={12}>
                                            <Form.Label className="mb-0 fw-bolder">
                                                {t("page.user-profile.forms.field.reattachment.area.label")}
                                            </Form.Label>
                                            <p className="d-block">
                                                {userFetched.reattachmentArea.name} ({userFetched.reattachmentArea.code})
                                            </p>
                                        </Form.Group>
                                    )}

                                    <Form.Group className="" as={Col} md="6" sm={12}>
                                        <Form.Label className="mb-0 fw-bolder">
                                            {t("page.user-profile.forms.field.reattachment.service.label")}
                                        </Form.Label>
                                        <p className="d-block">
                                            {userFetched?.reattachmentService?.name ? (
                                                <>
                                                    {userFetched.reattachmentService.name} ({userFetched.reattachmentService.code})
                                                </>
                                            ) : (
                                                <>{t("page.user-profile.forms.field.reattachment.any.label")}</>
                                            )}
                                        </p>
                                    </Form.Group>
                                </Row>

                                <Row className="">
                                    <Form.Group className="" as={Col} md="12" sm={12}>
                                        <Form.Label className="mb-0 fw-bolder">{t("page.user-profile.forms.field.password.label")}</Form.Label>
                                        <p className="d-block mb-0 text-secondary">************</p>
                                        <Form.Text className="text-muted fs-6" role="button" onClick={() => setEditPassword(true)}>
                                            <PencilSquare className="me-1" />
                                            <span className="text-decoration-underline">
                                                {t("page.user-profile.forms.field.password.edit.label")}
                                            </span>
                                        </Form.Text>
                                    </Form.Group>
                                </Row>
                            </Row>
                        </Col>

                        <Col xs={12} sm={12} md={{ offset: 1, span: 4 }}>
                            <Row className="sub-body-container">
                                <Row>
                                    <Col className="mb-3">
                                        <div className="border-bottom fs-5">
                                            <AppIndicator className="me-1" />
                                            {t("page.administration.user.card.technical-status")}
                                        </div>
                                    </Col>
                                </Row>

                                <Row>
                                    <label className="fw-bolder">{t("page.user-profile.forms.field.registrationID.label")}</label>
                                    <p>{userFetched.matricule}</p>
                                </Row>

                                <Row>
                                    <Form.Group className="">
                                        <Form.Label className="mb-0 fw-bolder">{t("page.user-profile.forms.field.role.label")}</Form.Label>

                                        <p>
                                            {userFetched.authorities.map((auth) => (
                                                <span key={auth.authority}>{t(`business-domain.user.role.types.${auth.authority}`)}</span>
                                            ))}
                                        </p>
                                    </Form.Group>
                                </Row>

                                <Row>
                                    <label className="fw-bolder">{t("page.user-profile.forms.field.createdAt.label")}</label>
                                    <p>{format(new Date(userFetched.createdAt || ""), DEFAULT_DATE_FORMAT)}</p>
                                </Row>

                                <Row>
                                    <label className="fw-bolder">{t("page.user-profile.forms.field.status.label")}</label>

                                    <p>
                                        {!userFetched.enabled && (
                                            <>
                                                <XSquareFill
                                                    className="me-1"
                                                    color="#dc3545"
                                                    title={t("page.user-profile.forms.field.status.values.locked")}
                                                />
                                                {t("page.user-profile.forms.field.status.values.locked")}
                                            </>
                                        )}
                                        {userFetched.enabled && (
                                            <>
                                                <CheckSquareFill
                                                    className="me-1"
                                                    color="#198754"
                                                    title={t("page.user-profile.forms.field.status.values.enabled")}
                                                />
                                                {t("page.user-profile.forms.field.status.values.enabled")}
                                            </>
                                        )}
                                    </p>
                                </Row>
                            </Row>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} sm={12} md={12}>
                            <Row className="sub-body-container">
                                <Row>
                                    <Col className="mb-3">
                                        <div className="border-bottom fs-5">
                                            <ShieldExclamation className="me-1" />
                                            {t("page.administration.user.card.reported-offenses")}
                                        </div>
                                    </Col>
                                </Row>

                                <Row>
                                    <div className="table-responsive">
                                        <Table striped bordered hover>
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>{t("page.user-profile.table.offenses.columns.date")}</th>
                                                    <th>{t("page.user-profile.table.offenses.columns.vehicle")}</th>
                                                    <th>{t("page.user-profile.table.offenses.columns.location")}</th>
                                                    <th>{t("page.user-profile.table.offenses.columns.offenseTypes")}</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {offenses.length == 0 && (
                                                    <tr>
                                                        <td colSpan={6}>
                                                            <div className="text-center">{t("page.user-profile.table.offenses.empty")}</div>
                                                        </td>
                                                    </tr>
                                                )}

                                                {offenses.length > 0 && (
                                                    <>
                                                        {offenses.map((offense, index) => (
                                                            <tr key={offense.id}>
                                                                <td>{pageSize * (page - 1) + (index + 1)}</td>
                                                                <td>{format(new Date(offense.date || ""), DEFAULT_DATE_FORMAT)}</td>
                                                                <td>{offense.vehicle}</td>
                                                                <td>
                                                                    {offense.city} ({offense.state.label})
                                                                </td>
                                                                <td>{offenseTypesRowSummary(offense.offenseTypes)}</td>
                                                                <td className="text-center">
                                                                    <Button
                                                                        variant="outline-primary"
                                                                        size="sm"
                                                                        onClick={() => setOffenseOnModal(offense)}
                                                                    >
                                                                        <EyeFill />
                                                                    </Button>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </>
                                                )}
                                            </tbody>
                                        </Table>
                                    </div>

                                    <Row>
                                        <Col md={4}>
                                            <Row>
                                                <div className="justify-content-start">
                                                    <FormSelect defaultValue={pageSize} onChange={handlePageSizeChange}>
                                                        {PAGE_SIZE.map((itemPage) => (
                                                            <option key={itemPage} value={itemPage}>
                                                                {itemPage} {t("globals.tables.rows")}
                                                            </option>
                                                        ))}
                                                    </FormSelect>
                                                </div>
                                            </Row>
                                            <Row>
                                                <div className="justify-content-start">
                                                    {t("globals.tables.total-rows", {
                                                        total: totalElements,
                                                    })}
                                                </div>
                                            </Row>
                                        </Col>
                                        <Col>
                                            <Pagination className="justify-content-end">
                                                <Pagination.First disabled={page <= 1} onClick={() => handlePageChange(1)} />
                                                <Pagination.Prev disabled={page <= 1} onClick={() => handlePageChange(page - 1)} />

                                                {pageNumbers}

                                                <Pagination.Next disabled={page >= totalPages} onClick={() => handlePageChange(page + 1)} />
                                                <Pagination.Last disabled={page >= totalPages} onClick={() => handlePageChange(totalPages)} />
                                            </Pagination>
                                        </Col>
                                    </Row>
                                </Row>

                                <Modal show={offenseOnModal != null} onHide={() => setOffenseOnModal(null)} dialogClassName="modal-xl">
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            {t("globals.offense.fine-on-vehicle", {
                                                numberplate: offenseOnModal?.vehicle,
                                            })}
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        {offenseOnModal && (
                                            <>
                                                <Row>
                                                    <Col className="mb-3" md="6" sm={12}>
                                                        <label className="mb-0 fw-bolder">{t("page.user-profile.offense.modal.date.label")}</label>
                                                        <p className="d-block mt-2">
                                                            {format(new Date(offenseOnModal.date || ""), DEFAULT_DATE_TIME_FORMAT)}
                                                        </p>
                                                    </Col>
                                                    <Col className="mb-3" md="6" sm={12}>
                                                        <label className="mb-0 fw-bolder">{t("page.user-profile.offense.modal.driver.label")}</label>
                                                        <p className="d-block mt-2">
                                                            {offenseOnModal.firstName || offenseOnModal.lastName ? (
                                                                <>
                                                                    {offenseOnModal.lastName} {offenseOnModal.firstName}
                                                                </>
                                                            ) : (
                                                                <>{t("No driver information has been provided for this offense.")}</>
                                                            )}
                                                        </p>
                                                    </Col>
                                                </Row>

                                                <Row className="mb-2">
                                                    <label className="mb-0 fw-bolder">{t("page.user-profile.offense.modal.address.label")}</label>
                                                    <p className="d-block mt-2">
                                                        {offenseOnModal.address}, {offenseOnModal.city} - {offenseOnModal.state.label}
                                                    </p>
                                                </Row>
                                                <Row className="mb-2">
                                                    <Col md={12}>
                                                        <label className="mb-0 fw-bolder">
                                                            {t("page.user-profile.offense.modal.offenseTypes.label")}
                                                        </label>
                                                    </Col>

                                                    <Col md={12}>
                                                        {Object.keys(offenseOnModal.mapOffenseTypes ? offenseOnModal.mapOffenseTypes : {}).map(
                                                            (itemLevel) => (
                                                                <Row key={itemLevel}>
                                                                    <Col md={12} className="fst-italic">
                                                                        {itemLevel}
                                                                    </Col>
                                                                    <Col md="12">
                                                                        {offenseOnModal.mapOffenseTypes &&
                                                                            offenseOnModal.mapOffenseTypes[itemLevel].map((itemOffense) => (
                                                                                <Badge pill className="me-2 mb-2" key={itemOffense.id}>
                                                                                    {itemOffense.name}
                                                                                </Badge>
                                                                            ))}
                                                                    </Col>
                                                                </Row>
                                                            )
                                                        )}

                                                        {Object.keys(offenseOnModal.mapOffenseTypes ? offenseOnModal.mapOffenseTypes : {}).length ===
                                                            0 && <>-</>}
                                                    </Col>
                                                </Row>

                                                <Row className="mb-2">
                                                    <label className="mb-0 fw-bolder">{t("page.user-profile.offense.modal.comments.label")}</label>
                                                    <p className="d-block mt-2">
                                                        {offenseOnModal.comment
                                                            ? offenseOnModal.comment
                                                            : t("page.vehicle.report.offense.comment-not-provided")}
                                                    </p>
                                                </Row>
                                            </>
                                        )}
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={() => setOffenseOnModal(null)}>
                                            {t("globals.actions.close")}
                                        </Button>
                                    </Modal.Footer>
                                </Modal>
                            </Row>
                        </Col>
                    </Row>

                    <Modal size="lg" show={editPassword} onHide={() => setEditPassword(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>{t("page.user-profile.modal.edit-password.title")}</Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                            <Row>
                                <Form.Group className="mb-3" as={Col} md="12" sm={12}>
                                    <Form.Label className="mb-0 fw-bolder">{t("page.user-profile.forms.field.password.current.label")}</Form.Label>
                                    <Form.Text className="text-muted mt-0 mb-1 d-block">
                                        {t("page.user-profile.forms.field.password.current.description")}
                                    </Form.Text>
                                    <FormControl
                                        required
                                        type="password"
                                        name="currentPassword"
                                        value={currentPassword}
                                        onChange={(e) => setCurrentPassword(e.target.value)}
                                    />
                                </Form.Group>
                            </Row>

                            <Row>
                                <Form.Group className="mb-3" as={Col} md="12" sm={12}>
                                    <Form.Label className="mb-0 fw-bolder">{t("page.user-profile.forms.field.password.new1.label")}</Form.Label>
                                    <Form.Text className="text-muted mt-0 mb-1 d-block">
                                        {t("page.user-profile.forms.field.password.new1.description")}
                                    </Form.Text>
                                    <FormControl
                                        required
                                        type="password"
                                        name="password1"
                                        value={password1}
                                        onChange={(e) => setPassword1(e.target.value)}
                                    />
                                    <Form.Text className="text-black mt-0 mb-0 d-block fw-bold">
                                        {t("page.user-profile.forms.field.password.new1.required")}
                                    </Form.Text>
                                </Form.Group>
                            </Row>

                            <Row>
                                <Form.Group className="mb-3" as={Col} md="12" sm={12}>
                                    <Form.Label className="mb-0 fw-bolder">{t("page.user-profile.forms.field.password.new2.label")}</Form.Label>
                                    <Form.Text className="text-muted mt-0 mb-1 d-block">
                                        {t("page.user-profile.forms.field.password.new2.description")}
                                    </Form.Text>

                                    <FormControl
                                        required
                                        type="password"
                                        name="password2"
                                        value={password2}
                                        onChange={(e) => setPassword2(e.target.value)}
                                    />
                                    {isNonEmpty(password1) && isNonEmpty(password2) && password1 !== password2 && (
                                        <Form.Text className="fw-bold text-danger mt-2 mb-0 d-block">
                                            <ExclamationCircle className="pe-1 fs-5" />
                                            {t("page.user-profile.forms.field.password.notmatching")}
                                        </Form.Text>
                                    )}
                                </Form.Group>
                            </Row>
                        </Modal.Body>

                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => handleCancelEditPassword()}>
                                Cancel
                            </Button>
                            <Button disabled={!hasValidPassForm} variant="primary" onClick={() => handleEditPassword()}>
                                Validate
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </Row>
            )}
        </Container>
    );
};

export default UserAccount;
