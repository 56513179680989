import { Button, Col, Container, Row } from "react-bootstrap";
import { styled } from "styled-components";
import { devices } from "../../utils/constants";

import ErrorIllustration from "../../assets/error-illustration.png";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { ArrowReturnLeft, Envelope } from "react-bootstrap-icons";

const Logo = styled.img`
    display: block;
    max-width: 400px;
    margin: auto;
    height: auto;

    @media ${devices.md} {
        max-width: 100%;
    }
`;

const TitleH1 = styled.h1`
    text-align: center;
    @media ${devices.md} {
        text-align: initial;
    }
`;

const NotFound = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();

    const status = location.state?.status;
    const from = location.state?.from;
    const qPrevious = location.state?.previous;

    const previousPage = () => {
        if (from) {
            navigate("/owner", { replace: true, state: { tab: qPrevious } });
        } else {
            navigate("/", { replace: true });
        }
    };

    return (
        <Container fluid="lg">
            <Row className="sub-body-container">
                <Row className="mb-4">
                    <Col lg={4} md={3}>
                        <Logo className="tsc-responsive-img tsc-image-header" alt="TSC - error" src={ErrorIllustration} />
                    </Col>

                    <Col className="m-auto" lg={7} md={7}>
                        <Row className="mb-2">
                            <TitleH1>{t("errors.mainTitle")}</TitleH1>
                        </Row>

                        <Row className="fs-5 mb-3">
                            <div>
                                <p className="mb-0">
                                    {t(["errors." + from + ".status." + status, "errors." + from + ".status.default", "errors.default.status"])}
                                </p>
                            </div>
                        </Row>
                    </Col>
                </Row>

                <Row>
                    <Col md={{ offset: 4, span: 4 }} className="mb-3">
                        <Button className="w-100 rounded-0" onClick={previousPage}>
                            <ArrowReturnLeft className="m-1" />
                            {t(["errors." + from + ".previous", "errors.default.previous"])}
                        </Button>
                    </Col>
                </Row>
            </Row>
        </Container>
    );
};

export default NotFound;
