import { Alert, Button, Col, Form, Row } from "react-bootstrap";
import FormControl from "../../../../components/@extends/react-bootstrap/FormControl";
import { useEffect, useState } from "react";
import { User } from "../../../../utils/models/user";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import FormSelect from "../../../../components/@extends/react-bootstrap/FormSelect";
import { GLOBAL_DEFAULT_USER, ROLES } from "../../../../utils/constants";
import { ArrowRepeat, Check2Square, ChevronDoubleLeft, InfoCircleFill, Toggle2Off, Toggle2On } from "react-bootstrap-icons";
import { isNonEmpty } from "../../../../utils/stringUtils";
import { createUser, getUsersRoles } from "../../../../services/userService";
import { getUserAreas } from "../../../../services/reattachmentService";
import { ReattachmentArea, ReattachmentService } from "../../../../utils/models/reattachment";

export const AddUserForm = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();


    const defaultUser = {
        ...GLOBAL_DEFAULT_USER,
        authorities: [{
            authority: ""
        }],

    }
    const [user, setUser] = useState<User>(defaultUser);
    const [updateSuccess, setUpdateSucces] = useState<boolean>(false);
    const [userRoles, setUserRoles] = useState<string[]>([]);
    const [availableUserRoles, setAvailableUserRoles] = useState<string[]>([]);

    const [areas, setAreas] = useState<ReattachmentArea[]>([]);
    const [availableService, setAvailableService] = useState<ReattachmentService[]>([]);
    const [selectedArea, setSelectedArea] = useState<number | null>(null);
    const [selectedService, setSelectedService] = useState<number | null>(null);



    const validateEmail = (email: string): boolean => {
        return isNonEmpty(email) && (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email));
    }

    const setLastNameChange = (value: string) => {
        setUser({ ...user, lastName: value });
    }

    const setFirstNameChange = (value: string) => {
        setUser({ ...user, firstName: value });
    }

    const setEmailChange = (value: string) => {
        setUser({ ...user, email: value });
    }

    const setMatriculeChange = (value: string) => {
        setUser({ ...user, matricule: value });
    }

    const setEnableUser = (value: boolean) => {
        setUser({ ...user, enabled: value });
    }

    const isFormValid = (): boolean => {
        return isNonEmpty(user.email) &&
            isNonEmpty(user.matricule) &&
            isNonEmpty(user.lastName) &&
            isNonEmpty(user.firstName) &&
            isNonEmpty(user.authorities[0].authority)
            && validateEmail(user.email);
    }

    const handleRoleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedOption = event.target.selectedOptions[0];
        const optionValue = selectedOption.value || "0";

        setUser({ ...user, authorities: [{ authority: optionValue }] })
    };

    const handleAreaChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedOption = event.target.selectedOptions[0];
        const optionValue = selectedOption.value || "0";

        setSelectedArea(Number.parseInt(optionValue));
    };

    const handleServiceChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedOption = event.target.selectedOptions[0];
        const optionValue = selectedOption.value || "0";

        setSelectedService(Number.parseInt(optionValue));
    };

    useEffect(() => {
        if (selectedArea != null) {
            const rs = areas.filter(obj => obj.id == selectedArea).shift();
            if (rs != null) {
                setAvailableService(rs.reattachmentService);

                if (rs.reattachmentService == null || rs.reattachmentService.length == 0) {
                    setSelectedService(null);
                    setAvailableUserRoles(userRoles.filter(or => or == ROLES.ROLE_AREA_MANAGER));
                } else {
                    setSelectedService(rs.reattachmentService[0].id);
                    setAvailableUserRoles(userRoles.filter(or => or != ROLES.ROLE_AREA_MANAGER));
                }
            }
        }
    }, [selectedArea]);

    const handleSaveUsers = () => {
        createUser({
            firstName: user.firstName,
            lastName: user.lastName,
            matricule: user.matricule,
            email: user.email,
            role: user.authorities[0].authority,
            enabled: user.enabled,
            reattachmentArea: selectedArea,
            reattachmentService: selectedService
        }).then(response => {
            setUpdateSucces(true);
            setUser(response);
        }).catch((error) => {
            //TODO Ramener à la page précédente ou page d'erreur
            setUpdateSucces(false);
        })
    }

    const handleReset = () => {
        setUpdateSucces(false);
        setUser(defaultUser);
    }

    useEffect(() => {
        getUsersRoles().then(response => {
            setUserRoles(response);
            setAvailableUserRoles(response);
        }).catch((error) => {
            //TODO Ramener à la page précédente ou page d'erreur
        });

        getUserAreas().then(response => {
            setAreas(response);
        }).catch((error) => {
            //TODO Ramener à la page précédente ou page d'erreur
        })
    }, []);


    useEffect(() => {
        if (userRoles && userRoles.length > 0) {
            setUser({ ...user, authorities: [{ authority: userRoles[0] }] });
        }
    }, [userRoles]);

    useEffect(() => {
        if (availableUserRoles && availableUserRoles.length > 0) {
            setUser({ ...user, authorities: [{ authority: availableUserRoles[0] }] })
        }
    }, [availableUserRoles]);

    useEffect(() => {
        if (areas && areas.length > 0) {
            const dfArea = areas[0].id;
            setSelectedArea(dfArea);
        } else {
            setSelectedArea(null);
        }
    }, [areas]);


    return (

        <div>

            <Row>
                <Col className="mb-3">
                    <Alert variant="primary" dismissible>
                        <InfoCircleFill /> {t("page.administration.user-create.forms.tabs.single.description")}
                    </Alert>
                </Col>
            </Row>

            <Row>
                <Form.Group className="mb-3" as={Col} md="6" sm={12}>
                    <Form.Label className="mb-0 fw-bolder">{t("page.administration.user-create.forms.field.lastName.label")}</Form.Label>
                    <Form.Text className="text-muted mt-0 mb-1 d-block">
                        {t("page.administration.user-create.forms.field.lastName.description")}
                    </Form.Text>
                    <FormControl
                        required
                        type="text"
                        name="lastName"
                        value={user?.lastName}
                        onChange={(e) => setLastNameChange(e.target.value)}
                    />

                </Form.Group>

                <Form.Group className="mb-3" as={Col} md="6" sm={12}>
                    <Form.Label className="mb-0 fw-bolder">{t("page.administration.user-create.forms.field.firstName.label")}</Form.Label>
                    <Form.Text className="text-muted mt-0 mb-1 d-block">
                        {t("page.administration.user-create.forms.field.firstName.description")}
                    </Form.Text>
                    <FormControl
                        required
                        type="text"
                        name="firstName"
                        value={user?.firstName}
                        onChange={(e) => setFirstNameChange(e.target.value)}
                    />
                </Form.Group>
            </Row>

            <Row>
                <Form.Group className="mb-3" as={Col} md="6" sm={12}>
                    <Form.Label className="mb-0 fw-bolder">{t("page.administration.user-create.forms.field.registrationID.label")}</Form.Label>
                    <Form.Text className="text-muted mt-0 mb-1 d-block">
                        {t("page.administration.user-create.forms.field.registrationID.description")}
                    </Form.Text>
                    <FormControl
                        required
                        type="text"
                        name="matricule"
                        value={user?.matricule}
                        onChange={(e) => setMatriculeChange(e.target.value)}

                    />


                </Form.Group>

                <Form.Group className="mb-3" as={Col} md="6" sm={12}>
                    <Form.Label className="mb-0 fw-bolder">{t("page.administration.user-create.forms.field.email.label")}</Form.Label>
                    <Form.Text className="text-muted mt-0 mb-1 d-block">
                        {t("page.administration.user-create.forms.field.email.description")}
                    </Form.Text>
                    <FormControl
                        className={!isNonEmpty(user.email) || validateEmail(user.email) ? "" : "errors"}
                        required
                        type="email"
                        name="email"
                        value={user?.email}
                        onChange={(e) => setEmailChange(e.target.value)}

                    />
                </Form.Group>
            </Row>

            <Row className="mt-2">
                <Form.Group className="mb-3" as={Col} md="12" sm={12}>
                    <Form.Label className="mb-0 fw-bolder">{t("page.administration.user-create.forms.field.area.label")}</Form.Label>
                    <Form.Text className="text-muted mt-0 mb-1 d-block">
                        {t("page.administration.user-create.forms.field.area.description")}
                    </Form.Text>
                    <FormSelect className="w-100"
                        name="area"
                        onChange={handleAreaChange}
                    >
                        {areas.map((itArea) => (
                            <option key={itArea.code} value={itArea.id}>
                                {itArea.name}
                            </option>
                        ))}
                    </FormSelect>
                </Form.Group>
            </Row>

            <Row className="mt-2">
                <Form.Group className="mb-3" as={Col} md="12" sm={12}>
                    <Form.Label className="mb-0 fw-bolder">{t("page.administration.user-create.forms.field.service.label")}</Form.Label>
                    <Form.Text className="text-muted mt-0 mb-1 d-block">
                        {t("page.administration.user-create.forms.field.service.description")}
                    </Form.Text>
                    <FormSelect className="w-100"
                        name="service"
                        onChange={handleServiceChange}
                        disabled={setSelectedArea === null || availableService.length === 0}
                    >
                        {availableService.map((itService) => (
                            <option key={itService.code} value={itService.id}>
                                {itService.name}
                            </option>
                        ))}
                    </FormSelect>
                </Form.Group>
            </Row>




            <Row className="mt-2">
                <Form.Group className="mb-3" as={Col} md="12" sm={12}>
                    <Form.Label className="mb-0 fw-bolder">{t("page.administration.user-create.forms.field.role.label")}</Form.Label>
                    <Form.Text className="text-muted mt-0 mb-1 d-block">
                        {t("page.administration.user-create.forms.field.role.description")}
                    </Form.Text>
                    <FormSelect className="w-100"
                        name="role"
                        onChange={handleRoleChange}
                    >
                        {availableUserRoles.map((itRole) => (
                            <option key={itRole} value={itRole}>
                                {t(`business-domain.user.role.types.${itRole}`)}
                            </option>
                        ))}
                    </FormSelect>
                </Form.Group>
            </Row>

            <Row className="mt-2">
                <Form.Group>
                    <Form.Label className="mb-0 fw-bolder">{t("page.administration.user-create.forms.field.status.label")}</Form.Label>
                    <Form.Text className="text-muted mt-0 mb-1 d-block">
                        {t("page.administration.user-create.forms.field.status.description")}
                    </Form.Text>
                    <p>
                        {!user.enabled && (
                            <>
                                <Toggle2Off role="button" className="me-1 fs-1" color="#dc3545" title={t("user.account.locked")}
                                    onClick={() => setEnableUser(true)} />
                                {t("page.administration.user-create.forms.field.status.values.locked")}
                            </>

                        )}
                        {user.enabled && (
                            <>
                                <Toggle2On role="button" className="me-1 fs-1" color="#198754" title={t("user.account.locked")}
                                    onClick={() => setEnableUser(false)} />
                                {t("page.administration.user-create.forms.field.status.values.enabled")}
                            </>

                        )}
                    </p>
                </Form.Group>
            </Row>

            <Row>
                <Col md={{ offset: 2, span: 4 }}>

                    <Button variant="outline-dark" className="w-100 rounded-0 mb-2" onClick={() => navigate(-1)}>
                        <ChevronDoubleLeft className="m-1" />
                        {t("page.administration.user-create.forms.submit.previous.label")}
                    </Button>
                </Col>
                <Col md={{ span: 4 }}>
                    {!updateSuccess && (
                        <Button disabled={!isFormValid()} variant="success" className="w-100 rounded-0 mb-2" onClick={() => handleSaveUsers()}>
                            <Check2Square className="m-1" />
                            {t("page.administration.user-create.forms.submit.save.label")}
                        </Button>
                    )}

                    {updateSuccess && (
                        <Button variant="success" className="w-100 rounded-0 mb-2" onClick={() => handleReset()}>
                            <ArrowRepeat className="m-1" />
                            {t("page.administration.user-create.forms.submit.clear.label")}
                        </Button>
                    )}

                </Col>
            </Row>

            {updateSuccess && (user != null && isNonEmpty(user.id)) && (
                <Row>
                    <Alert variant="success">
                        The user <span className="fw-bold">{user.matricule}</span> has been succesfully created.
                    </Alert>
                </Row>
            )}
        </div>
    );
}