import { Button, Col, Container, Row, Tab, Tabs } from "react-bootstrap"
import { styled } from "styled-components";

// Icons
import { FileEarmarkText, ShieldExclamation } from 'react-bootstrap-icons';

import { useTranslation } from "react-i18next";
import { Vehicle } from "../../../utils/models/base";
import { search } from "../../../services/searchService";
import { useEffect, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";

import Report from "../../../components/Vehicle/Report";
import OffenseReportItem from "../../../components/Offense/OffenseReportItem";
import { getVehicleOffenses } from "../../../services/offenseService";
import { Offense } from "../../../utils/models/offense";
import { routes } from "../../../utils/routes";

const ReportColDetails = styled(Col)`
    margin: auto;
`;

const TabsContainer = styled(Tabs)`
    & li:nth-child(1) .nav-link {
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
    }

    & li:nth-child(2) .nav-link{
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
    }

    & .nav-link:not(.active){
        color: #2b2f328c;
        background-color: #e2e3e5;
    }
`;

export default function VehicleReport() {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();

    const [vehicle, setVehicleInformation] = useState<Vehicle | null>(null);
    const [offenses, setOffenses] = useState<Offense[]>([]);

    useEffect(() => {
        const storedJson = sessionStorage.getItem("formData");

        try {
            if (storedJson) {
                const formData = JSON.parse(storedJson);

                search(formData.query).then(response => {
                    setVehicleInformation(response.vehicle);

                    // Get Offenses
                    getVehicleOffenses({ numberplate: response.vehicle.numberplate }).then(responseB => {
                        setOffenses(responseB);
                    }).catch((error) => {
                        //TODO Ramener à la page précédente ou page d'erreur
                        navigate('/error', { replace: true, state: { from: "search", status: error.message, previous: location?.state?.from } });
                    });

                }).catch((error) => {
                    //TODO Ramener à la page précédente ou page d'erreur
                    navigate('/error', { replace: true, state: { from: "search", status: error.message, previous: location?.state?.from } });
                });
            } else {
                //sessionStorage absent
                //TODO Ramener à la page précédente ou page d'erreur
            }
        } catch (e) {
            //ERR
            //TODO Ramener à la page précédente ou page d'erreur
        }
    }, [location?.state?.from, navigate]);


    function establishOffense(): void {
        navigate(routes.offense.route, { replace: false, state: { vNumberplate: vehicle?.numberplate } });
    }

    return (

        <Container fluid="lg">
            <Row className="text-center mb-3">
                <ReportColDetails>
                    <h1>{t("page.vehicle.report.mainTitle")}</h1>
                </ReportColDetails>
            </Row>

            {vehicle && (
                <Row className="sub-body-container">

                    <TabsContainer
                        defaultActiveKey="report"
                        className="mb-3"
                        justify fill
                        variant="pills"
                    >
                        <Tab eventKey="report" title={<><FileEarmarkText /> {t("page.vehicle.report.tabs.report")} </>}>
                            <Report vehicle={vehicle} />
                        </Tab>
                        <Tab eventKey="offense" title={<><ShieldExclamation /> {t("page.vehicle.report.tabs.offense")} </>}>

                            {offenses.length === 0 && (
                                <div>
                                    <h5>{t('page.vehicle.report.offense.no-offenses')}</h5>
                                </div>
                            )}

                            {offenses.map((offense) => (
                                <OffenseReportItem key={offense.id} offense={offense} />
                            ))}
                        </Tab>

                    </TabsContainer>

                    <Row>
                        <Col>
                            <Button className="mt-4" variant="primary" type="submit" onClick={() => establishOffense()}>
                                {t("navigation.offense.label")}
                            </Button>
                        </Col>
                    </Row>

                </Row>
            )}

        </Container>
    );
}