import { useEffect, useState } from "react";
import { Alert, Button, Card, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { bookmarkOffenseType, fetchAllOffenseGroups } from "../../../../services/offenseService";
import { OffenseGroup, OffenseType } from "../../../../utils/models/offense";
import { InfoCircleFill, StarFill } from "react-bootstrap-icons";


const OffenseTypes = () => {

    const { t } = useTranslation();

    const initialOffenseTypes: Record<number, OffenseType[]> = {};

    const [offenseTypes, setOffenseTypes] = useState(initialOffenseTypes);
    const [offenseGroups, setOffenseGroups] = useState<OffenseGroup[]>([]);

    const handleSearchData = () => {

        fetchAllOffenseGroups().then(response => {
            setOffenseTypes(response.types);
            setOffenseGroups(response.groups);
        }).catch((error) => {
            //TODO Ramener à la page précédente ou page d'erreur
        })
    }



    useEffect(() => {
        handleSearchData();
    }, []);


    const handleBookmark = (itOffTp: OffenseType): void => {

        bookmarkOffenseType({
            id: itOffTp.id,
            bookmark: !itOffTp.bookmark
        }).then(response => {

            const updated: OffenseType = response;
            //2. Remove from list
            const updatedState = { ...offenseTypes }; // Copiez le state

            if (updatedState[updated.group.id]) {
                const updatedOffenseTypes = updatedState[updated.group.id].map((type) => {
                    if (type.id === updated.id) {
                        return updated;
                    }
                    return type;
                });

                updatedState[updated.group.id] = updatedOffenseTypes;

                setOffenseTypes(updatedState); // Mettez à jour le state
            }

        }).catch((error) => {
            //TODO Ramener à la page précédente ou page d'erreur
        })
    }

    return (

        <Container fluid="lg">


            <Row className="text-center mb-3">
                <h1>{t("page.administration.offenseTypes.mainTitle")}</h1>
            </Row>

            <Row className="sub-body-container pt-1">
                <div className="mt-2">
                    <Alert variant="dark">
                        <InfoCircleFill /> Select offense types to highlight <StarFill /> them when reporting an offense.
                    </Alert>
                </div>
                <div className="mt-2">
                    {
                        offenseGroups.map(((itOffLvl) => (
                            <Card key={itOffLvl.id} className="mb-2">
                                <Card.Header>{itOffLvl.name}</Card.Header>

                                <Card.Body>
                                    {
                                        offenseTypes[itOffLvl.id].map(((itOffTp) => (
                                            <Button key={itOffTp.id} variant={itOffTp.bookmark ? "primary" : "outline-primary"}
                                                className="me-2 mb-2"
                                                onClick={() => handleBookmark(itOffTp)}
                                            >
                                                
                                                {itOffTp.bookmark && (
                                                    <><StarFill /> {' '}</>
                                                )}
                                                {itOffTp.name}
                                            </Button>

                                        )))
                                    }

                                </Card.Body>


                            </Card>
                        )))
                    }

                </div>

            </Row>



        </Container >
    )
};

export default OffenseTypes;