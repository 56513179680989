import { Alert, Col, Container, Row } from "react-bootstrap"
import { styled } from "styled-components";

// Icons
import { useTranslation } from "react-i18next";
import { Activity, BootstrapReboot, CaretRightFill, GraphUpArrow, People, PersonAdd, ShieldCheck, ShieldExclamation, Tools } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import { routes } from "../../utils/routes";
import { useUserContext } from "../../context/UserContext";
import { isAdmin } from "../../services/authenticationService";

const AdminMenuItem = styled(Alert)`
    &:hover {
        cursor: pointer;
    }
`

export default function AdminHome() {

    const { t } = useTranslation();
    const navigate = useNavigate();

    const { user } = useUserContext();

    return (

        <Container fluid="lg">
            <Row className="text-center mb-3">
                <h1>{t("page.administration.mainTitle")}</h1>
            </Row>

            <Row className="sub-body-container">

                {isAdmin(user) && (
                    <>
                        <Col xs={12} sm={6} md={4}>
                            <AdminMenuItem variant="light" onClick={e => navigate(routes.administrationStats.route)}>
                                <Row>
                                    <Col className="fw-bold"><GraphUpArrow className="fs-2 me-1" /> {t("page.administration.home.box.statistics.title")}</Col>
                                </Row>
                                <hr />
                                <div className="p-ss0">
                                    {t("page.administration.home.box.statistics.description")}
                                    <div className="text-end">
                                        <CaretRightFill />
                                    </div>
                                </div>
                            </AdminMenuItem>
                        </Col>

                        <Col xs={12} sm={6} md={4}>
                            <AdminMenuItem variant="light" onClick={e => navigate(routes.administrationTraces.route)}>
                                <Row>
                                    <Col className="fw-bold"><Activity className="fs-2 me-1" /> {t("page.administration.home.box.traces.title")}</Col>
                                </Row>
                                <hr />
                                <div className="p-ss0">
                                    {t("page.administration.home.box.traces.description")}
                                    <div className="text-end">
                                        <CaretRightFill />
                                    </div>
                                </div>
                            </AdminMenuItem>
                        </Col>
                    </>
                )}

                <Col xs={12} sm={6} md={4}>
                    <AdminMenuItem variant="light" onClick={e => navigate(routes.administrationAssignment.route)}>
                        <Row>
                            <Col className="fw-bold"><BootstrapReboot className="fs-2 me-1" /> {t("page.administration.home.box.assignment.title")}</Col>
                        </Row>
                        <hr />
                        <div className="p-ss0">
                            {t("page.administration.home.box.assignment.description")}
                            <div className="text-end">
                                <CaretRightFill />
                            </div>
                        </div>
                    </AdminMenuItem>
                </Col>

                <Col xs={12} sm={6} md={4}>
                    <AdminMenuItem variant="light" onClick={e => navigate(routes.administrationUsers.route)}>
                        <Row>
                            <Col className="fw-bold"><People className="fs-2 me-1" /> {t("page.administration.home.box.users.title")}</Col>
                        </Row>
                        <hr />
                        <div className="p-ss0">
                            {t("page.administration.home.box.users.description")}
                            <div className="text-end">
                                <CaretRightFill />
                            </div>
                        </div>
                    </AdminMenuItem>
                </Col>

                <Col xs={12} sm={6} md={4}>
                    <AdminMenuItem variant="light" onClick={e => navigate(routes.administrationUserCreation.route)}>
                        <Row>
                            <Col className="fw-bold"><PersonAdd className="fs-2 me-1" /> {t("page.administration.home.box.create-users.title")}</Col>
                        </Row>
                        <hr />
                        <div className="p-ss0">
                            {t("page.administration.home.box.create-users.description")}
                            <div className="text-end">
                                <CaretRightFill />
                            </div>
                        </div>
                    </AdminMenuItem>
                </Col>



                <Col xs={12} sm={6} md={4} className="d-none">
                    <AdminMenuItem variant="light" onClick={e => navigate(routes.administrationOffenseTypes.route)}>
                        <Row>
                            <Col className="fw-bold"><ShieldExclamation className="fs-2 me-1" /> {t("page.administration.home.box.offense-types.title")}</Col>
                        </Row>
                        <hr />
                        <div className="p-ss0">
                            {t("page.administration.home.box.offense-types.description")}
                            <div className="text-end">
                                <CaretRightFill />
                            </div>
                        </div>
                    </AdminMenuItem>
                </Col>





            </Row>

        </Container>
    );
}