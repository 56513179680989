import { ReactNode, createContext, useContext, useState } from "react";
import { CurrentUserStorage } from "../utils/models/user";

interface IUserContext {
    user: CurrentUserStorage | null;
    userContextLogin: (userData: CurrentUserStorage) => void;
    userContextLogout: () => void;
}

const defaultState: IUserContext = {
    user: null,
    userContextLogin: () => {},
    userContextLogout: () => {},
};

// Créez un context pour stocker les informations de l'utilisateur
const UserContext = createContext<IUserContext>(defaultState);

// Créez un fournisseur (Provider) pour envelopper votre application
export function UserProvider({ children }: Readonly<{ children: ReactNode }>) {
    const [user, setUser] = useState<CurrentUserStorage | null>(null);

    // Vous pouvez ajouter des fonctions pour définir ou mettre à jour l'utilisateur ici
    const userContextLogin = (userData: CurrentUserStorage) => {
        setUser(userData);
    };

    const userContextLogout = () => {
        setUser(null);
    };

    return <UserContext.Provider value={{ user, userContextLogin, userContextLogout }}>{children}</UserContext.Provider>;
}

// Créez un hook pour accéder aux informations de l'utilisateur
export function useUserContext() {
    const context = useContext(UserContext);
    if (!context) {
        throw new Error("useUserContext must be used within a UserProvider");
    }
    return context;
}
