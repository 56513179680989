import { Button, Form, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import FormControl from "../@extends/react-bootstrap/FormControl";
import { useState } from "react";
import { format } from "date-fns";
import { DEFAULT_DATE_FORMAT } from "../../utils/constants";
import * as Yup from "yup";
import { useFormik, Form as FormikForm, FormikProvider } from "formik";

interface DateRangeProps {
    label?: string;
    description?: string;
    startDate?: string;
    endDate?: string;
    onRangeChange: (start: string, end: string) => void;
}
const DateRange = ({ label, description, onRangeChange, startDate, endDate }: DateRangeProps) => {
    const { t } = useTranslation();

    // Obtenir la date actuelle
    const currentDate = new Date(endDate || "");
    // Soustraire un an à la date actuelle
    const lastYearDate = new Date(startDate || currentDate);

    const [show, setShow] = useState(false);
    const [range, setRange] = useState<{ start: string; end: string }>({
        start: format(lastYearDate, DEFAULT_DATE_FORMAT),
        end: format(currentDate, DEFAULT_DATE_FORMAT),
    });

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const searchSchema = Yup.object().shape({
        startDate: Yup.date().required(),
        endDate: Yup.date().required(),
    });

    const formik = useFormik({
        initialValues: {
            startDate: format(lastYearDate, DEFAULT_DATE_FORMAT),
            endDate: format(currentDate, DEFAULT_DATE_FORMAT),
        },
        validationSchema: searchSchema,
        validate: (values) => {
            const dateS = new Date(values.startDate);
            const dateE = new Date(values.endDate);

            let errors = {};

            if (isNaN(dateS.getTime())) {
                errors = { ...errors, startDate: t("invalid-date-format") };
            } else if (isNaN(dateE.getTime())) {
                errors = { ...errors, endDate: t("invalid-date-format") };
            } else if (dateS.getTime() >= dateE.getTime()) {
                errors = { ...errors, endDate: t("startDate-after-or-equal-endDate") };
                errors = {
                    ...errors,
                    startDate: t("startDate-after-or-equal-endDate"),
                };
            } else {
                const timeDifference = dateE.getTime() - dateS.getTime();
                const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

                if (daysDifference > 365) {
                    errors = {
                        ...errors,
                        endDate: t("startDate-endDate-more-than-a-year"),
                    };
                }
            }
            return errors;
        },

        onSubmit: (values, actions) => {
            actions.setSubmitting(false);
            setRange({
                start: values.startDate,
                end: values.endDate,
            });
            onRangeChange(values.startDate, values.endDate);
            handleClose();
        },
    });

    const { errors, values, handleSubmit, handleChange } = formik;

    const handleModalSubmit = () => {
        handleSubmit();
    };

    return (
        <>
            <>
                {label && <Form.Label className="mb-0 fw-bolder">{label}</Form.Label>}
                {description && <Form.Text className="text-muted mt-0 mb-1 d-block">{description}</Form.Text>}
                <FormControl
                    className="text-center"
                    type="text"
                    name="dateRange"
                    value={`${range.start} - ${range.end}`}
                    readOnly
                    onClick={handleShow}
                />
            </>

            <FormikProvider value={formik}>
                <FormikForm autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <Modal show={show} onHide={handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>Modal heading</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Start date</Form.Label>
                                <FormControl
                                    type="date"
                                    placeholder="Start date"
                                    autoFocus
                                    name="startDate"
                                    value={values.startDate}
                                    onChange={handleChange}
                                    isValid={!errors.startDate}
                                    isInvalid={!!errors.startDate}
                                />
                                <Form.Control.Feedback type="invalid">{errors.startDate}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
                                <Form.Label>End date</Form.Label>
                                <FormControl
                                    type="date"
                                    placeholder="End date"
                                    name="endDate"
                                    value={values.endDate}
                                    onChange={handleChange}
                                    isValid={!errors.endDate}
                                    isInvalid={!!errors.endDate}
                                />
                                <Form.Control.Feedback type="invalid">{errors.endDate}</Form.Control.Feedback>
                            </Form.Group>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                {t("globals.actions.close")}
                            </Button>
                            <Button variant="primary" type="submit" disabled={!!Object.keys(errors).length} onClick={handleModalSubmit}>
                                Save Changes
                                {t("globals.actions.save-changes")}
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </FormikForm>
            </FormikProvider>
        </>
    );
};

export default DateRange;
