
const LNG_FR = "fr";
const LNG_EN = "en";
const LNG_SLECTOR = "lng";
export const getLanguage = (): string => {

    let language = localStorage.getItem(LNG_SLECTOR);

    const queryParams = new URLSearchParams(window.location.search);
    const lngParam = queryParams.get(LNG_SLECTOR);

    if (lngParam === LNG_EN || lngParam === LNG_FR) {
        language = lngParam;
        localStorage.setItem(LNG_SLECTOR, language);
    } else if (language !== LNG_EN && language !== LNG_FR) {
        language = LNG_EN;
    }
    console.log({ lngParam, language });


    return language;

}


