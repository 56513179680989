import { API_BASE_URL } from '../utils/constants';
import { AgentVehicleSearch } from '../utils/models/base';
import request from "./apiUtils";

export function search(searchRequest:AgentVehicleSearch) {

    return request({
        url: API_BASE_URL + "/agent/search",
        method: 'POST',
        body: JSON.stringify(searchRequest)
    });
}

export function verifyVinOrNumberplate(searchRequest:AgentVehicleSearch) {

    return request({
        url: API_BASE_URL + "/agent/validate-numberplate",
        method: 'POST',
        body: JSON.stringify(searchRequest)
    });
}
