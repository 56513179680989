import { ACCESS_TOKEN } from '../utils/constants';

export default function request(options: any) {
    let headers = new Headers()

    if (options.headers) {
        headers = options.headers;
    } else {
        headers = new Headers({
            'Content-Type': 'application/json',
        })
    }

    if (localStorage.getItem(ACCESS_TOKEN)) {
        headers.append('Authorization', 'Bearer ' + localStorage.getItem(ACCESS_TOKEN))
    }

    const defaults = { headers: headers };
    options = Object.assign({}, defaults, options);

    return fetch(options.url, options)
        .then((response) => {
            // Vérifiez si la réponse a un en-tête Content-Type
            const contentType = response.headers.get('Content-Type');

            if (response.ok) {
                // Vérifiez si le type de contenu est JSON
                if (contentType && contentType.includes('application/json')) {
                    // La réponse est un JSON, traitez-la en conséquence
                    return response.json();
                } else if (contentType && contentType.includes('application/pdf')) {
                    // La réponse est un PDF, vous pouvez par exemple le télécharger ou l'afficher
                    // en fonction de vos besoins
                    //return response.blob(); // Pour récupérer le contenu en tant que blob PDF
                    return response; // Pour récupérer la réponse sans traitement
                } else if (contentType && contentType.includes('application/csv')) {
                    // La réponse est un tableau de byte, vous pouvez par exemple le télécharger ou l'afficher
                    // en fonction de vos besoins
                    //return response.blob(); // Pour récupérer le contenu en tant que blob 
                    return response; // Pour récupérer la réponse sans traitement
                } else {
                    // Le type de contenu n'est ni JSON ni PDF, gérer les autres cas si nécessaire
                    throw new Error('Type de contenu non pris en charge');
                }
            }
            throw new Error(response.status.toString());
        })
};


