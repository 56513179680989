import request from "./apiUtils";
import { API_BASE_URL } from '../utils/constants';
import { LineStatsDetailsRequest, TopStatsDetailsRequest } from "../utils/models/stats";

export const getAllVehicles = (query: {page: number, pageSize: number}) => {
    return request({
        url: API_BASE_URL + "/dashboard/vehicle/search",
        method: 'POST',
        body: JSON.stringify(query)
    });
}


export const getStatsBox = () => {
    return request({
        url: API_BASE_URL + "/admin/stats/report",
        method: 'POST'
    });
}

export const getDetailledChartLineStats = (query: LineStatsDetailsRequest) => {
    return request({
        url: API_BASE_URL + "/admin/stats/report/details",
        method: 'POST',
        body: JSON.stringify(query)
    });
}

export const getDetailledChartTopStats = (query: TopStatsDetailsRequest) => {
    return request({
        url: API_BASE_URL + "/admin/stats/report/details",
        method: 'POST',
        body: JSON.stringify(query)
    });
}
