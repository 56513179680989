export const MimeType = {
    TEXT_CSV: "text/csv",
    APPLICATION_CSV: "application/csv",
    APPLICATION_EXCEL: "application/vnd.ms-excel",
    TEXT_PLAIN: "text/plain"
}


export const checkFileExtension = (fileName: string, allowedExtensions: string[]) => {
    // Obtenez l'extension du fichier en convertissant la chaîne en minuscules et en divisant par le dernier point.
    const fileExtension = fileName.toLowerCase().split('.').pop();

    // Vérifiez si l'extension est dans le tableau des extensions autorisées.
    if (fileExtension && allowedExtensions.includes(fileExtension)) {
        return true; // L'extension est autorisée.
    } else {
        return false; // L'extension n'est pas autorisée.
    }
}

export const isCsvFile = (file: File): boolean => {
    return checkFileExtension(file.name, ["csv"]) && (
        file.type === MimeType.APPLICATION_CSV ||
        file.type === MimeType.TEXT_CSV ||
        file.type === MimeType.APPLICATION_EXCEL ||
        file.type === MimeType.TEXT_PLAIN
    );

}

export const exportCSV = (csvContent: string, filename: string) => {
    // Créer un objet Blob pour le contenu CSV
    const blob = new Blob([csvContent], { type: 'text/csv' });
    // Créer un objet URL pour le Blob
    const url = window.URL.createObjectURL(blob);
    // Créer un élément d'ancrage pour le téléchargement
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    // Simuler un clic sur le lien pour démarrer le téléchargement
    a.click();

    // Libérer les ressources
    window.URL.revokeObjectURL(url);
};
