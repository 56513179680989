export const isNumber = (variable: any): boolean => {
  return typeof variable === "number";
}


export const getRandomNumber = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const numberWithSeparator = (x: number, separator: string) => {
  if (x == undefined || x == null) {
    x = 0;
  }
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator);
}


export const toPercentage = (x: number) => {
  if (x == undefined || x == null) {
    x = 0;
  }
  return x.toFixed(2);
}
