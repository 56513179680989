import { Alert, Button, Col, Collapse, Container, Form, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { User } from "../../../utils/models/user";
import { routes } from "../../../utils/routes";
import { useEffect, useState } from "react";
import { areaAssignment, getUser } from "../../../services/userService";
import { cancelAssignmentUser, assignmentUserStatus } from "../../../services/assignmentService";
import { format } from "date-fns";
import { DEFAULT_DATE_FORMAT, GLOBAL_DEFAULT_USER } from "../../../utils/constants";
import {
    AppIndicator,
    ArrowReturnLeft,
    BootstrapReboot,
    Check2All,
    CheckSquareFill,
    ChevronDoubleLeft,
    PersonVcard,
    XSquareFill,
} from "react-bootstrap-icons";
import { isNonEmpty } from "../../../utils/stringUtils";
import { ReattachmentArea, ReattachmentService } from "../../../utils/models/reattachment";
import { buildListAreas } from "../../../services/reattachmentService";
import FormSelect from "../../../components/@extends/react-bootstrap/FormSelect";
import FormControl from "../../../components/@extends/react-bootstrap/FormControl";

import { useFormik, FormikProvider } from "formik";
import * as Yup from "yup";
import { useUserContext } from "../../../context/UserContext";
import { AssignmentStatus, CreateAssignmentUser } from "../../../utils/models/assignment";
import { createAssignment } from "../../../services/assignmentService";

const ProfileVisualize = () => {
    const { user } = useUserContext();

    const [userProfile, setUser] = useState<User>(GLOBAL_DEFAULT_USER);

    const [confirmAssignment, setConfirmAssignment] = useState(false);
    const [assignmentModal, setAssignmentModal] = useState<boolean>(false);
    const [confirmCancelAssignment, setConfirmCancelAssignment] = useState<boolean>(false);
    const [areas, setAreas] = useState<ReattachmentArea[]>([]);
    const [availableService, setAvailableService] = useState<ReattachmentService[]>([]);
    const [assignmentUserRequest, setAssignmentUserRequest] = useState<CreateAssignmentUser | null>(null);
    const [assignmentStatus, setAssignmentStatus] = useState<AssignmentStatus | null>(null);

    const [success, setSuccess] = useState<boolean | null>(null);
    const [message, setMessage] = useState<string>("");
    const [reloadUser, setReloadUser] = useState<number>(0);

    const { t } = useTranslation();
    const navigate = useNavigate();

    const { id } = useParams();

    const getAreaLabel = (id: number) => {
        const rs = areas.filter((obj) => obj.id == id).shift();
        return rs?.name;
    };

    const getServiceLabel = (id: number) => {
        if (id == 0) {
            return "N/A";
        }
        const rs = availableService.filter((obj) => obj.id == id).shift();
        return rs?.name;
    };

    const formik = useFormik({
        initialValues: {
            area: 0,
            service: 0,
            comment: "",
        },
        validationSchema: Yup.object().shape({
            area: Yup.number().required(""),
            service: Yup.number(),
            comment: Yup.string().required(""),
        }),
        validate: (values) => {
            let errors = {};
            if (values.service != 0 && userProfile != null && userProfile.reattachmentService?.id == values.service) {
                errors = { ...errors, service: t("Already in this service") };
            }
            return errors;
        },

        onSubmit: (values, actions) => {
            actions.setSubmitting(true);
            setAssignmentUserRequest({
                user: userProfile.id,
                comment: values.comment,
                reattachmentArea: values.area,
                reattachmentService: values.service === 0 ? null : values.service,
            });
            setConfirmAssignment(true);
            actions.setSubmitting(false);
        },
    });

    const { errors, touched, values, handleSubmit, handleChange, resetForm } = formik;

    useEffect(() => {
        if (id) {
            getUser(id)
                .then((response: User) => {
                    setUser(response);
                })
                .catch(() => {
                    navigate(routes.notfound.route);
                });
        } else {
            navigate(routes.notfound.route);
        }
    }, [id, navigate]);

    useEffect(() => {
        assignmentUserStatus(userProfile.id)
            .then((response: AssignmentStatus) => {
                setAssignmentStatus(response);
            })
            .catch((e) => {
                //TODO
            });
    }, [userProfile, success, reloadUser]);

    useEffect(() => {
        areaAssignment()
            .then((response: ReattachmentArea) => {
                setAreas(buildListAreas(response));
            })
            .catch(() => {
                //TODO
            });
    }, []);

    useEffect(() => {
        if (areas && areas.length > 0) {
            const dfArea = areas[0].id;
            values.area = dfArea;
        }
    }, [areas, assignmentModal]);

    useEffect(() => {
        if (values.area != null) {
            const selectedArea = areas.filter((obj) => obj.id == values.area).shift();
            if (selectedArea != null) {
                let services = selectedArea.reattachmentService;
                if (services) services = services.filter((obj) => obj.id !== userProfile.reattachmentService?.id);

                setAvailableService(services);

                if (services == null || services.length == 0) {
                    values.service = 0;
                } else {
                    values.service = services[0].id;
                }
            }
        }
    }, [values.area]);

    const handleModalClose = () => {
        if (confirmAssignment) {
            setConfirmAssignment(false);
        } else {
            setAssignmentModal(false);
            formik.resetForm();
            setAssignmentUserRequest(null);
            setSuccess(null);
            setMessage("");
        }
    };

    const handleCancelAssignment = (assignmentId: number) => {
        cancelAssignmentUser(assignmentId)
            .then((response: boolean) => {
                if (response) {
                    setReloadUser(reloadUser + 1);
                    setSuccess(true);
                    setMessage(t("page.administration.user-profile.assignment.cancel.success"));
                    reset();
                } else {
                }
            })
            .catch((e) => {
                //TODO
                console.log(e);
            });
    };

    const handleModalSubmit = () => {
        if (!confirmAssignment) {
            handleSubmit();
        } else {
            if (assignmentUserRequest != null) {
                createAssignment(assignmentUserRequest)
                    .then((response) => {
                        setSuccess(true);
                        setMessage(t("page.administration.user-profile.assignment.success"));
                        setAssignmentModal(false);
                        setReloadUser(reloadUser + 1);
                        setConfirmAssignment(false);
                    })
                    .catch(() => {
                        setSuccess(false);
                        setMessage(t("page.administration.user-profile.assignment.error"));
                        setAssignmentModal(false);
                        setReloadUser(reloadUser + 1);
                        setConfirmAssignment(false);
                    });
            } else {
                setSuccess(false);
                setMessage(t("page.administration.user-profile.assignment.runtime.error"));
            }
        }
    };

    const reset = () => {
        setAssignmentStatus(null);
        setConfirmCancelAssignment(false);
        setAssignmentModal(false);
        setConfirmAssignment(false);
        resetForm();
    };

    return (
        <Container fluid="lg">
            <Row className="text-center mb-3">
                <h1>{t("page.administration.user-profile.mainTitle")}</h1>
            </Row>

            {userProfile && isNonEmpty(userProfile.id) && (
                <>
                    {success !== null && isNonEmpty(message) && (
                        <Row className="sub-body-container">
                            {success === true && <Alert variant="success">{message}</Alert>}
                            {success === false && <Alert variant="danger">{message}</Alert>}
                        </Row>
                    )}
                    <Row>
                        <Col xs={12} sm={12} md={7}>
                            <Row className="sub-body-container">
                                <Row>
                                    <Col className="mb-3">
                                        <div className="border-bottom fs-5">
                                            <PersonVcard className="me-1" />
                                            {t("page.administration.user.card.civil-status")}
                                        </div>
                                    </Col>
                                </Row>

                                <Row>
                                    <Form.Group className="mb-1" as={Col} md="6" sm={12}>
                                        <Form.Label className="mb-0 fw-bolder">
                                            {t("page.administration.user-create.forms.field.lastName.label")}
                                        </Form.Label>
                                        <p className="d-block">{userProfile?.lastName}</p>
                                    </Form.Group>

                                    <Form.Group className="mb-1" as={Col} md="6" sm={12}>
                                        <Form.Label className="mb-0 fw-bolder">
                                            {t("page.administration.user-create.forms.field.firstName.label")}
                                        </Form.Label>

                                        <p className="d-block">{userProfile?.firstName}</p>
                                    </Form.Group>
                                </Row>

                                <Row className="">
                                    <Form.Group className="" as={Col} md="12" sm={12}>
                                        <Form.Label className="mb-0 fw-bolder">
                                            {t("page.administration.user-create.forms.field.email.label")}
                                        </Form.Label>
                                        <p className="d-block">{userProfile?.email}</p>
                                    </Form.Group>
                                </Row>

                                {userProfile?.reattachmentArea?.name && (
                                    <Row className="">
                                        <Form.Group className="" as={Col} md="12" sm={12}>
                                            <Form.Label className="mb-0 fw-bolder">
                                                {t("page.administration.user-create.forms.field.reattachment.area.label")}
                                            </Form.Label>
                                            <p className="d-block">
                                                {userProfile.reattachmentArea.name} ({userProfile.reattachmentArea.code})
                                            </p>
                                        </Form.Group>
                                    </Row>
                                )}

                                <Row className="">
                                    <Form.Group className="" as={Col} md="12" sm={12}>
                                        <Form.Label className="mb-0 fw-bolder">
                                            {t("page.administration.user-create.forms.field.reattachment.service.label")}
                                        </Form.Label>
                                        <p className="d-block">
                                            {userProfile?.reattachmentService?.name ? (
                                                <>
                                                    {userProfile.reattachmentService.name} ({userProfile.reattachmentService.code})
                                                </>
                                            ) : (
                                                <>{t("page.administration.user-create.forms.field.reattachment.any.label")}</>
                                            )}
                                        </p>
                                    </Form.Group>
                                </Row>
                            </Row>
                        </Col>

                        <Col xs={12} sm={12} md={{ offset: 1, span: 4 }}>
                            <Row className="sub-body-container">
                                <Row>
                                    <Col className="mb-3">
                                        <div className="border-bottom fs-5">
                                            <AppIndicator className="me-1" />
                                            {t("page.administration.user.card.technical-status")}
                                        </div>
                                    </Col>
                                </Row>

                                <Row>
                                    <label className="fw-bolder">{t("page.administration.user-create.forms.field.registrationID.label")}</label>
                                    <p>{userProfile?.matricule}</p>
                                </Row>

                                <Row>
                                    <Form.Group className="">
                                        <Form.Label className="mb-0 fw-bolder">
                                            {t("page.administration.user-create.forms.field.role.label")}
                                        </Form.Label>

                                        <p>
                                            {userProfile?.authorities.map((auth) => (
                                                <span key={auth.authority}>{t(`business-domain.user.role.types.${auth.authority}`)}</span>
                                            ))}
                                        </p>
                                    </Form.Group>
                                </Row>

                                <Row>
                                    <label className="fw-bolder">{t("page.administration.user-create.forms.field.createdAt.label")}</label>
                                    <p>{format(new Date(userProfile?.createdAt || ""), DEFAULT_DATE_FORMAT)}</p>
                                </Row>

                                <Row>
                                    <label className="fw-bolder">{t("page.administration.user-create.forms.field.status.label")}</label>

                                    <p>
                                        {!userProfile?.enabled && (
                                            <>
                                                <XSquareFill
                                                    className="me-1"
                                                    color="#dc3545"
                                                    title={t("page.administration.user-create.forms.field.status.values.locked")}
                                                />
                                                {t("page.administration.user-create.forms.field.status.values.locked")}
                                            </>
                                        )}
                                        {userProfile?.enabled && (
                                            <>
                                                <CheckSquareFill
                                                    className="me-1"
                                                    color="#198754"
                                                    title={t("page.administration.user-create.forms.field.status.values.enabled")}
                                                />
                                                {t("page.administration.user-create.forms.field.status.values.enabled")}
                                            </>
                                        )}
                                    </p>
                                </Row>
                            </Row>
                        </Col>
                    </Row>

                    {assignmentStatus && (
                        <Row>
                            <Col xs={12} sm={12} md={12}>
                                <Row className="sub-body-container">
                                    <Row>
                                        <Col className="mb-3">
                                            <div className="border-bottom fs-5">{t("page.administration.user.card.assignment-status")}</div>
                                        </Col>
                                    </Row>

                                    <Row className="">
                                        <Form.Group className="" as={Col} md="6" sm={12}>
                                            <Form.Label className="mb-0 fw-bolder">
                                                {t("page.administration.user-create.forms.field.assignment.area.label")}
                                            </Form.Label>
                                            <p className="d-block">
                                                {assignmentStatus.reattachmentAreaTo?.name} ({assignmentStatus.reattachmentAreaTo?.code})
                                            </p>
                                        </Form.Group>

                                        <Form.Group className="" as={Col} md="6" sm={12}>
                                            <Form.Label className="mb-0 fw-bolder">
                                                {t("page.administration.user-create.forms.field.assignment.service.label")}
                                            </Form.Label>
                                            <p className="d-block">
                                                {assignmentStatus.reattachmentServiceTo ? (
                                                    <>
                                                        {assignmentStatus.reattachmentServiceTo.name} ({assignmentStatus.reattachmentServiceTo.code})
                                                    </>
                                                ) : (
                                                    <>N/A</>
                                                )}
                                            </p>
                                        </Form.Group>
                                    </Row>
                                    <Row>
                                        <Form.Group className="" as={Col} md="6" sm={12}>
                                            <Form.Label className="mb-0 fw-bolder">
                                                {t("page.administration.user-create.forms.field.assignment.manager.label")}
                                            </Form.Label>
                                            <p className="d-block">
                                                {assignmentStatus.manager.firstName} {assignmentStatus.manager.lastName} (
                                                {assignmentStatus.manager.matricule})
                                            </p>
                                        </Form.Group>

                                        <Form.Group className="" as={Col} md="6" sm={12}>
                                            <Form.Label className="mb-0 fw-bolder">
                                                {t("page.administration.user-profile.forms.field.assignment.date.label")}
                                            </Form.Label>
                                            <p className="d-block">{format(new Date(assignmentStatus.dateStart || ""), DEFAULT_DATE_FORMAT)}</p>
                                        </Form.Group>
                                    </Row>

                                    <Row>
                                        <Form.Group className="" as={Col} md="12" sm={12}>
                                            <Form.Label className="mb-0 fw-bolder">
                                                {t("page.administration.user-create.forms.field.assignment.comment.label")}
                                            </Form.Label>
                                            <p className="d-block">{assignmentStatus.commentManager}</p>
                                        </Form.Group>
                                    </Row>
                                </Row>
                            </Col>
                        </Row>
                    )}

                    {user?.id != userProfile.id && (
                        <Row className="sub-body-container">
                            <Col md={{ offset: 2, span: 4 }}>
                                <Button variant="outline-dark" className="w-100 rounded-0 mb-2" onClick={() => navigate(-1)}>
                                    <ChevronDoubleLeft className="m-1" />
                                    {t("globals.actions.previous")}
                                </Button>
                            </Col>

                            <Col md={{ span: 4 }}>
                                {assignmentStatus && (
                                    <>
                                        {!confirmCancelAssignment && (
                                            <Button
                                                variant="warning"
                                                className="w-100 rounded-0 mb-2"
                                                onClick={() => setConfirmCancelAssignment(true)}
                                            >
                                                {t("page.administration.user-profile.actions.cancel.assignment.label")}
                                                <ArrowReturnLeft className="m-1" />
                                            </Button>
                                        )}

                                        {confirmCancelAssignment && (
                                            <Button
                                                variant="outline-danger"
                                                className="w-100 rounded-0 mb-2"
                                                onClick={() => handleCancelAssignment(assignmentStatus.id)}
                                            >
                                                {t("page.administration.user-profile.actions.cancel.assignment.confirm.label")}
                                                <Check2All className="m-1" />
                                            </Button>
                                        )}
                                    </>
                                )}

                                {assignmentStatus == null && (
                                    <Button variant="warning" className="w-100 rounded-0 mb-2" onClick={() => setAssignmentModal(true)}>
                                        {t("page.administration.user-profile.actions.assignment.label")}
                                        <BootstrapReboot className="m-1" />
                                    </Button>
                                )}
                            </Col>
                        </Row>
                    )}
                </>
            )}

            <FormikProvider value={formik}>
                <Form onSubmit={handleSubmit}>
                    <Modal show={assignmentModal} size="lg" centered>
                        <Modal.Header>
                            <Modal.Title>{t("page.administration.user-profile.assignment.modal.title")}</Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                            <Collapse in={!confirmAssignment}>
                                <Row>
                                    <Row className="mt-2">
                                        <Form.Group className="mb-3" as={Col} md="12" sm={12} controlId="validationCustom01">
                                            <Form.Label className="mb-0 fw-bolder">
                                                {t("page.administration.user-profile.assignment.modal.forms.field.area.label")}
                                            </Form.Label>
                                            <Form.Text className="text-muted mt-0 mb-1 d-block">
                                                {t("page.administration.user-profile.assignment.modal.forms.field.area.description")}
                                            </Form.Text>
                                            <FormSelect className="w-100" name="area" onChange={handleChange}>
                                                {areas.map((itArea) => (
                                                    <option key={itArea.code} value={itArea.id}>
                                                        {itArea.name}
                                                    </option>
                                                ))}
                                            </FormSelect>
                                        </Form.Group>
                                    </Row>

                                    <Row className="mt-2">
                                        <Form.Group className="mb-3" as={Col} md="12" sm={12} controlId="validationCustom05">
                                            <Form.Label className="mb-0 fw-bolder">
                                                {t("page.administration.user-profile.assignment.modal.forms.field.service.label")}
                                            </Form.Label>
                                            <Form.Text className="text-muted mt-0 mb-1 d-block">
                                                {t("page.administration.user-profile.assignment.modal.forms.field.service.description")}
                                            </Form.Text>
                                            <FormSelect
                                                className="w-100"
                                                name="service"
                                                onChange={handleChange}
                                                disabled={values.area === null || availableService.length === 0}
                                            >
                                                {availableService.map((itService) => (
                                                    <option key={itService.code} value={itService.id}>
                                                        {itService.name} ({itService.id})
                                                    </option>
                                                ))}
                                            </FormSelect>
                                        </Form.Group>
                                    </Row>
                                    <Row>
                                        <Form.Group className="mb-3" controlId="validationCustom10">
                                            <Form.Label className="mb-0 fw-bolder">
                                                {t("page.administration.user-profile.assignment.modal.forms.field.comment.label")}
                                            </Form.Label>
                                            <Form.Text className="text-muted mt-0 mb-1 d-block">
                                                {t("page.administration.user-profile.assignment.modal.forms.field.comment.description")}
                                            </Form.Text>
                                            <FormControl
                                                className={"w-100 textarea " + `${touched.comment && !!errors.comment ? "errors" : ""}`}
                                                as="textarea"
                                                name="comment"
                                                value={values.comment}
                                                onChange={handleChange}
                                                maxLength={249}
                                            />
                                        </Form.Group>
                                    </Row>
                                </Row>
                            </Collapse>

                            <Collapse in={confirmAssignment}>
                                <Row>
                                    <Row>
                                        <Form.Group className="mb-1" as={Col} md="6" sm={12}>
                                            <Form.Label className="mb-0 fw-bolder">
                                                {t("page.administration.user-profile.assignment.modal.forms.field.area.label")}
                                            </Form.Label>
                                            <p className="d-block">{getAreaLabel(values.area)}</p>
                                        </Form.Group>
                                    </Row>

                                    <Row>
                                        <Form.Group className="mb-1" as={Col} md="6" sm={12}>
                                            <Form.Label className="mb-0 fw-bolder">
                                                {t("page.administration.user-profile.assignment.modal.forms.field.service.label")}
                                            </Form.Label>
                                            <p className="d-block">{getServiceLabel(values.service)}</p>
                                        </Form.Group>
                                    </Row>

                                    <Row>
                                        <Form.Group className="mb-1" as={Col} md="12" sm={12}>
                                            <Form.Label className="mb-0 fw-bolder">
                                                {t("page.administration.user-profile.assignment.modal.forms.field.comment.label")}
                                            </Form.Label>
                                            <p className="d-block">{values.comment}</p>
                                        </Form.Group>
                                    </Row>
                                </Row>
                            </Collapse>
                        </Modal.Body>
                        <Modal.Footer>
                            {confirmAssignment ? (
                                <>
                                    <Button variant="secondary" onClick={() => handleModalClose()}>
                                        Cancel
                                    </Button>
                                    <Button
                                        type="submit"
                                        variant="success"
                                        onClick={() => handleModalSubmit()}
                                        disabled={assignmentUserRequest == null}
                                    >
                                        Confirm
                                    </Button>
                                </>
                            ) : (
                                <>
                                    <Button variant="dark" onClick={() => handleModalClose()}>
                                        {t("globals.actions.close")}
                                    </Button>
                                    <Button type="submit" variant="primary" onClick={() => handleModalSubmit()}>
                                        {t("globals.actions.submit")}
                                    </Button>
                                </>
                            )}
                        </Modal.Footer>
                    </Modal>
                </Form>
            </FormikProvider>
        </Container>
    );
};

export default ProfileVisualize;
