import request from "./apiUtils";
import { ACCESS_TOKEN, API_BASE_URL } from '../utils/constants';
import { CurrentUserStorage, LoginRequest, ResetRequest } from "../utils/models/user";

export function isAuthenticated(): boolean {
    return localStorage.getItem(ACCESS_TOKEN) != null;
}

export function setNotAuthenticated(): void {
    return localStorage.removeItem(ACCESS_TOKEN);
}

export function setIsAuthenticated(token: string): void {
    return localStorage.setItem(ACCESS_TOKEN, token);
}

export function connect(loginRequest: LoginRequest) {
    return request({
        url: API_BASE_URL + "/auth/connect",
        method: 'POST',
        body: JSON.stringify(loginRequest)
    });
}

export function resetPass(loginRequest: ResetRequest) {
    return request({
        url: API_BASE_URL + "/auth/reset-pass",
        method: 'POST',
        body: JSON.stringify(loginRequest)
    });
}

export function disconnect() {
    return request({
        url: API_BASE_URL + "/auth/disconnect",
        method: 'GET',
    });
}

export const isAdmin = (user: CurrentUserStorage | null): boolean => {
    if (user != null)
        return user.canViewAdmin === true;

    return false;
}