import { Accordion, Badge, Col, Container, Row } from "react-bootstrap";
import { styled } from "styled-components";
import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { differenceInMonths, differenceInDays, format } from "date-fns";
import { useLocation, useNavigate } from "react-router-dom";

import { ADMINISTRATIVE_STATUS_TYPE, DEFAULT_DATE_FORMAT } from "../../utils/constants";
import { AdministrativeStatus, Vehicle } from "../../utils/models/base";
import { generateOwnerFullName } from "../../services/reportService";

const TabsParent = styled(Row)`
    border: 1px solid #ededed;
    padding: 15px;

    & .col-value {
        color: #096665;
    }

    & .accordion-button.collapsed {
        background-color: #fff;
    }
`;

const ReportLabel = styled.label`
    font-weight: bold;
`;

const ReportDate = styled.p`
    color: gray;

    & span {
        font-weight: bold;
    }
`;

const PeriodicTechnicalStatus = styled.span`
    border-radius: 5px;
    padding: 3px 10px;

    &.favorable {
        background-color: rgba(55, 214, 90, 0.62);
        color: #444040;
    }
    &.unfavorable {
        background-color: #b34000;
        color: #fff;
    }
`;

// Définition des types pour les propriétés du composant
interface VehicleReportProps {
    vehicle: Vehicle;
}

export default function VehicleReport({ vehicle }: VehicleReportProps) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();

    const [currentOwnerSince, setCurrentOwnerSince] = useState<string>();
    const [currentOwnerFullName, setCurrentOwnerFullName] = useState<string>();
    const [administrativeStatus, setAdministrativeStatus] = useState<any>({});

    const generateAdministrativeStatus = (administrativeStatus: AdministrativeStatus[]) => {
        const administrativeStatusReport: string[] = administrativeStatus.map((item) => item.type.code);
        setAdministrativeStatus({
            //Minimum required values
            PLEDGE: administrativeStatusReport.includes(ADMINISTRATIVE_STATUS_TYPE.PLEDGE),
            SEIZURE_STATEMENT: administrativeStatusReport.includes(ADMINISTRATIVE_STATUS_TYPE.SEIZURE_STATEMENT),
            CRASHED_CAR: administrativeStatusReport.includes(ADMINISTRATIVE_STATUS_TYPE.CRASHED_CAR),
            VEHICLE_DECLARED_STOLEN: administrativeStatusReport.includes(ADMINISTRATIVE_STATUS_TYPE.VEHICLE_DECLARED_STOLEN),
            VEHICLE_DECLARED_LOST: administrativeStatusReport.includes(ADMINISTRATIVE_STATUS_TYPE.VEHICLE_DECLARED_LOST),
            CERTIFICATE_DECLARED_STOLEN: administrativeStatusReport.includes(ADMINISTRATIVE_STATUS_TYPE.CERTIFICATE_DECLARED_STOLEN),
            CERTIFICATE_DECLARED_LOST: administrativeStatusReport.includes(ADMINISTRATIVE_STATUS_TYPE.CERTIFICATE_DECLARED_LOST),
            REGISTRATION_CANCELED: administrativeStatusReport.includes(ADMINISTRATIVE_STATUS_TYPE.REGISTRATION_CANCELED),
            REGISTRATION_SUSPENDED: administrativeStatusReport.includes(ADMINISTRATIVE_STATUS_TYPE.REGISTRATION_SUSPENDED),
            CERTIFICATE_DUPLICATED: administrativeStatusReport.includes(ADMINISTRATIVE_STATUS_TYPE.CERTIFICATE_DUPLICATED),
        });
    };

    const generateSinceCurrentOwnerDate = (vehicle: Vehicle) => {
        const startDate = new Date(vehicle?.currentRegistration);
        const endDate = new Date();

        const monthsDifference = differenceInMonths(endDate, startDate);
        const daysDifference = differenceInDays(endDate, startDate);

        const years = Math.floor(monthsDifference / 12);
        const remainingMonths = monthsDifference % 12;

        let since = "Since ";
        if (years > 0) {
            since += years + " " + (years === 1 ? "year" : "years");
        }
        if (remainingMonths > 0) {
            if (years > 0) {
                since += " and ";
            }
            since += remainingMonths + " " + (remainingMonths === 1 ? "month" : "months");
        } else if (remainingMonths === 0 && daysDifference > 0) {
            if (years > 0) {
                since += " and ";
            }
            since += daysDifference + " " + (daysDifference === 1 ? "day" : "days");
        }
        setCurrentOwnerSince(since);
    };

    useEffect(() => {
        try {
            generateSinceCurrentOwnerDate(vehicle);
            setCurrentOwnerFullName(generateOwnerFullName(vehicle.owner));
            generateAdministrativeStatus(vehicle.administrativeStatus);
        } catch (e) {
            //ERR
            //TODO Ramener à la page précédente ou page d'erreur
        }
    }, [location?.state?.from, navigate, vehicle]);

    return (
        <>
            {vehicle && (
                <Row>
                    <TabsParent className="d-flex justify-content-center">
                        <Container>
                            <ReportDate>
                                {t("page.vehicle.report.situationAt")}
                                <span>
                                    &nbsp;
                                    {format(new Date(vehicle.lastUpdate), DEFAULT_DATE_FORMAT)}
                                </span>
                                .
                            </ReportDate>

                            <Accordion defaultActiveKey={["0"]} alwaysOpen>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>{t("page.vehicle.report.accordions.vehicle-summary")}</Accordion.Header>
                                    <Accordion.Body>
                                        <Row>
                                            <Col>
                                                <ReportLabel>{t("globals.vehicle.model")}</ReportLabel>
                                                <div>
                                                    <p className="mb-0 brand col-value">{vehicle.brand}</p>
                                                    <p className="small col-value">
                                                        Fiscal horsepower : {vehicle.cvPower} hp
                                                        {t("globals.vehicle.model")}
                                                    </p>
                                                </div>
                                            </Col>
                                            <Col>
                                                <ReportLabel>Immatriculation</ReportLabel>
                                                <div>
                                                    <p className="mb-0 col-value">
                                                        {t("globals.vehicle.numberplate")} : {vehicle.numberplate}
                                                    </p>
                                                    <p className="small col-value">
                                                        {t("globals.vehicle.first-registration")}{" "}
                                                        {format(new Date(vehicle.firstRegistration), DEFAULT_DATE_FORMAT)}
                                                    </p>
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col>
                                                <ReportLabel>{t("globals.vehicle.owner")}</ReportLabel>
                                                <div>
                                                    <div>
                                                        <p className="mb-0 col-value">{currentOwnerFullName}</p>
                                                        <p className="small col-value">{currentOwnerSince}</p>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col>
                                                <ReportLabel>{t("globals.vehicle.administrative-status")}</ReportLabel>
                                                <div>
                                                    {vehicle.administrativeStatus.length === 0 && (
                                                        <p>{t("page.vehicle.report.administrativeStatus.global.no-report")}</p>
                                                    )}

                                                    {vehicle.administrativeStatus.length > 0 &&
                                                        vehicle.administrativeStatus.map((item, index) => (
                                                            <Badge key={index} bg="danger" className="me-1">
                                                                {t("page.vehicle.report.administrativeStatus.global." + item.type.code.toLowerCase())}
                                                            </Badge>
                                                        ))}
                                                </div>
                                            </Col>
                                        </Row>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>{t("page.vehicle.report.accordions.vehicle-info")}</Accordion.Header>
                                    <Accordion.Body>
                                        <Row className="mb-3">
                                            <ReportLabel>{t("globals.vehicle.technical-characteristics")}</ReportLabel>
                                        </Row>
                                        <Row className="mb-4">
                                            <Col>
                                                <label>{t("globals.vehicle.brand")}</label>
                                            </Col>
                                            <Col className="col-value">{vehicle.brand}</Col>
                                        </Row>
                                        <Row className="mb-4">
                                            <Col>
                                                <label>{t("globals.vehicle.variant-type-version")}</label>
                                            </Col>
                                            <Col className="col-value">{vehicle.variantType}</Col>
                                        </Row>
                                        <Row className="mb-4">
                                            <Col>
                                                <label>{t("globals.vehicle.numberplate")}</label>
                                            </Col>
                                            <Col className="col-value">{vehicle.numberplate}</Col>
                                        </Row>
                                        <Row className="mb-4">
                                            <Col>
                                                <label>{t("globals.vehicle.vin")}</label>
                                            </Col>
                                            <Col className="col-value">{vehicle.vinSafe}</Col>
                                        </Row>
                                        <Row className="mb-4">
                                            <Col>
                                                <label>{t("globals.vehicle.total-authorized-weight")}</label>
                                            </Col>
                                            <Col className="col-value">{vehicle.totalWeightInLoad}</Col>
                                        </Row>
                                        <Row className="mb-4">
                                            <Col>
                                                <label>{t("globals.vehicle.max-net-power")}</label>
                                            </Col>
                                            <Col className="col-value">{vehicle.maxNetPower}</Col>
                                        </Row>
                                        <Row className="mb-4">
                                            <Col>
                                                <label>{t("globals.vehicle.energy")}</label>
                                            </Col>
                                            <Col className="col-value">{vehicle.energy}</Col>
                                        </Row>
                                        <Row className="mb-4">
                                            <Col>
                                                <label>{t("globals.vehicle.power")}</label>
                                            </Col>
                                            <Col className="col-value">{vehicle.cvPower}</Col>
                                        </Row>
                                        <Row className="mb-4">
                                            <Col>
                                                <label>{t("globals.vehicle.seats")}</label>
                                            </Col>
                                            <Col className="col-value">{vehicle.seats}</Col>
                                        </Row>
                                        <Row className="mb-4">
                                            <Col>
                                                <label>{t("globals.vehicle.standing-places")}</label>
                                            </Col>
                                            <Col className="col-value">{vehicle.standingPlaces}</Col>
                                        </Row>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="2">
                                    <Accordion.Header>{t("page.vehicle.report.accordions.vehicle-owner")}</Accordion.Header>
                                    <Accordion.Body>
                                        <Row>
                                            <Col>
                                                <ReportLabel>{t("globals.vehicle.owner")}</ReportLabel>
                                                <div>
                                                    <p>{t("globals.vehicle.owner-identity")}</p>
                                                    <p>{t("globals.vehicle.owner-address")}</p>
                                                </div>
                                            </Col>
                                            <Col>
                                                <ReportLabel></ReportLabel>
                                                <div className="col-value">
                                                    <p>{currentOwnerFullName}</p>
                                                    <p>{vehicle.owner.address}</p>
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col>
                                                <ReportLabel>{t("globals.vehicle.certificate-registration")}</ReportLabel>
                                                <div>
                                                    <p>{t("globals.vehicle.date-first-registration")}</p>
                                                    <p>{t("globals.vehicle.date-actual-registration")}</p>
                                                </div>
                                            </Col>
                                            <Col>
                                                <ReportLabel></ReportLabel>
                                                <div className="col-value">
                                                    <p>{format(new Date(vehicle.firstRegistration), DEFAULT_DATE_FORMAT)}</p>
                                                    <p>{format(new Date(vehicle.currentRegistration), DEFAULT_DATE_FORMAT)}</p>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="3">
                                    <Accordion.Header>{t("page.vehicle.report.accordions.vehicle-administrative")}</Accordion.Header>
                                    <Accordion.Body>
                                        <Row className="mb-3">
                                            <Col>
                                                <Row>
                                                    <Col>
                                                        <ReportLabel>{t("globals.vehicle.administrative.pledge")}</ReportLabel>
                                                    </Col>
                                                    <Col>
                                                        <div className="col-value">
                                                            <Badge bg={administrativeStatus.PLEDGE ? "danger" : "secondary"}>
                                                                {t("page.vehicle.report.administrativeStatus." + administrativeStatus.PLEDGE)}
                                                            </Badge>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col>
                                                <Row>
                                                    <Col>
                                                        <ReportLabel>{t("globals.vehicle.administrative.declarations-worth-seized")}</ReportLabel>
                                                    </Col>
                                                    <Col>
                                                        <div className="col-value">
                                                            <Badge bg={administrativeStatus.SEIZURE_STATEMENT ? "danger" : "secondary"}>
                                                                {t(
                                                                    "page.vehicle.report.administrativeStatus." +
                                                                        administrativeStatus.SEIZURE_STATEMENT
                                                                )}
                                                            </Badge>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>

                                        <Row className="mb-3">
                                            <Col>
                                                <ReportLabel>{t("globals.vehicle.administrative.opposition.label")}</ReportLabel>
                                                <Row>
                                                    <Col>{t("globals.vehicle.administrative.opposition.crashed-car")}</Col>
                                                    <Col>
                                                        <div className="col-value">
                                                            <Badge bg={administrativeStatus.CRASHED_CAR ? "danger" : "secondary"}>
                                                                {t("page.vehicle.report.administrativeStatus." + administrativeStatus.CRASHED_CAR)}
                                                            </Badge>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col>
                                                <ReportLabel>{t("globals.vehicle.administrative.registration.label")}</ReportLabel>
                                                <Row>
                                                    <Col>{t("globals.vehicle.administrative.registration.suspended")}</Col>
                                                    <Col className="col-value">
                                                        <Badge bg={administrativeStatus.REGISTRATION_SUSPENDED ? "danger" : "secondary"}>
                                                            {t(
                                                                "page.vehicle.report.administrativeStatus." +
                                                                    administrativeStatus.REGISTRATION_SUSPENDED
                                                            )}
                                                        </Badge>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>{t("globals.vehicle.administrative.registration.canceled")}</Col>
                                                    <Col className="col-value">
                                                        <Badge bg={administrativeStatus.REGISTRATION_CANCELED ? "danger" : "secondary"}>
                                                            {t(
                                                                "page.vehicle.report.administrativeStatus." +
                                                                    administrativeStatus.REGISTRATION_CANCELED
                                                            )}
                                                        </Badge>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>

                                        <Row className="mb-3">
                                            <Col>
                                                <ReportLabel>{t("globals.vehicle.administrative.vehicle.label")}</ReportLabel>
                                                <Row>
                                                    <Col>{t("globals.vehicle.administrative.vehicle.declared-stolen")}</Col>
                                                    <Col className="col-value">
                                                        <Badge bg={administrativeStatus.VEHICLE_DECLARED_STOLEN ? "danger" : "secondary"}>
                                                            {t(
                                                                "page.vehicle.report.administrativeStatus." +
                                                                    administrativeStatus.VEHICLE_DECLARED_STOLEN
                                                            )}
                                                        </Badge>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>{t("globals.vehicle.administrative.vehicle.declared-lost")}</Col>
                                                    <Col className="col-value">
                                                        <Badge bg={administrativeStatus.VEHICLE_DECLARED_LOST ? "danger" : "secondary"}>
                                                            {t(
                                                                "page.vehicle.report.administrativeStatus." +
                                                                    administrativeStatus.VEHICLE_DECLARED_LOST
                                                            )}
                                                        </Badge>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col>
                                                <ReportLabel>{t("globals.vehicle.administrative.certificate-registration.label")}</ReportLabel>
                                                <Row>
                                                    <Col>{t("globals.vehicle.administrative.certificate-registration.declared-stolen")}</Col>
                                                    <Col className="col-value">
                                                        <Badge bg={administrativeStatus.CERTIFICATE_DECLARED_STOLEN ? "danger" : "secondary"}>
                                                            {t(
                                                                "page.vehicle.report.administrativeStatus." +
                                                                    administrativeStatus.CERTIFICATE_DECLARED_STOLEN
                                                            )}
                                                        </Badge>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>{t("globals.vehicle.administrative.certificate-registration.declared-lost")}</Col>
                                                    <Col className="col-value">
                                                        <Badge bg={administrativeStatus.CERTIFICATE_DECLARED_LOST ? "danger" : "secondary"}>
                                                            {t(
                                                                "page.vehicle.report.administrativeStatus." +
                                                                    administrativeStatus.CERTIFICATE_DECLARED_LOST
                                                            )}
                                                        </Badge>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>{t("globals.vehicle.administrative.certificate-registration.duplicate")}</Col>
                                                    <Col className="col-value">
                                                        <Badge bg={administrativeStatus.CERTIFICATE_DUPLICATED ? "danger" : "secondary"}>
                                                            {t(
                                                                "page.vehicle.report.administrativeStatus." +
                                                                    administrativeStatus.CERTIFICATE_DUPLICATED
                                                            )}
                                                        </Badge>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="4">
                                    <Accordion.Header>{t("page.vehicle.report.accordions.vehicle-technical")}</Accordion.Header>
                                    <Accordion.Body>
                                        <div>
                                            <Row className="mb-2 fw-bold">
                                                <Col md={2}>{t("globals.vehicle.technical.table.date")}</Col>
                                                <Col md={5}>{t("globals.vehicle.technical.table.type")}</Col>
                                                <Col md={2}>{t("globals.vehicle.technical.table.result")}</Col>
                                                <Col>{t("globals.vehicle.technical.table.mileage")}</Col>
                                            </Row>
                                            {vehicle.technicalControls.map((item, index) => (
                                                <Row key={index} className="mb-3">
                                                    <Col md={2}>{format(new Date(item.date), DEFAULT_DATE_FORMAT)}</Col>
                                                    <Col md={5}>{t("page.vehicle.report.technicalControl." + item.type.code.toLowerCase())}</Col>
                                                    <Col md={2}>
                                                        <PeriodicTechnicalStatus className={item.status.code.toLowerCase()}>
                                                            {t("page.vehicle.report.technicalControlStatus." + item.status.code.toLowerCase())}
                                                        </PeriodicTechnicalStatus>
                                                    </Col>
                                                    <Col>{item.mileage.toLocaleString()} km</Col>
                                                </Row>
                                            ))}

                                            {vehicle.technicalControls.length === 0 && (
                                                <Row className="">
                                                    <Col>{t("globals.vehicle.technical.table.empty")}</Col>
                                                </Row>
                                            )}
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Container>
                    </TabsParent>
                </Row>
            )}
        </>
    );
}
