import { Card, Col, Container, Placeholder, Row } from "react-bootstrap";
import { CarFront, ShieldCheck, ShieldExclamation } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import { LineChart } from "../../../components/Chart/LineChart";
import { DoughnutChart } from "../../../components/Chart/DoughnutChart";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getStatsBox } from "../../../services/statisticService";
import { numberWithSeparator, toPercentage } from "../../../utils/numberUtils";
import { Stats } from "../../../utils/models/stats";
import Chart from "../../../components/Chart/Chart";
import { CHARTS_ID } from "../../../utils/constants";

const StatisticsHome = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [stats, setStats] = useState<Stats>();

    useEffect(() => {
        getStatsBox()
            .then((response) => {
                setStats(response);
            })
            .catch();
    }, [navigate]);

    return (
        <Container fluid="lg">
            <Row className="text-center mb-3">
                <h1>{t("page.administration.stats.mainTitle")}</h1>
            </Row>

            <Row>
                <Col xs={12} md={4} className="pe-0 vehicle-chart">
                    <div className="sub-body-container p-0">
                        <Card className="border-0">
                            <Card.Body className="">
                                <Row>
                                    <Col xs={9} md={9} className="fs-1">
                                        <Card.Title className="fs-2 mt-2">
                                            {stats && <>{numberWithSeparator(stats.vehicles, ",")}</>}

                                            {stats == null && (
                                                <Placeholder as={"span"} animation="glow">
                                                    <Placeholder xs={12} />
                                                </Placeholder>
                                            )}
                                        </Card.Title>
                                        <Card.Subtitle className="mb-2 text-muted ">
                                            {t("page.administration.stats.chart.graph.vehicle.title")}
                                        </Card.Subtitle>
                                    </Col>
                                    <Col xs={3} md={3}>
                                        <span
                                            className="p-3 rounded align-middle d-block text-center"
                                            style={{
                                                backgroundColor: "aliceblue",
                                                color: "rgb(32, 102, 163)",
                                            }}
                                        >
                                            <CarFront className="fs-1" />
                                        </span>
                                    </Col>
                                </Row>

                                <Row className="p-0 m-0 w-100" style={{ color: "rgb(32, 102, 163)" }}>
                                    <Col>
                                        <Card.Text>
                                            {stats && (
                                                <>
                                                    {toPercentage(stats.vehiclesEvolution)}
                                                    {t("page.administration.stats.chart.metadata.chart-time-evolution")}
                                                </>
                                            )}

                                            {stats == null && (
                                                <Placeholder as={"span"} animation="glow">
                                                    <Placeholder xs={12} />
                                                </Placeholder>
                                            )}
                                        </Card.Text>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </div>
                </Col>

                <Col xs={12} md={4} className="pe-0 all-offense-chart">
                    <div className="sub-body-container p-0">
                        <Card className="border-0">
                            <Card.Body className="">
                                <Row>
                                    <Col xs={9} md={9} className="fs-1">
                                        <Card.Title className="fs-2 mt-2">
                                            {stats && <>{numberWithSeparator(stats.allOffenses, ",")}</>}

                                            {stats == null && (
                                                <Placeholder as={"span"} animation="glow">
                                                    <Placeholder xs={12} />
                                                </Placeholder>
                                            )}
                                        </Card.Title>
                                        <Card.Subtitle className="mb-2 text-muted ">
                                            {t("page.administration.stats.chart.graph.all-offenses.title")}
                                        </Card.Subtitle>
                                    </Col>
                                    <Col xs={3} md={3}>
                                        <span
                                            className="p-3 rounded align-middle d-block text-center"
                                            style={{
                                                backgroundColor: "rgb(255, 240, 240)",
                                                color: "rgb(163, 32, 32)",
                                            }}
                                        >
                                            <ShieldExclamation className="fs-1" />
                                        </span>
                                    </Col>
                                </Row>

                                <Row className="p-0 m-0 w-100" style={{ color: "rgb(163, 32, 32)" }}>
                                    <Col>
                                        <Card.Text>
                                            {stats && (
                                                <>
                                                    {toPercentage(stats.allOffensesEvolution)}
                                                    {t("page.administration.stats.chart.metadata.chart-time-evolution")}
                                                </>
                                            )}

                                            {stats == null && (
                                                <Placeholder as={"span"} animation="glow">
                                                    <Placeholder xs={12} />
                                                </Placeholder>
                                            )}
                                        </Card.Text>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </div>
                </Col>

                <Col xs={12} md={4} className="paid-offense-chart">
                    <div className="sub-body-container p-0">
                        <Card className="border-0">
                            <Card.Body className="">
                                <Row>
                                    <Col xs={9} md={9} className="fs-1">
                                        <Card.Title className="fs-2 mt-2">
                                            {stats && <>{numberWithSeparator(stats.paidOffenses, ",")}</>}

                                            {stats == null && (
                                                <Placeholder as={"span"} animation="glow">
                                                    <Placeholder xs={12} />
                                                </Placeholder>
                                            )}
                                        </Card.Title>
                                        <Card.Subtitle className="mb-2 text-muted ">
                                            {t("page.administration.stats.chart.graph.paid-offenses.title")}
                                        </Card.Subtitle>
                                    </Col>
                                    <Col xs={3} md={3}>
                                        <span
                                            className="p-3 rounded align-middle d-block text-center"
                                            style={{
                                                backgroundColor: "rgb(240, 255, 240)",
                                                color: "rgb(58, 163, 32)",
                                            }}
                                        >
                                            <ShieldCheck className="fs-1" />
                                        </span>
                                    </Col>
                                </Row>

                                <Row className="p-0 m-0 w-100" style={{ color: "rgb(58, 163, 32)" }}>
                                    <Col>
                                        <Card.Text>
                                            {stats && (
                                                <>
                                                    {toPercentage(stats.paidOffensesEvolution)}
                                                    {t("page.administration.stats.chart.metadata.chart-time-evolution")}
                                                </>
                                            )}

                                            {stats == null && (
                                                <Placeholder as={"span"} animation="glow">
                                                    <Placeholder xs={12} />
                                                </Placeholder>
                                            )}
                                        </Card.Text>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </div>
                </Col>
            </Row>

            <Row>
                <Col md={8} className="">
                    <div className="sub-body-container p-0">
                        <Chart
                            id={CHARTS_ID.VEHICLE_OFFENSE_PER_MONTH}
                            title={t("page.administration.stats.chart.graph.VOM.title")}
                            description={t("page.administration.stats.chart.graph.VOM.description")}
                        >
                            {stats && (
                                <LineChart
                                    vehicleLineData={stats.vehicleLineData}
                                    paidOffenseLineData={stats.paidOffenseLineData}
                                    offenseLineData={stats.offenseLineData}
                                />
                            )}

                            {stats == null && (
                                <Placeholder as={"span"} animation="glow">
                                    <Placeholder xs={12} />
                                </Placeholder>
                            )}
                        </Chart>
                    </div>
                </Col>
                <Col md={4} className="">
                    <div className="sub-body-container p-0">
                        <Chart
                            id={CHARTS_ID.TOP_OFFENSES_BY_TYPE}
                            title={t("page.administration.stats.chart.graph.TOT.title")}
                            description={t("page.administration.stats.chart.graph.TOT.description")}
                        >
                            {stats && <DoughnutChart data={stats.topOffenseType} />}

                            {stats == null && (
                                <Placeholder as={"span"} animation="glow">
                                    <Placeholder xs={12} />
                                </Placeholder>
                            )}
                        </Chart>
                    </div>
                </Col>
            </Row>

            <Row>
                <Col md={8} className="">
                    <div className="sub-body-container p-0">
                        <Chart
                            id={CHARTS_ID.EVOLUTION_V_O}
                            title={t("page.administration.stats.chart.graph.EVO.title")}
                            description={t("page.administration.stats.chart.graph.EVO.description")}
                        >
                            {stats && (
                                <LineChart
                                    cumulative
                                    vehicleLineData={stats.vehicleLineData}
                                    paidOffenseLineData={stats.paidOffenseLineData}
                                    offenseLineData={stats.offenseLineData}
                                />
                            )}

                            {stats == null && (
                                <Placeholder as={"span"} animation="glow">
                                    <Placeholder xs={12} />
                                </Placeholder>
                            )}
                        </Chart>
                    </div>
                </Col>
                <Col md={4} className="">
                    <div className="sub-body-container p-0">
                        <Chart
                            id={CHARTS_ID.TOP_OFFENSES_BY_CITY}
                            title={t("page.administration.stats.chart.graph.TOC.title")}
                            description={t("page.administration.stats.chart.graph.TOC.description")}
                        >
                            {stats && <DoughnutChart data={stats.topOffenseByState} />}

                            {stats == null && (
                                <Placeholder as={"span"} animation="glow">
                                    <Placeholder xs={12} />
                                </Placeholder>
                            )}
                        </Chart>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default StatisticsHome;
