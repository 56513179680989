import request from "./apiUtils";
import { API_BASE_URL } from '../utils/constants';
import { ReattachmentArea } from "../utils/models/reattachment";

export const getUserAreas = () => {
    return request({
        url: API_BASE_URL + "/reattachment/areas",
        method: 'POST'
    });
}


export const buildListAreas = (area: ReattachmentArea): ReattachmentArea[] => {
    return recursiveGetChildren(area);
}

const recursiveGetChildren = (area: ReattachmentArea): ReattachmentArea[] => {
    let areas: ReattachmentArea[] = [];
    if (area != null) {
        areas.push(area);
        if (area.children != null && area.children.length > 0) {
            area.children.forEach((child) => {
                areas = areas.concat(recursiveGetChildren(child));
            });
        }
    }
    return areas;
}

