import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { User } from "../../../utils/models/user";
import { routes } from "../../../utils/routes";
import { useEffect, useState } from "react";
import { getUser, getUsersRoles, updateUser } from "../../../services/userService";
import FormControl from "../../../components/@extends/react-bootstrap/FormControl";
import { format } from "date-fns";
import { DEFAULT_DATE_FORMAT, GLOBAL_DEFAULT_USER } from "../../../utils/constants";
import { AppIndicator, Check2Square, ChevronDoubleLeft, PersonVcard, Toggle2Off, Toggle2On, XSquare } from "react-bootstrap-icons";
import { isNonEmpty } from "../../../utils/stringUtils";
import FormSelect from "../../../components/@extends/react-bootstrap/FormSelect";
import { getUserAreas } from "../../../services/reattachmentService";
import { ReattachmentArea, ReattachmentService } from "../../../utils/models/reattachment";

const ProfileEdition = () => {
    const [initialUser, setInitialUser] = useState<User>(GLOBAL_DEFAULT_USER);
    const [user, setUser] = useState<User>(GLOBAL_DEFAULT_USER);
    const [userRoles, setUserRoles] = useState<string[]>([]);
    const [hasChanges, setHasChanges] = useState<boolean>(false);
    const [formValid, setFormValid] = useState<boolean>(false);
    const [updateSuccess, setUpdateSucces] = useState<boolean>(false);

    const [areas, setAreas] = useState<ReattachmentArea[]>([]);
    const [availableService, setAvailableService] = useState<ReattachmentService[]>([]);
    const [selectedService, setSelectedService] = useState<ReattachmentService[]>([]);

    const { t } = useTranslation();
    const navigate = useNavigate();

    const { id } = useParams();

    const setLastNameChange = (value: string) => {
        setUser({ ...user, lastName: value });
    };

    const setFirstNameChange = (value: string) => {
        setUser({ ...user, firstName: value });
    };

    const setEnableUser = (value: boolean) => {
        setUser({ ...user, enabled: value });
    };

    const handleRoleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedOption = event.target.selectedOptions[0];
        const optionValue = selectedOption.value || "0";

        setUser({ ...user, authorities: [{ authority: optionValue }] });
    };

    const handleResetUser = () => {
        setUser(initialUser);
    };
    const handleSaveUser = () => {
        updateUser({
            id: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            role: user.authorities[0].authority,
            enabled: user.enabled,
        })
            .then((response) => {
                setUpdateSucces(true);
                setUser(response);
                setInitialUser(response);
            })
            .catch((error) => {
                //TODO Ramener à la page précédente ou page d'erreur
                setUpdateSucces(false);
            });
    };

    const areUsersEqual = (user1: User, user2: User): boolean => {
        return (
            user1.id === user2.id &&
            user1.firstName === user2.firstName &&
            user1.lastName === user2.lastName &&
            user1.enabled === user2.enabled &&
            user1.authorities[0].authority === user2.authorities[0].authority
        );
    };

    useEffect(() => {
        if (id) {
            getUser(id)
                .then((response) => {
                    setUser(response);
                    setInitialUser(response);
                })
                .catch((error) => {
                    //TODO Ramener à la page précédente ou page d'erreur
                });
        } else {
            navigate(routes.notfound.route);
        }

        getUsersRoles()
            .then((response) => {
                setUserRoles(response);
            })
            .catch((error) => {
                //TODO Ramener à la page précédente ou page d'erreur
            });

        getUserAreas()
            .then((response) => {
                setAreas(response);
            })
            .catch((error) => {
                //TODO Ramener à la page précédente ou page d'erreur
            });
    }, [id, navigate]);

    useEffect(() => {
        if (isNonEmpty(user.id) && !areUsersEqual(user, initialUser)) {
            setHasChanges(true);
            setFormValid(isNonEmpty(user.firstName) && isNonEmpty(user.lastName) && isNonEmpty(user.authorities[0].authority));
        } else {
            setHasChanges(false);
        }
    }, [user, initialUser]);

    return (
        <Container fluid="lg">
            <Row className="text-center mb-3">
                <h1>{t("page.administration.users.edition.mainTitle")}</h1>
            </Row>

            {user && isNonEmpty(user.id) && (
                <>
                    <Row>
                        <Col xs={12} sm={12} md={7}>
                            <Row className="sub-body-container">
                                <Row>
                                    <Col className="mb-3">
                                        <div className="border-bottom fs-5">
                                            <PersonVcard className="me-1" />
                                            {t("page.administration.user.card.civil-status")}
                                        </div>
                                    </Col>
                                </Row>

                                <Row>
                                    <Form.Group className="mb-3" as={Col} md="6" sm={12}>
                                        <Form.Label className="mb-0 fw-bolder">
                                            {t("page.administration.user-create.forms.field.lastName.label")}
                                        </Form.Label>

                                        <FormControl
                                            required
                                            type="text"
                                            name="lastName"
                                            value={user?.lastName}
                                            onChange={(e) => setLastNameChange(e.target.value)}
                                        />
                                    </Form.Group>

                                    <Form.Group className="mb-3" as={Col} md="6" sm={12}>
                                        <Form.Label className="mb-0 fw-bolder">
                                            {t("page.administration.user-create.forms.field.lastName.label")}
                                        </Form.Label>
                                        <FormControl
                                            required
                                            type="text"
                                            name="firstName"
                                            value={user?.firstName}
                                            onChange={(e) => setFirstNameChange(e.target.value)}
                                        />
                                    </Form.Group>
                                </Row>

                                <Row className="mt-2">
                                    <Form.Group className="" as={Col} md="12" sm={12}>
                                        <Form.Label className="mb-0 fw-bolder">
                                            {t("page.administration.user-create.forms.field.email.label")}
                                        </Form.Label>
                                        <p className="d-block mt-1">{user?.email}</p>
                                    </Form.Group>
                                </Row>

                                {user?.reattachmentArea?.name && (
                                    <Row className="">
                                        <Form.Group className="" as={Col} md="12" sm={12}>
                                            <Form.Label className="mb-0 fw-bolder">
                                                {t("page.administration.user-create.forms.field.reattachment.area.label")}
                                            </Form.Label>
                                            <p className="d-block mt-1">
                                                {user.reattachmentArea.name} ({user.reattachmentArea.code})
                                            </p>
                                        </Form.Group>
                                    </Row>
                                )}

                                <Row className="">
                                    <Form.Group className="" as={Col} md="12" sm={12}>
                                        <Form.Label className="mb-0 fw-bolder">
                                            {t("page.administration.user-create.forms.field.reattachment.service.label")}
                                        </Form.Label>
                                        <p className="d-block mt-1">
                                            {user?.reattachmentService?.name ? (
                                                <>
                                                    {user.reattachmentService.name} ({user.reattachmentService.code})
                                                </>
                                            ) : (
                                                <>{t("page.administration.user-create.forms.field.reattachment.any.label")}</>
                                            )}
                                        </p>
                                    </Form.Group>
                                </Row>
                            </Row>
                        </Col>

                        <Col xs={12} sm={12} md={{ offset: 1, span: 4 }}>
                            <Row className="sub-body-container">
                                <Row>
                                    <Col className="mb-3">
                                        <div className="border-bottom fs-5">
                                            <AppIndicator className="me-1" />
                                            {t("page.administration.user.card.technical-status")}
                                        </div>
                                    </Col>
                                </Row>

                                <Row>
                                    <label className="fw-bolder">{t("page.administration.user-create.forms.field.registrationID.label")}</label>
                                    <p>{user?.matricule}</p>
                                </Row>

                                <Row>
                                    <Form.Group className="">
                                        <Form.Label className="mb-0 fw-bolder">
                                            {t("page.administration.user-create.forms.field.role.label")}
                                        </Form.Label>

                                        {userRoles.length <= 1 && (
                                            <p>
                                                {user?.authorities.map((auth) => (
                                                    <span key={auth.authority}>{t(`business-domain.user.role.types.${auth.authority}`)}</span>
                                                ))}
                                            </p>
                                        )}

                                        {userRoles.length > 1 && (
                                            <FormSelect
                                                className="w-100 mb-3"
                                                defaultValue={user.authorities[0].authority}
                                                name="role"
                                                onChange={handleRoleChange}
                                            >
                                                {userRoles.map((itRole) => (
                                                    <option key={itRole} value={itRole}>
                                                        {t(`business-domain.user.role.types.${itRole}`)}
                                                    </option>
                                                ))}
                                            </FormSelect>
                                        )}
                                    </Form.Group>
                                </Row>

                                <Row className="mt-0">
                                    <label className="fw-bolder">{t("page.administration.user-create.forms.field.createdAt.label")}</label>
                                    <p>{format(new Date(user?.createdAt || ""), DEFAULT_DATE_FORMAT)}</p>
                                </Row>

                                <Row>
                                    <label className="fw-bolder">{t("page.administration.user-create.forms.field.status.label")}</label>

                                    <p>
                                        {!user?.enabled && (
                                            <>
                                                <Toggle2Off
                                                    role="button"
                                                    className="me-1 fs-1"
                                                    color="#dc3545"
                                                    title={t("page.administration.user-create.forms.field.status.values.locked")}
                                                    onClick={() => setEnableUser(true)}
                                                />
                                                {t("page.administration.user-create.forms.field.status.values.locked")}
                                            </>
                                        )}
                                        {user?.enabled && (
                                            <>
                                                <Toggle2On
                                                    role="button"
                                                    className="me-1 fs-1"
                                                    color="#198754"
                                                    title={t("page.administration.user-create.forms.field.status.values.enabled")}
                                                    onClick={() => setEnableUser(false)}
                                                />
                                                {t("page.administration.user-create.forms.field.status.values.enabled")}
                                            </>
                                        )}
                                    </p>
                                </Row>
                            </Row>
                        </Col>
                    </Row>

                    <Row className="sub-body-container">
                        <Col md={{ offset: 2, span: 4 }}>
                            <Button variant="outline-dark" className="w-100 rounded-0 mb-2" onClick={() => navigate(-1)}>
                                <ChevronDoubleLeft className="m-1" />
                                {t("globals.actions.previous")}
                            </Button>
                        </Col>

                        <Col md={{ span: 4 }}>
                            <Button
                                disabled={!hasChanges || !formValid}
                                variant="success"
                                className="w-100 rounded-0 mb-2"
                                onClick={() => handleSaveUser()}
                            >
                                <Check2Square className="m-1" />
                                {t("globals.actions.save")}
                            </Button>
                        </Col>
                    </Row>
                </>
            )}
        </Container>
    );
};

export default ProfileEdition;
