import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

//i18n
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEn from "./i18n/en.json";
import translationFr from "./i18n/fr.json";

import "./visual.scss";
//

import "bootstrap/scss/bootstrap.scss";
import "./visual.scss";

import "bootstrap/scss/bootstrap-utilities.scss";
import { UserProvider } from "./context/UserContext";

import "./visual.scss";
import "./visual.scss";
import { getLanguage } from "./services/languageService";

const lng = getLanguage();

i18n.use(initReactI18next) // passes i18n down to react-i18next
    .init({
        // the translations
        // (tip move them in a JSON file and import them,
        // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
        resources: {
            en: {
                translation: translationEn,
            },
            fr: {
                translation: translationFr,
            },
        },
        lng: lng, // if you're using a language detector, do not define the lng option
        fallbackLng: "en",
        keySeparator: ".", // to support nested translations

        interpolation: {
            escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
        },
    });
const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <UserProvider>
                <App />
            </UserProvider>
        </BrowserRouter>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
