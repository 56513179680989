import request from "./apiUtils";
import { ACCESS_TOKEN, API_BASE_URL, CURRENT_USER } from '../utils/constants';
import { User, UserCreate, UserDownloadQuery, UserPasswordUpdateRequest, UserSearchQuery, UserUpdate } from "../utils/models/user";
import { UUIDModel } from "../utils/models/base";


export const getCurrentUser = (user: string) => {
    return request({
        url: API_BASE_URL + "/" + user + "/me",
        method: 'POST'
    });
}

export const getCurrentUserProfile = () => {
    return request({
        url: API_BASE_URL + "/agent/profile",
        method: 'POST'
    });
}

export const updatePass = (query: UserPasswordUpdateRequest) => {
    return request({
        url: API_BASE_URL + "/agent/update-pass",
        method: 'POST',
        body: JSON.stringify(query)
    });
} 

export const getCurrentUserNotifications = () => {
    return request({
        url: API_BASE_URL + "/agent/notifications",
        method: 'GET'
    });
}

export const getUsers = (query: UserSearchQuery) => {
    return request({
        url: API_BASE_URL + "/admin/user/search",
        method: 'POST',
        body: JSON.stringify(query)
    });
}

export const downloadUsers = (query: UserDownloadQuery) => {
    return request({
        url: API_BASE_URL + "/admin/user/download",
        method: 'POST',
        body: JSON.stringify(query)
    });
}

export const getUsersRoles = () => {
    return request({
        url: API_BASE_URL + "/admin/user/roles",
        method: 'GET'
    });
}

export const getUser = (id: string) => {
    return request({
        url: API_BASE_URL + "/admin/user/profile",
        method: 'POST',
        body: JSON.stringify({ id })
    });
}

export const updateUser = (user: UserUpdate) => {
    return request({
        url: API_BASE_URL + "/admin/user/update",
        method: 'POST',
        body: JSON.stringify(user)
    });
}

export const enableOrDisableUser = (user: UUIDModel, enable: boolean) => {
    if (enable) {
        return request({
            url: API_BASE_URL + "/admin/user/enable",
            method: 'POST',
            body: JSON.stringify(user)
        });
    } else {
        return request({
            url: API_BASE_URL + "/admin/user/disable",
            method: 'POST',
            body: JSON.stringify(user)
        });
    }

}

export const createUser = (user: UserCreate) => {
    return request({
        url: API_BASE_URL + "/admin/user/create",
        method: 'POST',
        body: JSON.stringify(user)
    });
}

export const areaAssignment = () => {
    return request({
        url: API_BASE_URL + "/admin/user/area-assignment",
        method: 'GET'
    });
}


export const bulkCreateUsers = (file: File) => {
    const formData = new FormData();
    formData.append('file', file);

    return request({
        url: API_BASE_URL + "/admin/user/bulk-create",
        method: 'POST',
        body: formData,
        headers: new Headers({})
    });
}

export const storeAccessToken = (accessToken: string) => {
    localStorage.setItem(ACCESS_TOKEN, accessToken);
}
export const storeCurrentUser = (user: any) => {
    localStorage.setItem(CURRENT_USER, JSON.stringify(user));
}
export const cleanStorage = () => {
    localStorage.removeItem(CURRENT_USER);
    localStorage.removeItem(ACCESS_TOKEN);
}

export const hasSameGrade = (user1: User, user2: User) : boolean => {
    if(user1 == null || user2 == null){
        return false;
    }
    const role1 = user1.authorities[0].authority;
    const role2 = user2.authorities[0].authority;
    return role1 === role2 && user1.reattachmentArea?.id === user2.reattachmentArea?.id;
}


