import { Outlet, useNavigate } from "react-router-dom";
import Header from "../components/Header/Header";
import { styled } from "styled-components";
import Breadcrumb from "../components/Breadcrumb/Breadcrumb";
import Footer from "../components/Footer/Footer";
import { routes } from "../utils/routes";
import { useUserContext } from "../context/UserContext";
import { isAdmin, isAuthenticated, setNotAuthenticated } from "../services/authenticationService";
import { useEffect } from "react";
import { getCurrentUser } from "../services/userService";


const RootBodyStyle = styled('div')({
    display: 'flex',
    minHeight: '100%',
    overflow: 'hidden',
    margin: '20px 0 50px'
});

function AdministrationLayout() {

    const { user, userContextLogin, userContextLogout } = useUserContext();
    const navigate = useNavigate();

    useEffect(() => {
        const isAuth = isAuthenticated();
        if (isAuth && user == null) {
            getCurrentUser("agent")
                .then((response: any) => {
                    userContextLogin(response);
                    if (!isAdmin(response)) {
                        // Redirigez l'utilisateur vers la page d'accueil
                        navigate(routes.notfound.route, { replace: true, state: { code: 403 } });
                    }
                }).catch(() => {
                    userContextLogout();
                    setNotAuthenticated();
                    navigate(routes.logIn.route, { replace: true, state: { code: 500 } });
                });
        } else if (isAuth && user !== null) {
            if (!isAdmin(user)) {
                // Redirigez l'utilisateur vers la page d'accueil
                navigate(routes.notfound.route, { replace: true, state: { code: 403 } });
            }
        } else if (!isAuth) {
            navigate(routes.logIn.route, { state: { code: 401 } });
        }
    }, [user, navigate, userContextLogin, userContextLogout]) //notice the empty array here

    return (
        <Outlet />
    )

}

export default AdministrationLayout;