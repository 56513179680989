export const isNonEmpty = (str?: string): boolean => {
    return str !== null && str !== '' && str !== undefined && str.trim() !== '';
}

export const isEmpty = (str?: string): boolean => {
    return !isNonEmpty(str);
}

export const isNonNull = (str?: string): boolean => {
    return str !== null;
}

export const isAlphaNumeric = (str: string): boolean => {
    return /^(?!\s)[a-zA-Z0-9\s]*(?<!\s)$/.test(str);
}

export const hasAccent = (str: string): boolean => {
    return /[éèàùûêâôîëäöüï]/.test(str);
}

export const isAlphaNumericWithoutAccent = (str: string): boolean => {
    return isAlphaNumeric(str) && !hasAccent(str);
}

export const convertToCamelCase = (inputString: string): string | null => {
    // Remplace les espaces par une chaîne vide, puis utilise la méthode replace avec une expression régulière pour mettre en majuscule la première lettre après chaque espace
    if (inputString === null || inputString === undefined) {
        return null;
    }
    return inputString.replace(/\s(.)/g, (_, match) => match.toUpperCase());
}