import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { generateSoftColorsPalette } from "../../utils/chartjs";
import { useTranslation } from "react-i18next";

ChartJS.register(ArcElement, Tooltip, Legend);

interface DoughnutChartProps {
    data: Record<string, number>;
}
export function DoughnutChart({ data }: Readonly<DoughnutChartProps>) {
    const { t } = useTranslation();

    const softColorsPalette = generateSoftColorsPalette();

    const options = {
        responsive: true,
        interaction: {
            mode: "index" as const,
            intersect: false,
        },
        stacked: false,
        plugins: {
            legend: {
                position: "bottom" as const,
                align: "start" as const,
            },
        },
    };
    const dataConfig = {
        labels: Object.keys(data),
        datasets: [
            {
                label: t("page.administration.stats.chart.graph.offenses.title"),
                data: Object.values(data),
                backgroundColor: softColorsPalette[0],
                borderColor: softColorsPalette[1],
                borderWidth: 1,
            },
        ],
    };
    return <Doughnut data={dataConfig} options={options} />;
}
