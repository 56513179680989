import { Navigate, Outlet } from "react-router-dom";
import { styled } from "styled-components";

import { Col, Container, Row, Stack } from "react-bootstrap";
import { routes } from "../utils/routes";
import { isAuthenticated } from "../services/authenticationService";

import HeaderAppLogo from "../assets/logoTSC5.png";
import WelcomePoliceLogo from "../assets/login-bg-police.svg";

import { Link } from "react-router-dom";
import { devices } from "../utils/constants";
import { useTranslation } from "react-i18next";

const CopyrightFooter = styled.footer``;

const WelcomeLogoCol = styled(Col)`
    background: rgba(9, 84, 83, 0.22);
    background-size: cover;
    padding: 75px 95px;
    min-height: calc(100vh - 72px);
    display: -webkit-box;
    display: flex;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
`;
const Logo = styled.img`
    display: block;
    max-width: 75px;
    height: auto;

    @media ${devices.md} {
        max-width: 85px;
    }
`;

const PoliceLogo = styled.img`
    display: block;
    max-width: 250px;
    height: auto;
    margin: auto;
    mix-blend-mode: darken;

    @media ${devices.md} {
        max-width: 250px;
    }

    @media ${devices.lg} {
        max-width: 300px;
    }
`;

const ServiceTitle = styled(Link)`
    color: #095453;
    font-family: Sumana;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-decoration: none;

    @media ${devices.sm} {
        font-size: 50px;
    }
`;

const ServiceTagline = styled.p`
    color: #095453;
    font-family: Sumana;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    font-size: 23px;
    margin-top: -10px;
    margin-left: 5px;

    @media ${devices.sm} {
        font-size: 25px;
        margin-left: 8px;
    }
`;

function LogInLayout() {
    const { t } = useTranslation();

    document.body.classList.add("log-in");

    if (isAuthenticated()) {
        return <Navigate to={routes.home.route} />;
    }

    return (
        <section className="vh-100">
            <Container fluid className="h-custom">
                <Row className="row d-flex justify-content-center align-items-center h-75">
                    <WelcomeLogoCol xs="12" sm="10" md="6" className="m-auto mt-auto mx-0 d-none d-md-flex">
                        <PoliceLogo className="tsc-responsive-img tsc-image-header" alt="TSC" src={WelcomePoliceLogo} />
                    </WelcomeLogoCol>
                    <Col xs="12" sm="10" md={{ span: 6, offset: 0 }} className="m-auto px-md-5 px-sm-2">
                        <Stack gap={2} direction="horizontal" className="mt-3 mt-md-0">
                            <div className="tsc-header__operator">
                                <Logo className="tsc-responsive-img tsc-image-header" alt="TSC" src={HeaderAppLogo} />
                            </div>

                            <div>
                                <ServiceTitle to="/">{t("app.title")}</ServiceTitle>
                                <ServiceTagline className="tsc-header__service-tagline">{t("app.tagline")}</ServiceTagline>
                            </div>
                        </Stack>

                        <Row className="pt-sm-5 mt-sm-4">
                            <Outlet />
                        </Row>
                    </Col>
                </Row>
            </Container>
            <CopyrightFooter className="d-flex flex-column flex-md-row text-center text-md-start justify-content-between py-4 px-4 px-xl-5 bg-primary">
                <div className="text-white mb-3 mb-md-0">
                    {t("globals.labels.all-rights-reserved", {
                        date: new Date().getFullYear(),
                    })}
                </div>
            </CopyrightFooter>
        </section>
    );
}

export default LogInLayout;
