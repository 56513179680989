import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import { Search as SearchIcon } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import FormControl from "../@extends/react-bootstrap/FormControl";
import { KeyboardEvent, useState } from "react";
import { isNonEmpty } from "../../utils/stringUtils";
import { verifyVinOrNumberplate } from "../../services/searchService";
import { useNavigate } from "react-router-dom";
import { routes } from "../../utils/routes";

interface QuickSearchProps {
    callback?: () => void;
}
const QuickSearch = ({ callback }: QuickSearchProps) => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const [searchNumberplate, setSearchNumberplate] = useState('');
    const [searchNumberplateError, setSearchNumberplateError] = useState<boolean>(false);


    const submitSearch = () => {

        verifyVinOrNumberplate({
            numberplate: searchNumberplate,
            vin: null
        }).then((response: boolean) => {
            if (response) {
                // Navigate to the results page with the search results
                if (callback) {
                    callback();
                }
                const formData = {
                    date: new Date(),
                    query: {
                        numberplate: searchNumberplate,
                        vin: null
                    }
                }
                sessionStorage.setItem("formData", JSON.stringify(formData));
                navigate(routes.vehicle.route);
            } else {
                setSearchNumberplateError(true);
            }

        }).catch((error) => {
            setSearchNumberplateError(true);
        });
    }

    function handleKeyUp(event: KeyboardEvent<HTMLInputElement>): void {
        //throw new Error("Function not implemented.");
        setSearchNumberplateError(false);
        if (event.code === "Enter" && isNonEmpty(searchNumberplate)) {
            submitSearch();
        }

    }

    return (
        <Row>
            <Form.Group className="mb-3" as={Col} md="12" sm={12} controlId="validationNumberplate">
                <Form.Label className="mb-0">{t("page.home.forms.numberplate.label")}</Form.Label>
                <Form.Text className="text-muted mt-0 mb-1 d-block">
                    {t("page.home.forms.numberplate.description")}
                </Form.Text>
                <InputGroup>
                    <FormControl
                        required
                        type="text"
                        name="numberplate"
                        value={searchNumberplate}
                        onChange={(e) => setSearchNumberplate(e.target.value.toUpperCase())}
                        onKeyUp={handleKeyUp}
                    />

                    <Button variant="primary" className="rounded-0"
                        onClick={() => submitSearch()}
                        disabled={!isNonEmpty(searchNumberplate)}>
                        <SearchIcon className="m-1" />
                        {t("page.offense.forms.vehicle.submit.search.label")}
                    </Button>
                </InputGroup>

                {searchNumberplateError && (
                    <Form.Text className="text-danger fw-bold mt-0 mb-1 d-block">
                        {t("page.home.forms.numberplate.unrecognised")}
                    </Form.Text>
                )}


            </Form.Group>

        </Row>
    );

}

export default QuickSearch;