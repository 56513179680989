import { ChangeEvent, KeyboardEvent, useEffect, useState } from "react";
import { Alert, Button, Col, Container, Form, ListGroup, Modal, OverlayTrigger, Pagination, Popover, Row, Stack, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { downloadUsers, enableOrDisableUser, getUsers, getUsersRoles } from "../../../services/userService";
import { User } from "../../../utils/models/user";
import {
    BootstrapReboot,
    EyeFill,
    FiletypeCsv,
    List,
    PencilSquare,
    PersonAdd,
    Search,
    ShieldFillExclamation,
    Toggle2Off,
    Toggle2On,
    TrashFill,
} from "react-bootstrap-icons";
import FormControl from "../../../components/@extends/react-bootstrap/FormControl";
import styled from "styled-components";
import { routes } from "../../../utils/routes";
import { useNavigate } from "react-router-dom";
import { useUserContext } from "../../../context/UserContext";
import { DEFAULT_DATE_FORMAT, DEFAULT_HOUR_MINUTES_FORMAT, PAGE_SIZE } from "../../../utils/constants";
import FormSelect from "../../../components/@extends/react-bootstrap/FormSelect";
import { isNonNull } from "../../../utils/stringUtils";
import { ReattachmentArea, ReattachmentService } from "../../../utils/models/reattachment";
import { getUserAreas } from "../../../services/reattachmentService";
import { format } from "date-fns";

const UserTableRow = styled.tr`
    &.override td {
        color: black;
        background-color: lavender;
        font-style: italic;
    }
`;

const ListGroupItem = styled(ListGroup.Item)`
    border-left: none;
    border-right: none;
    border-top: none;
    cursor: pointer;

    &:last-child {
        border-bottom: none;
    }

    &:hover {
        background-color: #ededed;
    }
`;

export const ListUser = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [searchUser, handleSearchChange] = useState<string>("");

    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(1);

    const [users, setUsers] = useState<User[]>([]);

    const { user } = useUserContext();

    const [areas, setAreas] = useState<ReattachmentArea[]>([]);
    const [availableService, setAvailableService] = useState<ReattachmentService[]>([]);
    const [selectedArea, setSelectedArea] = useState<number | null>(null);
    const [selectedService, setSelectedService] = useState<number | null>(null);
    const [selectedRole, setSelectedRole] = useState<string>("");
    const [selectedStatus, setSelectedStatus] = useState<string>("");
    const [availableUserRoles, setAvailableUserRoles] = useState<string[]>([]);

    // Modal to delete
    const [show, setShowConfirmEnableDisable] = useState(false);
    const [userToDel, setUserToDel] = useState<User | null>(null);
    const [errorUserDel, setErrorUserDel] = useState<boolean>(false);

    const handleCancelEnableDisableUser = () => {
        setUserToDel(null);
        setShowConfirmEnableDisable(false);
        setErrorUserDel(false);
    };

    const handleEnableDisableUser = (user: User) => {
        setUserToDel(user);
        setShowConfirmEnableDisable(true);
    };

    const confirmEnableDisableUser = () => {
        if (userToDel) {
            enableOrDisableUser({ id: userToDel.id }, !userToDel.enabled)
                .then((response) => {
                    const updatedUser: User = response;
                    // Trouver l'index de l'utilisateur dans la liste
                    const userIndex = users.findIndex((user) => user.id === updatedUser.id);

                    if (userIndex !== -1) {
                        // Créer une copie de la liste des utilisateurs avec l'utilisateur mis à jour
                        const updatedUsers = [...users];
                        updatedUsers[userIndex] = updatedUser;

                        // Mettre à jour l'état de la liste des utilisateurs
                        setUsers(updatedUsers);

                        setUserToDel(null);
                        setShowConfirmEnableDisable(false);
                    }
                })
                .catch((error) => {
                    setErrorUserDel(true);
                });
        }
    };

    // Handling pagination
    const [totalPages, setTotalPages] = useState<number>(1);
    const [totalElements, setTotalElements] = useState<number>(1);

    const __handleSearchUser = (
        page: number,
        pageSize: number,
        searchVal: string,
        selectedArea: number | null,
        selectedService: number | null,
        selectedRole: string | null,
        selectedStatus: string | null
    ) => {
        getUsers({
            search: searchVal,
            page: page,
            pageSize: pageSize,
            area: selectedArea,
            service: selectedService,
            role: selectedRole === "-1" ? null : selectedRole,
            status: selectedStatus == "true" || selectedStatus == "false" ? selectedStatus : null,
        })
            .then((response) => {
                setUsers(response.content);

                setTotalPages(response.totalPages);
                setTotalElements(response.totalElements);
            })
            .catch((error) => {
                //TODO Ramener à la page précédente ou page d'erreur
            });
    };

    const handleSearchUser = (page: number) => {
        const searchVal = searchUser.trim();
        handleSearchChange(searchVal);
        __handleSearchUser(page - 1, pageSize, searchVal, selectedArea, selectedService, selectedRole, selectedStatus);
    };

    const handlePageChange = (page: number) => {
        setPage(page);
        handleSearchUser(page);
    };

    const handleSubmit = () => {
        if (searchUser.trim().length >= 3 || searchUser.trim().length === 0) {
            handleSearchUser(1);
        }
    };

    function handleSearchKeyUp(event: KeyboardEvent<HTMLInputElement>): void {
        if (event.code === "Enter" && isNonNull(searchUser)) {
            handleSubmit();
        }
    }

    const handleAreaChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedOption = event.target.selectedOptions[0];
        const optionValue = selectedOption.value || "0";

        setSelectedArea(Number.parseInt(optionValue));
    };

    const handleServiceChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedOption = event.target.selectedOptions[0];
        const optionValue = selectedOption.value || "0";

        setSelectedService(Number.parseInt(optionValue));
    };
    const handleRoleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedOption = event.target.selectedOptions[0];
        const optionValue = selectedOption.value || "0";

        setSelectedRole(optionValue);
    };

    const handleStatusChange = (event: ChangeEvent<HTMLSelectElement>): void => {
        const selectedOption = event.target.selectedOptions[0];
        const optionValue = selectedOption.value || "";

        setSelectedStatus(optionValue);
    };

    const displayProfile = (user: User, edition: boolean) => {
        let userRoute;
        if (edition) {
            userRoute = routes.administrationUserProfileEdition.route.replace(":id", user.id);
        } else {
            userRoute = routes.administrationUserProfileVisualize.route.replace(":id", user.id);
        }
        navigate(userRoute);
    };

    useEffect(() => {
        handleSearchUser(page);
    }, [pageSize]);

    useEffect(() => {
        _innerHandleAreasLoaded();
    }, [areas]);

    const _innerHandleAreasLoaded = () => {
        if (areas && areas.length > 0) {
            const dfArea = areas[0].id;
            setSelectedArea(user?.reattachmentArea?.id || dfArea);
        } else {
            setSelectedArea(null);
        }
    };

    useEffect(() => {
        getUsersRoles()
            .then((response) => {
                setAvailableUserRoles(response);
            })
            .catch((error) => {
                //TODO Ramener à la page précédente ou page d'erreur
            });

        getUserAreas()
            .then((response) => {
                setAreas(response);
            })
            .catch((error) => {
                //TODO Ramener à la page précédente ou page d'erreur
            });
    }, []);

    useEffect(() => {
        if (selectedArea != null) {
            const rs = areas.filter((obj) => obj.id == selectedArea).shift();
            if (rs != null) {
                setAvailableService(rs.reattachmentService);

                if (rs.reattachmentService == null || rs.reattachmentService.length == 0) {
                    setSelectedService(null);
                } else {
                    setSelectedService(-1);
                }
            }
        }
    }, [selectedArea]);

    const handlePageSizeChange = (event: ChangeEvent<HTMLSelectElement>): void => {
        const selectedOption = event.target.selectedOptions[0].value;
        setPage(1);
        setPageSize(parseInt(selectedOption));
    };

    let isPageNumberOutOfRange: boolean = false;
    const pageNumbers = [...new Array(totalPages)].map((_, index) => {
        const pageNumber = index + 1;
        const isPageNumberFirst = pageNumber === 1;
        const isPageNumberLast = pageNumber === totalPages;
        const isCurrentPageWithinMorePageNumbers = Math.abs(pageNumber - page) <= 4;

        if (isPageNumberFirst || isPageNumberLast || isCurrentPageWithinMorePageNumbers) {
            isPageNumberOutOfRange = false;

            if (pageNumber === page) {
                return (
                    <Pagination.Item key={pageNumber} active={true}>
                        {pageNumber}
                    </Pagination.Item>
                );
            } else {
                return (
                    <Pagination.Item key={pageNumber} onClick={() => handlePageChange(pageNumber)}>
                        {pageNumber}
                    </Pagination.Item>
                );
            }
        }

        if (!isPageNumberOutOfRange) {
            isPageNumberOutOfRange = true;
            return <Pagination.Ellipsis key={pageNumber} className="muted" />;
        }

        return null;
    });

    function setEnableUser(user: User): void {
        enableOrDisableUser({ id: user.id }, !user.enabled)
            .then((response) => {
                const updatedUser: User = response;
                // Trouver l'index de l'utilisateur dans la liste
                const userIndex = users.findIndex((user) => user.id === updatedUser.id);

                if (userIndex !== -1) {
                    // Créer une copie de la liste des utilisateurs avec l'utilisateur mis à jour
                    const updatedUsers = [...users];
                    updatedUsers[userIndex] = updatedUser;

                    // Mettre à jour l'état de la liste des utilisateurs
                    setUsers(updatedUsers);

                    setUserToDel(null);
                    setShowConfirmEnableDisable(false);
                }
            })
            .catch((error) => {
                setErrorUserDel(true);
            });
    }

    const downloadTableUsers = () => {
        downloadUsers({
            search: searchUser.trim(),
            area: selectedArea,
            service: selectedService,
            role: selectedRole === "-1" ? null : selectedRole,
            status: selectedStatus == "true" || selectedStatus == "false" ? selectedStatus : null,
        })
            .then((response) => {
                response
                    .blob()
                    .then((blob: any) => {
                        let url = window.URL.createObjectURL(blob);
                        let a = document.createElement("a");
                        a.href = url;
                        a.download = "users-" + format(new Date(), DEFAULT_DATE_FORMAT + "-" + DEFAULT_HOUR_MINUTES_FORMAT) + ".csv";
                        a.click();
                    })
                    .catch((error: any) => {
                        console.log({ error });
                    });
            })
            .catch((error) => {
                //TODO Ramener à la page précédente ou page d'erreur
            });
    };

    const popoverBottom = (
        <Popover>
            <Popover.Body className="p-0">
                <ListGroup>
                    <ListGroupItem className="bt-0 bx-0" onClick={() => navigate(routes.administrationUserCreation.route)}>
                        <PersonAdd className="me-1" />
                        {t("page.administration.user-list.forms.add")}
                    </ListGroupItem>

                    <ListGroupItem className="bt-0 bx-0" onClick={() => downloadTableUsers()}>
                        <FiletypeCsv className="me-1" />
                        {t("page.administration.user-list.forms.download")}
                    </ListGroupItem>
                </ListGroup>
            </Popover.Body>
        </Popover>
    );

    return (
        <Container fluid="lg">
            <Row className="text-center mb-3">
                <h1>{t("page.administration.user-list.mainTitle")}</h1>
            </Row>

            <Row className="sub-body-container pt-1">
                <Col className="">
                    <Row className="py-2 me-0">
                        <Col xs={12} md={{ offset: 9, span: 3 }} className="p-2">
                            <OverlayTrigger trigger={["click"]} placement="left" overlay={popoverBottom} rootClose>
                                <List size={22} style={{ cursor: "pointer" }} className="float-end" />
                            </OverlayTrigger>
                        </Col>
                    </Row>

                    <Row>
                        <Form.Group className="mb-3" as={Col} md="4" sm={12}>
                            <Form.Label className="mb-0 fw-bolder">{t("page.administration.user-list.filter.forms.field.area.label")}</Form.Label>
                            <Form.Text className="text-muted mt-0 mb-1 d-block">
                                {t("page.administration.user-list.filter.forms.field.area.description")}
                            </Form.Text>
                            <FormSelect className="w-100" name="area" value={selectedArea ? selectedArea : 0} onChange={handleAreaChange}>
                                {areas.map((itArea) => (
                                    <option key={itArea.code} value={itArea.id}>
                                        {itArea.name}
                                    </option>
                                ))}
                            </FormSelect>
                        </Form.Group>

                        <Form.Group className="mb-3" as={Col} md="4" sm={12}>
                            <Form.Label className="mb-0 fw-bolder">{t("page.administration.user-list.filter.forms.field.service.label")}</Form.Label>
                            <Form.Text className="text-muted mt-0 mb-1 d-block">
                                {t("page.administration.user-list.filter.forms.field.service.description")}
                            </Form.Text>
                            <FormSelect
                                className="w-100"
                                name="service"
                                onChange={handleServiceChange}
                                disabled={setSelectedArea === null || availableService.length === 0}
                            >
                                <option value={-1}>{t("globals.labels.all-services")}</option>
                                {availableService.map((itService) => (
                                    <option key={itService.code} value={itService.id}>
                                        {itService.name}
                                    </option>
                                ))}
                            </FormSelect>
                        </Form.Group>

                        <Form.Group className="mb-3" as={Col} md="4" sm={12}>
                            <Form.Label className="mb-0 fw-bolder">{t("page.administration.user-list.filter.forms.field.role.label")}</Form.Label>
                            <Form.Text className="text-muted mt-0 mb-1 d-block">
                                {t("page.administration.user-list.filter.forms.field.role.description")}
                            </Form.Text>
                            <FormSelect className="w-100" name="role" onChange={handleRoleChange}>
                                {availableUserRoles.length > 1 && <option value="-1">{t("globals.labels.all-roles")}</option>}
                                {availableUserRoles.map((itRole) => (
                                    <option key={itRole} value={itRole}>
                                        {t(`business-domain.user.role.types.${itRole}`)}
                                    </option>
                                ))}
                            </FormSelect>
                        </Form.Group>
                    </Row>

                    <Row>
                        <Form.Group className="mb-3" as={Col} md="4" sm={12}>
                            <Form.Label className="mb-0 fw-bolder">{t("page.administration.user-list.filter.forms.field.status.label")}</Form.Label>
                            <Form.Text className="text-muted mt-0 mb-1 d-block">
                                {t("page.administration.user-list.filter.forms.field.status.description")}
                            </Form.Text>
                            <FormSelect className="w-100" name="service" onChange={handleStatusChange}>
                                <option value="-1">{t("page.administration.user-list.filter.forms.field.status.values.all")}</option>
                                <option value={"true"}>{t("page.administration.user-list.filter.forms.field.status.values.enabled")}</option>
                                <option value={"false"}>{t("page.administration.user-list.filter.forms.field.status.values.locked")}</option>
                            </FormSelect>
                        </Form.Group>

                        <Col xs={12} md={{ offset: 0, span: 6 }} className="mb-3">
                            <Form.Label className="mb-0 fw-bolder"></Form.Label>
                            <Form.Text className="text-muted mt-0 mb-1 d-block">
                                {t("page.administration.user-list.forms.search.description")}
                            </Form.Text>
                            <FormControl
                                className="b-0 no-focus header-search"
                                placeholder={t("page.administration.user-list.forms.search.label")}
                                aria-label={t("page.administration.user-list.forms.search.label")}
                                type="text"
                                name="searchUser"
                                value={searchUser}
                                onChange={(e) => handleSearchChange(e.target.value)}
                                onKeyUp={handleSearchKeyUp}
                            />
                        </Col>

                        <Col xs={12} md={2} className="mb-3 text-center mt-auto">
                            <Button variant="primary" onClick={() => handleSubmit()} className="w-100 mb-1">
                                <Search className="me-1" /> {t("page.administration.user-list.forms.submit.label")}
                            </Button>
                        </Col>
                    </Row>
                </Col>
                <Table responsive striped bordered hover>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>{t("page.administration.user-list.table.columns.lastName")}</th>
                            <th>{t("page.administration.user-list.table.columns.firstName")}</th>
                            <th>{t("page.administration.user-list.table.columns.registrationID")}</th>
                            <th>{t("page.administration.user-list.table.columns.email")}</th>
                            <th>{t("page.administration.user-list.table.columns.role")}</th>
                            <th>{t("page.administration.user-list.table.columns.status")}</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {users.length === 0 && (
                            <tr>
                                <td colSpan={8}>
                                    <div className="text-center">{t("page.administration.user-list.table.empty")}</div>
                                </td>
                            </tr>
                        )}
                        {users.map((itUser, index) => (
                            <UserTableRow key={index} className={user && itUser.id === user.id ? "override" : ""}>
                                <td>{pageSize * (page - 1) + (index + 1)}</td>
                                <td>{itUser.lastName}</td>
                                <td>{itUser.firstName}</td>
                                <td>{itUser.matricule}</td>
                                <td>
                                    {itUser.email} ({itUser.reattachmentService ? itUser.reattachmentService.name : itUser.reattachmentArea?.name})
                                </td>
                                <td>
                                    {itUser.authorities.map((auth, index) => (
                                        <span key={index}>{t(`business-domain.user.role.types.${auth.authority}`)}</span>
                                    ))}
                                </td>
                                <td className="text-center">
                                    {user && itUser.id !== user.id && itUser.valid && !itUser.enabled && (
                                        <>
                                            <Toggle2Off
                                                role="button"
                                                className="me-1 fs-4"
                                                color="#dc3545"
                                                title={t("user.account.locked")}
                                                onClick={() => setEnableUser(itUser)}
                                            />
                                        </>
                                    )}
                                    {user && itUser.id !== user.id && itUser.valid && itUser.enabled && (
                                        <>
                                            <Toggle2On
                                                role="button"
                                                className="me-1 fs-4"
                                                color="#198754"
                                                title={t("user.account.locked")}
                                                onClick={() => setEnableUser(itUser)}
                                            />
                                        </>
                                    )}

                                    {!itUser.valid && (
                                        <ShieldFillExclamation className="ms-1 fs-5" color="#d99e00" title={t("user.account.not.valid")} />
                                    )}
                                    {itUser.hasAssignment && (
                                        <BootstrapReboot className="ms-1 fs-5" color="#d99e00" title={t("user.assignment.inprogess")} />
                                    )}
                                </td>

                                <td>
                                    <Stack direction="horizontal" gap={1}>
                                        <Button variant="outline-success" size="sm" onClick={() => displayProfile(itUser, false)}>
                                            <EyeFill />
                                        </Button>
                                        {user && itUser.id !== user.id && itUser.valid && (
                                            <Button variant="outline-dark" size="sm" onClick={() => displayProfile(itUser, true)}>
                                                <PencilSquare />
                                            </Button>
                                        )}

                                        {itUser.valid && itUser.enabled && false && (
                                            <Button variant="outline-danger" size="sm" onClick={() => handleEnableDisableUser(itUser)}>
                                                <TrashFill />
                                            </Button>
                                        )}
                                    </Stack>
                                </td>
                            </UserTableRow>
                        ))}
                    </tbody>
                </Table>

                <Modal size="lg" show={show} onHide={() => handleCancelEnableDisableUser()}>
                    <Modal.Header closeButton>
                        <Modal.Title>Are you sure you want to {userToDel?.enabled ? "deactivate" : "activate"} this account?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {errorUserDel && (
                            <Alert variant="danger">
                                This user could not be {userToDel?.enabled ? "deactivate" : "activate"}. Please try again later!
                            </Alert>
                        )}

                        <Row>
                            <Col className="mb-3" md="6" sm={12}>
                                <label className="mb-0 fw-bolder">{t("page.administration.user-create.forms.field.lastName.label")}</label>
                                <p className="d-block mt-2">{userToDel?.lastName}</p>
                            </Col>

                            <Col className="mb-3" md="6" sm={12}>
                                <label className="mb-0 fw-bolder">{t("page.administration.user-create.forms.field.firstName.label")}</label>
                                <p className="d-block mt-2">{userToDel?.firstName}</p>
                            </Col>
                        </Row>

                        <Row>
                            <Col className="mb-3" md="6" sm={12}>
                                <label className="mb-0 fw-bolder">{t("page.administration.user-create.forms.field.email.label")}</label>
                                <p className="d-block mt-2">{userToDel?.email}</p>
                            </Col>

                            <Col className="mb-3" md="6" sm={12}>
                                <label className="mb-0 fw-bolder">{t("page.administration.user-create.forms.field.registrationID.label")}</label>
                                <p className="d-block mt-2">{userToDel?.matricule}</p>
                            </Col>
                        </Row>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => handleCancelEnableDisableUser()}>
                            Cancel
                        </Button>
                        <Button variant="danger" onClick={() => confirmEnableDisableUser()}>
                            {userToDel?.enabled ? "Disable" : "Enable"}
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Row>
                    <Col md={4}>
                        <Row>
                            <div className="justify-content-start">
                                <FormSelect defaultValue={pageSize} onChange={handlePageSizeChange}>
                                    {PAGE_SIZE.map((itemPage) => (
                                        <option key={itemPage} value={itemPage}>
                                            {itemPage} {t("globals.tables.rows")}
                                        </option>
                                    ))}
                                </FormSelect>
                            </div>
                        </Row>
                        <Row>
                            <div className="justify-content-start">{t("globals.tables.total-rows", { total: totalElements })}</div>
                        </Row>
                    </Col>
                    <Col>
                        <Pagination className="justify-content-end">
                            <Pagination.First disabled={page <= 1} onClick={() => handlePageChange(1)} />
                            <Pagination.Prev disabled={page <= 1} onClick={() => handlePageChange(page - 1)} />

                            {pageNumbers}

                            <Pagination.Next disabled={page >= totalPages} onClick={() => handlePageChange(page + 1)} />
                            <Pagination.Last disabled={page >= totalPages} onClick={() => handlePageChange(totalPages)} />
                        </Pagination>
                    </Col>
                </Row>
            </Row>
        </Container>
    );
};
