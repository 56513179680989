import { ChangeEvent, useEffect, useState } from "react";
import { Col, Container, Pagination, Row, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { format } from "date-fns";
import { DEFAULT_DATE_TIME_FORMAT2, PAGE_SIZE } from "../../../utils/constants";
import FormSelect from "../../../components/@extends/react-bootstrap/FormSelect";
import { Vehicle } from "../../../utils/models/base";
import { generateOwnerFullName } from "../../../services/reportService";
import { getAllVehicles } from "../../../services/statisticService";

const UserTableRow = styled.tr`
    &.override td {
        color: black;
        background-color: lavender;
        font-style: italic;
    }
`;

export const ListVehicle = () => {
    const { t } = useTranslation();

    const [searchDetails, handleSearchChange] = useState<string>("");
    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(1);

    const [vehicles, setVehicles] = useState<Vehicle[]>([]);

    // Handling pagination
    const [totalPages, setTotalPages] = useState<number>(1);
    const [totalElements, setTotalElements] = useState<number>(1);

    const handleSearchUser = (page: number) => {
        const searchVal = searchDetails.trim();
        handleSearchChange(searchVal);
        getAllVehicles({
            page: page - 1,
            pageSize: pageSize,
        })
            .then((response) => {
                setVehicles(response.content);

                setTotalPages(response.totalPages);
                setTotalElements(response.totalElements);
            })
            .catch(() => {
                //TODO Ramener à la page précédente ou page d'erreur
            });
    };

    const handlePageChange = (page: number) => {
        setPage(page);
        handleSearchUser(page);
    };

    useEffect(() => {
        handleSearchUser(page);
    }, [pageSize]);

    let isPageNumberOutOfRange: boolean = false;
    const pageNumbers = [...new Array(totalPages)].map((_, index) => {
        const pageNumber = index + 1;
        const isPageNumberFirst = pageNumber === 1;
        const isPageNumberLast = pageNumber === totalPages;
        const isCurrentPageWithinMorePageNumbers = Math.abs(pageNumber - page) <= 4;

        if (isPageNumberFirst || isPageNumberLast || isCurrentPageWithinMorePageNumbers) {
            isPageNumberOutOfRange = false;

            if (pageNumber === page) {
                return (
                    <Pagination.Item key={pageNumber} active={true}>
                        {pageNumber}
                    </Pagination.Item>
                );
            } else {
                return (
                    <Pagination.Item key={pageNumber} onClick={() => handlePageChange(pageNumber)}>
                        {pageNumber}
                    </Pagination.Item>
                );
            }
        }

        if (!isPageNumberOutOfRange) {
            isPageNumberOutOfRange = true;
            return <Pagination.Ellipsis key={pageNumber} className="muted" />;
        }

        return null;
    });

    const handlePageSizeChange = (event: ChangeEvent<HTMLSelectElement>): void => {
        setPage(1);
        const selectedOption = event.target.selectedOptions[0].value;
        setPageSize(parseInt(selectedOption));
    };

    return (
        <Container fluid="lg">
            <Row className="text-center mb-3">
                <h1>{t("page.offense.administration.user.mainTitle")}</h1>
            </Row>

            <Row className="sub-body-container pt-1">
                <div className="table-responsive mt-2">
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Numberplate</th>
                                <th>VIN</th>
                                <th>Brand</th>
                                <th>variantType</th>
                                <th>Date firstRegistration</th>
                                <th>Owner</th>
                            </tr>
                        </thead>
                        <tbody>
                            {vehicles.map((itVehicle, index) => (
                                <UserTableRow key={index}>
                                    <td>{pageSize * (page - 1) + (index + 1)}</td>
                                    <td>{itVehicle.numberplate}</td>
                                    <td>{itVehicle.vinSafe}</td>
                                    <td>{itVehicle.brand}</td>
                                    <td>{itVehicle.variantType}</td>

                                    <td>{format(new Date(itVehicle.firstRegistration), DEFAULT_DATE_TIME_FORMAT2)}</td>
                                    <td>{generateOwnerFullName(itVehicle.owner)}</td>
                                </UserTableRow>
                            ))}
                        </tbody>
                    </Table>
                </div>

                <Row>
                    <Col md={4}>
                        <Row>
                            <div className="justify-content-start">
                                <FormSelect defaultValue={pageSize} onChange={handlePageSizeChange}>
                                    {PAGE_SIZE.map((itemPage) => (
                                        <option key={itemPage} value={itemPage}>
                                            {itemPage} {t("globals.tables.rows")}
                                        </option>
                                    ))}
                                </FormSelect>
                            </div>
                        </Row>
                        <Row>
                            <div className="justify-content-start">{t("globals.tables.total-rows", { total: totalElements })}</div>
                        </Row>
                    </Col>
                    <Col>
                        <Pagination className="justify-content-end">
                            <Pagination.First disabled={page <= 1} onClick={() => handlePageChange(1)} />
                            <Pagination.Prev disabled={page <= 1} onClick={() => handlePageChange(page - 1)} />

                            {pageNumbers}

                            <Pagination.Next disabled={page >= totalPages} onClick={() => handlePageChange(page + 1)} />
                            <Pagination.Last disabled={page >= totalPages} onClick={() => handlePageChange(totalPages)} />
                        </Pagination>
                    </Col>
                </Row>
            </Row>
        </Container>
    );
};
