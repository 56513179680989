import React, { useRef, useState } from 'react';
import { FloatingLabel, Form, ListGroup } from 'react-bootstrap';
import FormControl from '../@extends/react-bootstrap/FormControl';
import { OffenseType } from '../../utils/models/offense';

interface AutocompleteSelectProps {
    options: OffenseType[];
    onSelect: (selectedValue: OffenseType) => void;
    label: string;
    description: string;
}

const AutocompleteSelect: React.FC<AutocompleteSelectProps> = ({ options, onSelect, label, description, ...props }) => {

    const [searchTerm, setSearchTerm] = useState<string>('');
    const [filteredOptions, setFilteredOptions] = useState<OffenseType[]>(options);
    const [show, setShow] = useState(false);

    const inputElement = useRef<HTMLInputElement | null>(null);


    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const term = e.target.value;
        setSearchTerm(term);

        const filtered = options.filter(option =>
            option.name.toLowerCase().includes(term.toLowerCase())
        );
        setFilteredOptions(filtered);
    };

    const handleSelect = (selectedValue: OffenseType) => {
        setSearchTerm('');
        setFilteredOptions(options);
        onSelect(selectedValue);
        setShow(false);
    };



    function onBlur(event: React.FocusEvent<HTMLInputElement>): void {
        setTimeout(() => {
            setShow(false);
        }, 200);
    }

    return (
        <Form.Group className="mb-2">
            <FloatingLabel className="mb-0" label={label}>

                <FormControl ref={inputElement}
                    type="text"
                    value={searchTerm}
                    onFocus={() => setShow(true)}
                    onBlur={onBlur}
                    onChange={handleInputChange}
                    list="autocomplete-options"
                    autoComplete="on"
                    placeholder={label}
                />
            </FloatingLabel>
            <Form.Text>{description}</Form.Text>

            <div style={{ position: "relative", zIndex: "99", display: show ? "block" : "none" }}>
                <ListGroup style={{ position: "absolute", width: '100%', overflow: "scroll", maxHeight: "30rem" }}>
                    {filteredOptions.map((option) => (
                        <ListGroup.Item action key={option.id}
                            onClick={() => handleSelect(option)}>
                            {option.name} ({option.group.code})
                        </ListGroup.Item>
                    ))}
                </ListGroup>
            </div>

        </Form.Group>
    );
};

export default AutocompleteSelect;
