import { API_BASE_URL } from '../utils/constants';
import request from "./apiUtils";

export function searchAddressStates() {

    return request({
        url: API_BASE_URL + "/ref/address-state",
        method: 'GET'
    });
}


export function listTraceModule() {

    return request({
        url: API_BASE_URL + "/ref/trace/module",
        method: 'GET'
    });
}
