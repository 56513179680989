import React, { useState } from "react";
import * as Yup from "yup";

import { Container, Form, Button, Row, Alert, FloatingLabel, Col } from "react-bootstrap";
import FormControl from "../../components/@extends/react-bootstrap/FormControl";
import { FormikProvider, useFormik, Form as FormikForm } from "formik";
import { resetPass } from "../../services/authenticationService";
import { useNavigate } from "react-router-dom";
import { routes } from "../../utils/routes";
import { ArrowRepeat, ArrowReturnLeft } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";

const ResetPassword: React.FC = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [renewAsked, setRenewAsked] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");

    const ResetSchema = Yup.object().shape({
        username: Yup.string().required(t("page.reset-pass.forms.field.username.required")),
        email: Yup.string()
            .email(t("page.reset-pass.forms.field.email.required-valid-email"))
            .required(t("page.reset-pass.forms.field.email.required")),
    });

    const formik = useFormik({
        initialValues: {
            username: "",
            email: "",
        },
        validationSchema: ResetSchema,

        onSubmit: (values, actions) => {
            //Here we submitted
            const resetRequest = Object.assign({}, values);
            setRenewAsked(false);
            setMessage("");

            resetPass(resetRequest)
                .then(() => {
                    setRenewAsked(true);
                })
                .catch(() => {
                    setMessage("Sorry! Something went wrong. Please try again!");
                    actions.setSubmitting(false);
                    setRenewAsked(false);
                });
        },
    });

    const { handleSubmit, getFieldProps, isValid, errors, touched } = formik;

    return (
        <Container className="mt-5 mt-sm-0">
            {renewAsked === true && (
                <Row>
                    <p>{t("page.reset-pass.messages.part1")}</p>
                    <p>{t("page.reset-pass.messages.part2")}</p>

                    <p>{t("page.reset-pass.messages.part3")}</p>
                    <br />

                    <p>{t("page.reset-pass.messages.part4")}</p>

                    <Row className="m-auto">
                        <Col sm={{ offset: 2, span: 8 }} className="text-center">
                            <Button className="mt-4 w-100" variant="primary" onClick={() => navigate(routes.logIn.route)}>
                                {t("page.reset-pass.forms.submit.go-back-login.label")}
                            </Button>
                        </Col>
                    </Row>
                </Row>
            )}

            {renewAsked === false && (
                <>
                    {message && (
                        <Alert dismissible variant="danger">
                            {message}
                        </Alert>
                    )}

                    <FormikProvider value={formik}>
                        <FormikForm onSubmit={handleSubmit}>
                            <Row>
                                <Form.Group controlId="username" className="mb-4">
                                    <FloatingLabel label={t("page.reset-pass.forms.field.username.label")}>
                                        <FormControl
                                            type="text"
                                            placeholder={t("page.reset-pass.forms.field.username.label")}
                                            isValid={touched.username && !errors.username}
                                            isInvalid={touched.username && !!errors.username}
                                            {...getFieldProps("username")}
                                        />
                                    </FloatingLabel>
                                    <Form.Text>{t("page.reset-pass.forms.field.username.description")}</Form.Text>
                                </Form.Group>

                                <Form.Group controlId="email" className="mb-4">
                                    <FloatingLabel label={t("page.reset-pass.forms.field.email.label")}>
                                        <FormControl
                                            type="email"
                                            placeholder={t("page.reset-pass.forms.field.email.label")}
                                            isValid={touched.email && !errors.email}
                                            isInvalid={touched.email && !!errors.email}
                                            {...getFieldProps("email")}
                                        />
                                    </FloatingLabel>
                                    <Form.Text>{t("page.reset-pass.forms.field.email.description")}</Form.Text>
                                </Form.Group>
                            </Row>

                            <Row className="m-auto">
                                <Col sm={{ offset: 2, span: 4 }} md={{ offset: 0, span: 6 }} className="text-center">
                                    <Button className="mt-4 w-100" variant="dark" onClick={() => navigate(routes.logIn.route)}>
                                        <ArrowReturnLeft className="me-1" />
                                        {t("page.reset-pass.forms.submit.go-back-login.label")}
                                    </Button>
                                </Col>

                                <Col sm={{ offset: 0, span: 4 }} md={{ offset: 0, span: 6 }} className="text-center">
                                    <Button className="mt-4 w-100" variant="primary" type="submit">
                                        {t("page.reset-pass.forms.submit.ask-new-password.label")}
                                        <ArrowRepeat className="ms-1" />
                                    </Button>
                                </Col>
                            </Row>
                        </FormikForm>
                    </FormikProvider>
                </>
            )}
        </Container>
    );
};

export default ResetPassword;
