import { Button, Col, Container, Form, Placeholder, Row, Stack } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import FormSelect from "../../../../components/@extends/react-bootstrap/FormSelect";
import { useEffect, useState } from "react";
import { ReattachmentArea, ReattachmentService } from "../../../../utils/models/reattachment";
import { getUserAreas } from "../../../../services/reattachmentService";
import { useUserContext } from "../../../../context/UserContext";
import DateRange from "../../../../components/DateRange/DateRange";
import { format } from "date-fns";
import { CHARTS_ID, DEFAULT_DATE_FORMAT } from "../../../../utils/constants";
import { Stats } from "../../../../utils/models/stats";
import Chart from "../../../../components/Chart/Chart";
import { LineChart } from "../../../../components/Chart/LineChart";
import { useNavigate, useParams } from "react-router-dom";
import { getDetailledChartLineStats } from "../../../../services/statisticService";
import { AreaBadge } from "../../../../components/Reattachement/AreaBadge";
import { ServiceBadge } from "../../../../components/Reattachement/ServiceBadge";
import { GenericBadge } from "../../../../components/Reattachement/GenericBadge";
import { ChevronDoubleLeft } from "react-bootstrap-icons";
import { routes } from "../../../../utils/routes";

const LineChartDetails = () => {
    const { id } = useParams<{ id: string }>();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [stats, setStats] = useState<Stats>();

    const { user } = useUserContext();

    const [chartTitle, setChartTitle] = useState<string>("");
    const [chartDescription, setChartDescription] = useState<string>("");
    const [chartCumulative, setChartCumulative] = useState<boolean>(false);

    const [chartType, setChartType] = useState<string>("");

    useEffect(() => {
        if (!id || id === undefined) {
            return; //ERROR
        }

        setChartTitle(t(`page.administration.stats.chart.graph.${id}.title`));
        setChartDescription(t(`page.administration.stats.chart.graph.${id}.description`));
        setChartType(id);
        if (id === CHARTS_ID.EVOLUTION_V_O) {
            setChartCumulative(true);
        } else if (id !== CHARTS_ID.VEHICLE_OFFENSE_PER_MONTH) {
            throw new Error("Unknown chart id");
        }
    }, [id]);

    const [areas, setAreas] = useState<ReattachmentArea[]>([]);
    const [availableService, setAvailableService] = useState<ReattachmentService[]>([]);
    const [selectedArea, setSelectedArea] = useState<number | null>(null);
    const [selectedService, setSelectedService] = useState<number | null>(null);

    const [tagArea, setTagArea] = useState<string>("");
    const [tagService, setTagService] = useState<string | null>(null);
    const [tagRangeDate, setTagRangeDate] = useState<string>("");

    const getServiceByNumber = (number: number | null) => {
        if (number === null) {
            return null;
        }
        if (number === -1) {
            return {
                id: -1,
                name: t("globals.labels.all-services"),
            };
        }
        return availableService.find((service) => service.id === number);
    };

    const getAreaByNumber = (number: number) => {
        return areas.find((area) => area.id === number);
    };

    // Obtenir la date actuelle
    const currentDate = new Date();
    const yesterday = new Date(currentDate);
    yesterday.setDate(yesterday.getDate() - 1);

    // Soustraire un an à la date actuelle
    const lastYearDate = new Date(currentDate);
    lastYearDate.setFullYear(currentDate.getFullYear() - 1);
    const [range, setRange] = useState<{ start: string; end: string }>({
        start: format(lastYearDate, DEFAULT_DATE_FORMAT),
        end: format(yesterday, DEFAULT_DATE_FORMAT),
    });

    const handleAreaChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedOption = event.target.selectedOptions[0];
        const optionValue = selectedOption.value || "0";

        setSelectedArea(Number.parseInt(optionValue));
    };

    const handleServiceChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedOption = event.target.selectedOptions[0];
        const optionValue = selectedOption.value || "0";

        setSelectedService(Number.parseInt(optionValue));
    };

    function handleFilters(): void {
        getDetailledChartLineStats({
            type: chartType,
            start: range.start,
            end: range.end,
            area: selectedArea || 0,
            service: selectedService,
        })
            .then((response) => {
                setStats(response);
            })
            .catch((e) => {
                console.log(e);
            });
    }

    useEffect(() => {
        if (selectedArea != null) {
            const rs = areas.filter((obj) => obj.id == selectedArea).shift();
            if (rs != null) {
                setAvailableService(rs.reattachmentService);

                if (rs.reattachmentService == null || rs.reattachmentService.length == 0) {
                    setSelectedService(null);
                } else {
                    setSelectedService(-1);
                }
            }
        }
    }, [selectedArea]);

    useEffect(() => {
        if (areas && areas.length > 0) {
            const dfArea = areas[0].id;
            setSelectedArea(user?.reattachmentArea?.id || dfArea);
        } else {
            setSelectedArea(null);
        }
    }, [areas]);

    useEffect(() => {
        getUserAreas()
            .then((response) => {
                setAreas(response);
            })
            .catch((error) => {
                //TODO Ramener à la page précédente ou page d'erreur
            });
    }, []);

    useEffect(() => {
        if (user && chartType !== "") {
            getDetailledChartLineStats({
                type: chartType,
                start: range.start,
                end: range.end,
                area: user.reattachmentArea?.id || 0,
                service: user.reattachmentService?.id || null,
            })
                .then((response) => {
                    setStats(response);
                })
                .catch((e) => {
                    console.log(e);
                });
        }
    }, [navigate, user, chartType]);

    useEffect(() => {
        setTagArea(getAreaByNumber(selectedArea || 0)?.name || t("-"));
        setTagService(getServiceByNumber(selectedService)?.name || null);
        setTagRangeDate(`${format(new Date(range.start), DEFAULT_DATE_FORMAT)} - ${format(new Date(range.end), DEFAULT_DATE_FORMAT)}`);
    }, [stats]);

    return (
        <Container fluid="lg">
            <Row className="text-center mb-3">
                <h1>{t("page.administration.stats-details.mainTitle")}</h1>
            </Row>

            <Row>
                <Col className="pe-0">
                    <div className="sub-body-container">
                        <Row>
                            <Form.Group className="mb-3" as={Col} md="3" sm={12}>
                                <Form.Label className="mb-0 fw-bolder">{t("page.administration.stats-details.forms.field.area.label")}</Form.Label>
                                <Form.Text className="text-muted mt-0 mb-1 d-block">
                                    {t("page.administration.stats-details.forms.field.area.description")}
                                </Form.Text>
                                <FormSelect className="w-100" name="area" onChange={handleAreaChange}>
                                    {areas.map((itArea) => (
                                        <option key={itArea.code} value={itArea.id}>
                                            {itArea.name}
                                        </option>
                                    ))}
                                </FormSelect>
                            </Form.Group>

                            <Form.Group className="mb-3" as={Col} md="3" sm={12}>
                                <Form.Label className="mb-0 fw-bolder">{t("page.administration.stats-details.forms.field.service.label")}</Form.Label>
                                <Form.Text className="text-muted mt-0 mb-1 d-block">
                                    {t("page.administration.stats-details.forms.field.service.description")}
                                </Form.Text>
                                <FormSelect
                                    className="w-100"
                                    name="service"
                                    onChange={handleServiceChange}
                                    disabled={setSelectedArea === null || availableService.length === 0}
                                >
                                    <option value={-1}>{t("globals.labels.all-services")}</option>
                                    {availableService.map((itService) => (
                                        <option key={itService.code} value={itService.id}>
                                            {itService.name}
                                        </option>
                                    ))}
                                </FormSelect>
                            </Form.Group>

                            <Form.Group className="mb-3" as={Col} md="3" sm={12}>
                                <DateRange
                                    label={t("page.administration.stats-details.forms.field.dateRange.label")}
                                    description={t("page.administration.stats-details.forms.field.dateRange.description")}
                                    startDate={range.start}
                                    endDate={range.end}
                                    onRangeChange={(start, end) => setRange({ start, end })}
                                />
                            </Form.Group>

                            <Col md={{ span: 2, offset: 0 }} sm={12} className="mb-3 mx-auto d-flex align-items-end">
                                <Button className="w-100" variant="primary" onClick={() => handleFilters()}>
                                    Submit
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>

            <Row>
                <Col className="">
                    <div className="sub-body-container p-0">
                        <Chart id={id || ""} hasDetails={false} title={chartTitle} description={chartDescription}>
                            <Stack direction="horizontal" gap={3}>
                                {tagArea && (
                                    <div className="bg-white mb-3">
                                        <AreaBadge name={tagArea || ""} />
                                    </div>
                                )}
                                {tagService && (
                                    <div className="bg-white mb-3">
                                        <ServiceBadge name={tagService} />
                                    </div>
                                )}
                                {tagRangeDate && (
                                    <div className="bg-white mb-3">
                                        <GenericBadge name={tagRangeDate} tooltip="Date Range" icon="R" bg="dark" />
                                    </div>
                                )}
                            </Stack>

                            {stats && (
                                <LineChart
                                    cumulative={chartCumulative}
                                    vehicleLineData={stats.vehicleLineData}
                                    paidOffenseLineData={stats.paidOffenseLineData}
                                    offenseLineData={stats.offenseLineData}
                                />
                            )}

                            {stats == null && (
                                <Placeholder as={"span"} animation="glow">
                                    <Placeholder xs={12} />
                                </Placeholder>
                            )}
                        </Chart>
                    </div>
                </Col>
            </Row>

            <Row className="sub-body-container">
                <Col md={{ offset: 4, span: 4 }}>
                    <Button variant="outline-dark" className="w-100 rounded-0 mb-2" onClick={() => navigate(routes.administrationStats.route)}>
                        <ChevronDoubleLeft className="m-1" />
                        {t("globals.actions.previous")}
                    </Button>
                </Col>
            </Row>
        </Container>
    );
};

export default LineChartDetails;
