import { ReactNode, useState } from "react";

import { styled } from "styled-components";
import { format } from "date-fns";
import { Badge, Col, Collapse, Row } from "react-bootstrap";
import { ArrowBarDown, ArrowBarUp, Calendar3, GeoAlt, PersonCircle, ShieldFillExclamation } from "react-bootstrap-icons";
import { Offense, OffenseType } from "../../utils/models/offense";
import { useTranslation } from "react-i18next";
import { DEFAULT_DATE_TIME_FORMAT } from "../../utils/constants";

interface IOffenseReportItem {
    offense: Offense;
}

const OffenseTop = styled(Row)`
    background-color: rgba(217, 217, 217, 0.23);
`;

const OffenseBottom = styled(Row)`
    background-color: rgba(217, 217, 217, 0.23);
    border-top: 1px solid rgba(217, 217, 217, 0.5);

    & .displayer {
        background-color: rgba(217, 217, 217, 0.5);
    }
`;

const OffenseReportItem = ({ offense }: IOffenseReportItem) => {
    const { t } = useTranslation();
    const MAX_TITLE_CHARS = 100;

    const [showDetails, setShowDetails] = useState<boolean>(false);

    const levels = Object.keys(offense.mapOffenseTypes ? offense.mapOffenseTypes : {});

    const listeOffenseTypes: OffenseType[] = [];

    if (offense.mapOffenseTypes != null)
        for (const values of Object.values(offense.mapOffenseTypes)) {
            listeOffenseTypes.push(...values);
        }

    let offenseTitle = "";

    for (const str of listeOffenseTypes) {
        if (offenseTitle.length + str.name.length <= MAX_TITLE_CHARS) {
            if (offenseTitle.length !== 0) {
                offenseTitle += ", ";
            }
            offenseTitle += str.name;
        } else {
            if (offenseTitle.length !== 0) {
                offenseTitle += ", ";
            }
            offenseTitle += str.name;
            offenseTitle = offenseTitle.slice(0, MAX_TITLE_CHARS - 4);
            offenseTitle += "...";
            break;
        }
    }

    return (
        <div className="offense-item mb-4">
            <OffenseTop className="offense-top">
                <Col md="10" sm="11" xs="12">
                    <Row className="mb-2 mt-1">
                        <Col>
                            <ShieldFillExclamation className="fs-5" /> {offenseTitle}
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col xs="12" md="5">
                            <Calendar3 className="fs-5" /> {format(new Date(offense.date), DEFAULT_DATE_TIME_FORMAT)}
                        </Col>
                        <Col xs="12" md="3">
                            <PersonCircle className="fs-5" /> {offense.agent}
                        </Col>
                        <Col xs="12" md="4">
                            <GeoAlt className="fs-5" /> {offense.city} ({offense.state.label})
                        </Col>

                        <Col xs="12" className="align-middle align-self-center mb-1 d-block d-sm-none">
                            {offense.payment && (
                                <Badge bg="success" text="light">
                                    {t("page.offense.payment.status.paid.label")}
                                </Badge>
                            )}
                            {!offense.payment && (
                                <Badge bg="warning" text="dark">
                                    {t("page.offense.payment.status.not-paid.label")}
                                </Badge>
                            )}
                        </Col>
                    </Row>
                </Col>
                <Col md="1" className="align-middle align-self-center d-none d-sm-block">
                    {offense.payment && (
                        <Badge bg="success" text="light">
                            {t("page.offense.payment.status.paid.label")}
                        </Badge>
                    )}
                    {!offense.payment && (
                        <Badge bg="warning" text="dark">
                            {t("page.offense.payment.status.not-paid.label")}
                        </Badge>
                    )}
                </Col>
            </OffenseTop>

            <OffenseBottom className="offense-bottom">
                <Collapse in={showDetails}>
                    <Row>
                        <Row className="mb-2">
                            <Col md={12}>
                                <p className="mb-0 fw-bold">{t("page.offense.forms.offense-type.header.label")}</p>
                            </Col>

                            <Col md={12}>
                                {levels.map((itemLevel) => (
                                    <Row key={itemLevel}>
                                        <Col md={2} className="fst-italic">
                                            {itemLevel}
                                        </Col>
                                        <Col md="10">
                                            {offense.mapOffenseTypes &&
                                                offense.mapOffenseTypes[itemLevel].map((itemOffense) => (
                                                    <Badge className="me-2 mb-2" key={itemOffense.id}>
                                                        {itemOffense.name}
                                                    </Badge>
                                                ))}
                                        </Col>
                                    </Row>
                                ))}
                            </Col>
                        </Row>

                        <Row className="mb-2">
                            <p className="mb-0 fw-bold">{t("page.offense.forms.offense-driver.header.label")}</p>
                            <p className="mb-0 ms-3">
                                {offense.firstName != null && offense.lastName != null ? (
                                    <>
                                        {offense.lastName.toUpperCase()} {offense.firstName}
                                    </>
                                ) : (
                                    <>{t("page.offense.forms.offense-overview.driver.not-provided")}</>
                                )}
                            </p>
                        </Row>

                        <Row className="mb-2">
                            <p className="mb-0 fw-bold">{t("page.offense.forms.offense-location.header.label")}</p>
                            <p className="mb-0 ms-3">
                                {offense.address} {offense.city} - {offense.state.label}
                            </p>
                        </Row>

                        <Row className="mb-2">
                            <p className="mb-0 fw-bold">{t("page.offense.forms.offense-additional-information.field.comment.label")}</p>
                            <p className="mb-0 ms-3">{offense.comment ? offense.comment : t("page.vehicle.report.offense.comment-not-provided")}</p>
                        </Row>
                    </Row>
                </Collapse>

                <Row className="displayer text-center p-0 m-0" onClick={() => setShowDetails(!showDetails)}>
                    <Col>{showDetails ? <ArrowBarUp /> : <ArrowBarDown />}</Col>
                    <Col>{showDetails ? <ArrowBarUp /> : <ArrowBarDown />}</Col>
                </Row>
            </OffenseBottom>
        </div>
    );
};

export default OffenseReportItem;
