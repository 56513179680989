import { ChangeEvent, useRef, useState } from "react";
import { Alert, Button, Col, Form, Row, Stack } from "react-bootstrap";
import { Check2Square, ChevronDoubleLeft, CloudArrowUpFill, FiletypeCsv, InfoCircleFill, TrashFill } from "react-bootstrap-icons";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { exportCSV, isCsvFile } from "../../../../utils/fileUtils";
import { useTranslation } from "react-i18next";
import { bulkCreateUsers } from "../../../../services/userService";
import { BulkCreateUserResponse } from "../../../../utils/models/user";

const FileUpload = styled(Alert)`
    border-style: dotted;
    border-width: 2px;
    border-color: #c4c8cb;
    cursor: pointer;
`;

const FileDetails = styled.div`
    font-size: 15px;
`;

export const BulkImportUserForm = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const inputElement = useRef<HTMLInputElement | null>(null);

    const [file, setFile] = useState<File | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [createResponse, setCreationResponse] = useState<BulkCreateUserResponse | null>(null);

    const resetInputField = () => {
        if (inputElement && inputElement.current) inputElement.current.value = "";
    };
    const handleRemoveFile = () => {
        setFile(null);
        setCreationResponse(null);
        resetInputField();
    };

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        setError(null);

        setCreationResponse(null);
        if (event.target.files) {
            const upload = event.target.files[0];

            if (!isCsvFile(upload)) {
                setError(t("There is en error on your file. Only csv file are accepted!"));
            } else {
                setFile(upload);
            }
            //setError
        }
    };

    const handleSaveUsers = () => {
        if (file) {
            bulkCreateUsers(file)
                .then((response) => {
                    setError(null);
                    setCreationResponse(response);

                    setFile(null);
                    resetInputField();
                })
                .catch((error) => {
                    //TODO Ramener à la page précédente ou page d'erreur
                    setCreationResponse(null);
                });
        }
    };

    const exportCSVTemplate = () => {
        const csvContent =
            "lastName,firstName,badge,email,role,area,service,status\nDOSSO,Soumana,123ABC,s.dosso@tsc.com,ADMIN,Zone_B,Service_B,1\nNDIAYE,Malick,XTR123,m.ndiaye@tsc.com,AGENT,ZONE_A,SERVICE_A,0";
        const filename = "modele-user.csv";
        exportCSV(csvContent, filename);
    };

    return (
        <div>
            <Row>
                <Col className="mb-3">
                    <Alert variant="primary" dismissible>
                        <InfoCircleFill /> This form allows you to bulk import multiple users and create multiple accounts!
                    </Alert>
                </Col>
            </Row>

            {error && (
                <Row>
                    <Col>
                        <Alert variant="danger" dismissible>
                            <InfoCircleFill /> {error}
                        </Alert>
                    </Col>
                </Row>
            )}

            {createResponse && createResponse.status.KO === 0 && (
                <Row>
                    <Col>
                        <Alert variant="success" dismissible>
                            <InfoCircleFill className="m-auto" /> Mass user creation was successfully completed.
                            <ul className="mb-0">
                                <li>Users in file: {createResponse.status.NB}</li>
                                <li>Users added: {createResponse.status.OK}</li>
                                <li>Users failed: {createResponse.status.KO}</li>
                            </ul>
                        </Alert>
                    </Col>
                </Row>
            )}

            {createResponse && createResponse.status.KO > 0 && (
                <Row>
                    <Col>
                        <Alert variant="warning" dismissible>
                            <p>
                                <InfoCircleFill className="m-auto" /> Mass user creation was completed, but errors were noticed. The following rows
                                may have some errors : {createResponse.failures.join(", ")}.
                            </p>

                            <ul className="mb-0">
                                <li>Users in file: {createResponse.status.NB}</li>
                                <li>Users added: {createResponse.status.OK}</li>
                                <li>Users failed: {createResponse.status.KO}</li>
                            </ul>
                        </Alert>
                    </Col>
                </Row>
            )}

            <Row>
                <Col md="4">
                    <FileUpload variant="light" onClick={() => inputElement.current?.click()}>
                        <div>
                            <Row className="py-4 mx-1">
                                <Col className="text-center mb-5">
                                    <CloudArrowUpFill className="fs-1" color="#2b2f32" />
                                </Col>
                                <Form.Label className="fs-6 text-center w-100">Select a csv file to upload</Form.Label>
                                <Form.Group controlId="formFile" className="mb-3">
                                    <Form.Control type="file" hidden ref={inputElement} onChange={handleFileChange} />
                                </Form.Group>
                            </Row>
                        </div>
                    </FileUpload>

                    {file && (
                        <div>
                            <Alert variant="light">
                                <Row>
                                    <Col xs="1" md="2" className="align-middle m-auto">
                                        <FiletypeCsv className="fs-1" />
                                    </Col>
                                    <Col xs="10" md="8">
                                        <Stack>
                                            <FileDetails>{file.name}</FileDetails>
                                            <FileDetails>{Math.ceil(file.size / 1024)} KB</FileDetails>
                                        </Stack>
                                    </Col>
                                    <Col xs="1" md="2" className="align-middle m-auto">
                                        <TrashFill role="button" className="fs-5" onClick={() => handleRemoveFile()} />
                                    </Col>
                                </Row>
                            </Alert>
                        </div>
                    )}
                </Col>
                <Col md="8">
                    <Alert variant="secondary">
                        <p>
                            Download this{" "}
                            <Alert.Link as={Link} to="#" onClick={() => exportCSVTemplate()}>
                                sample
                            </Alert.Link>{" "}
                            user import file.{" "}
                        </p>
                        <p>You must keep the first line and the order in which the fields are defined.</p>
                        <ol>
                            <li>
                                <span className="fw-bold">lastName</span>: The user last name
                            </li>
                            <li>
                                <span className="fw-bold">firstName</span>: The user first name
                            </li>
                            <li>
                                <span className="fw-bold">badge</span>: The user registration ID (this field must be unique)
                            </li>
                            <li>
                                <span className="fw-bold">email</span>: The user email (this field must be unique)
                            </li>
                            <li>
                                <span className="fw-bold">role</span>: <i>AGENT</i> for an agent, and <i>ADMIN</i> for an administrator
                            </li>
                            <li>
                                <span className="fw-bold">area</span>: The Area where the agent will belongs (using the area code)
                            </li>
                            <li>
                                <span className="fw-bold">service</span>: The Service where the agent will be reattached (using the service code)
                            </li>
                            <li>
                                <span className="fw-bold">status</span>: <i>1</i> to enable et <i>0</i> otherwise
                            </li>
                        </ol>
                    </Alert>
                </Col>
            </Row>

            <Row>
                <Col md={{ offset: 2, span: 4 }}>
                    <Button variant="outline-dark" className="w-100 rounded-0 mb-2" onClick={() => navigate(-1)}>
                        <ChevronDoubleLeft className="m-1" />
                        {t("globals.actions.previous")}
                    </Button>
                </Col>
                <Col md={{ span: 4 }}>
                    <Button disabled={file == null} variant="success" className="w-100 rounded-0 mb-2" onClick={() => handleSaveUsers()}>
                        <Check2Square className="m-1" />
                        {t("globals.actions.save")}
                    </Button>
                </Col>
            </Row>
        </div>
    );
};
