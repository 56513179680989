import { Navigate, Outlet } from "react-router-dom";
import Header from "../components/Header/Header";
import { styled } from "styled-components";
import Breadcrumb from "../components/Breadcrumb/Breadcrumb";
import Footer from "../components/Footer/Footer";
import { routes } from "../utils/routes";
import { isAuthenticated } from "../services/authenticationService";


const RootBodyStyle = styled('div')({
    display: 'flex',
    minHeight: '100%',
    overflow: 'hidden',
    margin: '5px 0 50px'
});

function StandardLayout() {

    if (!isAuthenticated()) {
        // Redirigez l'utilisateur vers la page de connexion s'il n'est pas authentifié
        return <Navigate to={routes.logIn.route} />;
    }

    return (
        <div className="w-100">
            <Header />

            <div className="bg-main-body">
                <Breadcrumb />
                <main>
                    <RootBodyStyle>
                        <Outlet />
                    </RootBodyStyle>
                </main>
                <Footer />
            </div>
        </div>
    )

}

export default StandardLayout;