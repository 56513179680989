import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CHARTS_ID } from "../../../../utils/constants";
import { routes } from "../../../../utils/routes";
import LineChartDetails from "./LineChartDetails";
import TopChartDetails from "./TopChartDetails";
import NotFound from "../../../error/NotFound";

const StatisticsDetailsHome = () => {

    const { id } = useParams<{ id: string }>();

    const navigate = useNavigate();

    if (!id) {
        navigate(routes.notfound.route);
        return <NotFound />
    }
    if (id === CHARTS_ID.EVOLUTION_V_O || id == CHARTS_ID.VEHICLE_OFFENSE_PER_MONTH) {
        return <LineChartDetails />;

    } else if (id === CHARTS_ID.TOP_OFFENSES_BY_CITY || id == CHARTS_ID.TOP_OFFENSES_BY_TYPE) {
        return <TopChartDetails />;

    } else {
        navigate(routes.notfound.route);
        return <NotFound />
    }

}

export default StatisticsDetailsHome;