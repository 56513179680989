import { Button, Col, Container, Form, InputGroup, Placeholder, Row, Stack, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ChangeEvent, KeyboardEvent, useEffect, useState } from "react";
import { useUserContext } from "../../../../context/UserContext";
import { CHARTS_ID } from "../../../../utils/constants";
import Chart from "../../../../components/Chart/Chart";
import { useNavigate, useParams } from "react-router-dom";
import { getDetailledChartTopStats } from "../../../../services/statisticService";
import { ChevronDoubleLeft, Search, Toggle2Off, Toggle2On } from "react-bootstrap-icons";
import { routes } from "../../../../utils/routes";
import { DoughnutChart } from "../../../../components/Chart/DoughnutChart";
import FormControl from "../../../../components/@extends/react-bootstrap/FormControl";
import { exportCSV } from "../../../../utils/fileUtils";

const TopChartDetails = () => {
    const { id } = useParams();
    const MIN_LIMIT = 1;

    const { t } = useTranslation();
    const navigate = useNavigate();
    const { user } = useUserContext();

    const [modeTable, setModeTable] = useState<boolean>(false);
    const [limit, setLimit] = useState<number | string>(10);
    const [disableSearchLimit, setDisableSearchLimit] = useState<boolean>(true);

    const [values, setValues] = useState<Record<string, number>>();
    const [chartTitle, setChartTitle] = useState<string>("");
    const [chartDescription, setChartDescription] = useState<string>("");
    const [chartType, setChartType] = useState<string>("");

    function handleFilters(): void {
        const intLimit = parseInt(limit.toString());
        if (!isNaN(intLimit) && intLimit >= MIN_LIMIT)
            getDetailledChartTopStats({
                limit: intLimit,
                type: chartType,
                area: user?.reattachmentArea?.id || 0,
                service: user?.reattachmentService?.id || null,
            })
                .then((response) => {
                    if (id === CHARTS_ID.TOP_OFFENSES_BY_CITY) {
                        setValues(response.topOffenseByState);
                    } else if (id === CHARTS_ID.TOP_OFFENSES_BY_TYPE) {
                        setValues(response.topOffenseType);
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
    }

    function handleSearchKeyUp(event: KeyboardEvent<HTMLInputElement>): void {
        if (event.code === "Enter") {
            handleFilters();
        }
    }

    useEffect(() => {
        if (!id || id === undefined) {
            return; //ERROR
        }

        setChartType(id);
        setChartTitle(t(`page.administration.stats.chart.graph.${id}.title`));
        setChartDescription(t(`page.administration.stats.chart.graph.${id}.description`));
    }, [id]);

    useEffect(() => {
        if (limit === "") {
            setDisableSearchLimit(true);
        } else {
            const intLimit = parseInt(limit.toString());
            if (!isNaN(intLimit) && intLimit >= MIN_LIMIT) {
                setDisableSearchLimit(false);
            }
        }
    }, [limit]);

    useEffect(() => {
        if (user && id && chartType !== "") {
            getDetailledChartTopStats({
                limit: 10,
                type: chartType,
                area: user.reattachmentArea?.id || 0,
                service: user.reattachmentService?.id || null,
            })
                .then((response) => {
                    if (id === CHARTS_ID.TOP_OFFENSES_BY_CITY) {
                        setValues(response.topOffenseByState);
                    } else if (id === CHARTS_ID.TOP_OFFENSES_BY_TYPE) {
                        setValues(response.topOffenseType);
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        }
    }, [navigate, user, id, chartType]);

    function handleLimitChange(event: ChangeEvent<HTMLInputElement>): void {
        setLimit(event.target.value);
    }

    function handleExportCsv(): void {
        let content = t(`page.administration.stats-details.${id}.table.columns.label`);
        content += ",";
        content += t(`page.administration.stats-details.${id}.table.columns.value`);
        content += "\n";

        values &&
            Object.entries(values).map(([key, value]) => {
                content += `"${key}",${value}\n`;
            });

        exportCSV(content, `tsc-${id}-top-offenses.csv`);
    }

    return (
        <Container fluid="lg">
            <Row className="text-center mb-3">
                <h1>{t("page.administration.stats-details.mainTitle")}</h1>
            </Row>

            <Row>
                <Col className="pe-0">
                    <div className="sub-body-container">
                        <Row>
                            <Col className="d-flex align-items-start mb-sm-4 mb-md-0" md="6" sm={12}>
                                <Form.Group className="">
                                    <Stack direction="horizontal" gap={1} className="mx-auto ">
                                        <div role="button" className={`${modeTable ? "fw-bold" : ""}`} onClick={() => setModeTable(true)}>
                                            {t("page.administration.stats.chart.mode.table")}
                                        </div>
                                        <div>
                                            {modeTable && (
                                                <>
                                                    <Toggle2Off
                                                        role="button"
                                                        className="me-1 fs-2"
                                                        color="#dc3545"
                                                        title={t("user.account.locked")}
                                                        onClick={() => setModeTable(false)}
                                                    />
                                                </>
                                            )}
                                            {!modeTable && (
                                                <>
                                                    <Toggle2On
                                                        role="button"
                                                        className="me-1 fs-2"
                                                        color="#198754"
                                                        title={t("user.account.locked")}
                                                        onClick={() => setModeTable(true)}
                                                    />
                                                </>
                                            )}
                                        </div>
                                        <div role="button" className={`${modeTable ? "" : "fw-bold"}`} onClick={() => setModeTable(false)}>
                                            {t("page.administration.stats.chart.mode.chart")}
                                        </div>
                                    </Stack>
                                    <Form.Text className="text-muted mt-0 mb-1 d-block">
                                        {t("page.administration.stats-details.forms.field.modeTableChart.description")}
                                    </Form.Text>
                                </Form.Group>
                            </Col>

                            <Col className="" md="6" sm={12}>
                                <InputGroup>
                                    <FormControl
                                        className="b-0 no-focus header-search"
                                        required
                                        placeholder="Limit"
                                        type="number"
                                        name="limit"
                                        value={limit}
                                        onChange={handleLimitChange}
                                        onKeyUp={handleSearchKeyUp}
                                    />
                                    <Button variant="primary" onClick={() => handleFilters()} disabled={disableSearchLimit}>
                                        <Search className="m-1" />
                                    </Button>
                                </InputGroup>
                                <Form.Text className="text-muted mt-0 mb-1 d-block">
                                    {t("page.administration.stats-details.forms.field.limit.description")}
                                </Form.Text>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>

            {!modeTable && (
                <Row>
                    <Col className="">
                        <div className="sub-body-container p-0">
                            <Chart id={id || ""} hasDetails={false} title={chartTitle} description={chartDescription}>
                                {values && <DoughnutChart data={values} />}

                                {values == null && (
                                    <Placeholder as={"span"} animation="glow">
                                        <Placeholder xs={12} />
                                    </Placeholder>
                                )}
                            </Chart>
                        </div>
                    </Col>
                </Row>
            )}

            {modeTable && (
                <Row>
                    <Col className="">
                        <div className="sub-body-container p-0">
                            <Chart
                                id={id || ""}
                                hasDetails={false}
                                hasExportCsv={true}
                                handleExportCsv={handleExportCsv}
                                title={chartTitle}
                                description={chartDescription}
                            >
                                <Table responsive striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>{t(`page.administration.stats-details.${id}.table.columns.label`)}</th>
                                            <th>{t(`page.administration.stats-details.${id}.table.columns.value`)}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {values && Object.keys(values).length === 0 && (
                                            <tr>
                                                <td colSpan={2}>
                                                    <div className="text-center">{t("page.administration.stats-details.table.empty")}</div>
                                                </td>
                                            </tr>
                                        )}

                                        {values &&
                                            Object.entries(values).map(([key, value], index) => (
                                                <tr key={key}>
                                                    <th>{index + 1}</th>
                                                    <th>{key}</th>
                                                    <td>{value}</td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </Table>
                            </Chart>
                        </div>
                    </Col>
                </Row>
            )}

            <Row className="sub-body-container">
                <Col md={{ offset: 4, span: 4 }}>
                    <Button variant="outline-dark" className="w-100 rounded-0 mb-2" onClick={() => navigate(routes.administrationStats.route)}>
                        <ChevronDoubleLeft className="m-1" />
                        {t("globals.actions.previous")}
                    </Button>
                </Col>
            </Row>
        </Container>
    );
};

export default TopChartDetails;
