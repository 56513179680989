import { Routes, Route, Navigate } from "react-router-dom";
import StandardLayout from "./layouts/StandardLayout";
import NotFound from "./pages/error/NotFound";

import "./App.scss";
import { routes } from "./utils/routes";
import LogInLayout from "./layouts/LogInLayout";
import LogIn from "./pages/login/LogIn";
import Search from "./pages/vehicle/information/Search";
import OffenseManagement from "./pages/offense/OffenseManagement";
import VehicleReport from "./pages/vehicle/information/VehicleReport";
import AdministrationLayout from "./layouts/AdministrationLayout";
import AdminHome from "./pages/administration/AdminHome";
import { ListUser } from "./pages/administration/user/ListUser";
import ProfileEdition from "./pages/administration/user/ProfileEdition";
import ProfileVisualize from "./pages/administration/user/ProfileVisualize";
import CreateUser from "./pages/administration/user/create/CreateUser";
import OffenseTypes from "./pages/administration/types/offenses/OffenseTypes";
import { ListTrace } from "./pages/administration/traces/ListTrace";
import StatisticsHome from "./pages/administration/statistics/StatisticsHome";
import { ListVehicle } from "./pages/dashboard/vehicle/ListVehicle";
import ManagerLayout from "./layouts/ManagerLayout";
import UserAccount from "./pages/user/UserAccount";
import { AssignmentHome } from "./pages/administration/assignment/AssignmentHome";
import StatisticsDetailsHome from "./pages/administration/statistics/details/StatisticsDetailsHome";
import ResetPassword from "./pages/login/ResetPassword";

function App() {
    return (
        <Routes>
            <Route>
                <Route element={<LogInLayout />}>
                    <Route path={routes.logIn.route} element={<LogIn />} />
                    <Route path={routes.resetPassword.route} element={<ResetPassword />} />
                </Route>

                <Route element={<StandardLayout />}>
                    <Route path={routes.home.route} element={<Search />} />
                    <Route path={routes.vehicle.route} element={<VehicleReport />} />
                    <Route path={routes.offense.route} element={<OffenseManagement />} />

                    <Route path={routes.userAccount.route} element={<UserAccount />} />

                    <Route path={routes.notfound.route} element={<NotFound />} />
                    <Route path="*" element={<Navigate to={routes.notfound.route} replace />} />

                    <Route element={<ManagerLayout />}>
                        <Route path={routes.administration.route} element={<AdminHome />} />
                        <Route path={routes.administrationUsers.route} element={<ListUser />} />
                        <Route path={routes.administrationUserProfileVisualize.route} element={<ProfileVisualize />} />
                        <Route path={routes.administrationUserProfileEdition.route} element={<ProfileEdition />} />
                        <Route path={routes.administrationUserCreation.route} element={<CreateUser />} />
                        <Route path={routes.administrationAssignment.route} element={<AssignmentHome />} />

                        <Route path={routes.administrationOffenseTypes.route} element={<OffenseTypes />} />

                        <Route path={routes.dashboardListVehicle.route} element={<ListVehicle />} />

                        <Route element={<AdministrationLayout />}>
                            <Route path={routes.administrationTraces.route} element={<ListTrace />} />
                            <Route path={routes.administrationStats.route} element={<StatisticsHome />} />
                            <Route path={routes.administrationStatsLineChartDetails.route} element={<StatisticsDetailsHome />} />
                        </Route>
                    </Route>
                </Route>
            </Route>
        </Routes>
    );
}

export default App;
