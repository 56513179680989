import { API_BASE_URL } from '../utils/constants';
import { Numberplate, OffenseCreation, OffenseTypeBokkmark } from '../utils/models/offense';
import { SearchQuery } from '../utils/models/user';
import request from "./apiUtils";


export function apiValidateNumberplate(numberplate:Numberplate) {
    return request({
        url: API_BASE_URL + "/offense/validate-numberplate",
        method: 'POST',
        body: JSON.stringify(numberplate)
    });
}

export function fetchAllOffenseTypes() {
    return request({
        url: API_BASE_URL + "/offense/types",
        method: 'GET'
    });
}

export function fetchAllBookmarkedOffenseTypes() {
    return request({
        url: API_BASE_URL + "/offense/types/bookmark",
        method: 'GET'
    });
}

export function fetchAllOffenseGroups() {
    return request({
        url: API_BASE_URL + "/offense/groups",
        method: 'GET'
    });
}

export function assignOffenseTypeToGroups(type: string, group: number) {

    return request({
        url: API_BASE_URL + "/offense/type/assign-to-group",
        method: 'POST',
        body: JSON.stringify({type, group})
    });
}


export function createOffense(createRequest:OffenseCreation) {

    return request({
        url: API_BASE_URL + "/offense/create",
        method: 'POST',
        body: JSON.stringify(createRequest)
    });
}

export function getVehicleOffenses(numberplate: Numberplate) {

    return request({
        url: API_BASE_URL + "/offense/vehicle-offenses",
        method: 'POST',
        body: JSON.stringify(numberplate)
    });
}

export function bookmarkOffenseType(type: OffenseTypeBokkmark) {

    return request({
        url: API_BASE_URL + "/offense/type/bookmark",
        method: 'POST',
        body: JSON.stringify(type)
    });
}

export function getOffensesOfAgent(query: SearchQuery) {
    return request({
        url: API_BASE_URL + "/offense/by-agent",
        method: 'POST',
        body: JSON.stringify(query)
    });
}