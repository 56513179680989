import { User } from "./models/user";

const size = {
    xs: 0,
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1200px',
    xxl: '1400px'
};

export const devices = {
    xs: `(min-width: ${size.xs})`,
    sm: `(min-width: ${size.sm})`,
    md: `(min-width: ${size.md})`,
    lg: `(min-width: ${size.lg})`,
    xl: `(min-width: ${size.xl})`,
    xxl: `(min-width: ${size.xxl})`
};

export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL ?? 'http://localhost:8080/api';
export const ACCESS_TOKEN = 'accessToken';
export const CURRENT_USER = 'currentU';

export const DEFAULT_DATE_TIME_FORMAT = 'yyyy-MM-dd\' at \'hh:mm a';
export const DEFAULT_DATE_TIME_FORMAT2 = 'yyyy-MM-dd hh:mm a';
export const DEFAULT_DATE_FORMAT = 'yyyy-MM-dd';
export const DEFAULT_HOUR_MINUTES_FORMAT = 'HH:mm';

export const ADMINISTRATIVE_STATUS_TYPE = {
    PLEDGE: 'PLEDGE',
    SEIZURE_STATEMENT: 'SEIZURE_STATEMENT',
    CRASHED_CAR: 'CRASHED_CAR',
    VEHICLE_DECLARED_STOLEN: 'VEHICLE_DECLARED_STOLEN',
    VEHICLE_DECLARED_LOST: 'VEHICLE_DECLARED_LOST',
    CERTIFICATE_DECLARED_STOLEN: 'CERTIFICATE_DECLARED_STOLEN',
    CERTIFICATE_DECLARED_LOST: 'CERTIFICATE_DECLARED_LOST',
    CERTIFICATE_DUPLICATED: 'CERTIFICATE_DUPLICATED',
    REGISTRATION_SUSPENDED: 'REGISTRATION_SUSPENDED',
    REGISTRATION_CANCELED: 'REGISTRATION_CANCELED'
}

export const PAGE_SIZE = [5, 10, 20, 30, 50, 75]

export const MIN_PASSWORD = 8;
export const MAX_PASSWORD = 20;

export const GLOBAL_DEFAULT_USER: User = {
    id: "",
    firstName: "",
    lastName: "",
    matricule: "",
    email: "",
    createdAt: "",
    updatedAt: "",
    authorities: [],
    enabled: false,
    valid: false,
    reattachmentService: null,
    reattachmentArea: null,
    hasAssignment: false
}

export const ROLES = {
    ROLE_AGENT: "ROLE_AGENT",
    ROLE_SERVICE_ADMIN: "ROLE_SERVICE_ADMIN",
    ROLE_AREA_MANAGER: "ROLE_AREA_MANAGER"
}

export const CHARTS_ID = {
    VEHICLE_OFFENSE_PER_MONTH: "VOM", //Vehicles and violations per month
    EVOLUTION_V_O: "EVO", //Evolution of vehicles and offenses
    TOP_OFFENSES_BY_TYPE: "TOT", //Top offense by type
    TOP_OFFENSES_BY_CITY: "TOC" //Top offenses by city
}