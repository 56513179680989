import { Alert, Button, Col, Collapse, Container, Form, Modal, Pagination, Row, Stack, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { cancelAssignmentUser, processingRequest, searchAssignments } from "../../../services/assignmentService";
import { ChangeEvent, useEffect, useState } from "react";
import { Assignment } from "../../../utils/models/assignment";
import { useUserContext } from "../../../context/UserContext";
import { EyeFill, InfoLg, PencilSquare } from "react-bootstrap-icons";
import { AreaBadge } from "../../../components/Reattachement/AreaBadge";
import { ServiceBadge } from "../../../components/Reattachement/ServiceBadge";
import FormSelect from "../../../components/@extends/react-bootstrap/FormSelect";
import { DEFAULT_DATE_FORMAT, PAGE_SIZE, ROLES } from "../../../utils/constants";
import { User } from "../../../utils/models/user";
import { format } from "date-fns";
import { getUsersRoles } from "../../../services/userService";
import { isNonEmpty } from "../../../utils/stringUtils";

export const AssignmentHome = () => {
    const { t } = useTranslation();

    const [success, setSuccess] = useState<boolean | null>(null);
    const [message, setMessage] = useState<string>("");

    const [searchUser, handleSearchChange] = useState<string>("");

    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(1);
    // Handling pagination
    const [totalPages, setTotalPages] = useState<number>(1);
    const [totalElements, setTotalElements] = useState<number>(1);

    const [assignments, setAssignments] = useState<Assignment[]>([]);
    const [assignmentToValidate, setAssignmentToValidate] = useState<Assignment | null>(null);
    const [availableUserRoles, setAvailableUserRoles] = useState<string[]>([]);
    const [userRoles, setUserRoles] = useState<string[]>([]);
    const [selectedRole, setSelectedRole] = useState<string>("");
    const [hasConfirmAssignment, setHasConfirmAssignment] = useState<boolean | null>(null);

    const [canConfirmRefuseAccept, setCanConfirmRefuseAccept] = useState<boolean>(false);
    const [wantToCancel, setWantToCancel] = useState<boolean>(false);

    const { user } = useUserContext();

    const handleSearch = (page: number) => {
        const searchVal = searchUser.trim();
        handleSearchChange(searchVal);
        searchAssignments({
            search: searchVal,
            page: page - 1,
            pageSize: pageSize,
        })
            .then((response) => {
                setAssignments(response.content);

                setTotalPages(response.totalPages);
                setTotalElements(response.totalElements);
            })
            .catch((error) => {
                //TODO Ramener à la page précédente ou page d'erreur
            });
    };

    useEffect(() => {
        handleSearch(page);
    }, [pageSize]);

    useEffect(() => {
        if (availableUserRoles && availableUserRoles.length > 0) {
            setSelectedRole(availableUserRoles[0]);
        }
    }, [availableUserRoles]);

    useEffect(() => {
        if (hasConfirmAssignment) {
            // Confirm assignment
            setCanConfirmRefuseAccept(isNonEmpty(selectedRole));
        } else {
            // Previous
            setCanConfirmRefuseAccept(true);
        }
    }, [selectedRole, hasConfirmAssignment]);

    useEffect(() => {
        getUsersRoles()
            .then((response) => {
                setUserRoles(response);
                setAvailableUserRoles(response);
            })
            .catch((error) => {
                //TODO Ramener à la page précédente ou page d'erreur
            });
    }, []);

    const handlePageSizeChange = (event: ChangeEvent<HTMLSelectElement>): void => {
        const selectedOption = event.target.selectedOptions[0].value;
        setPage(1);
        setPageSize(parseInt(selectedOption));
    };

    const handleRoleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedOption = event.target.selectedOptions[0];
        const optionValue = selectedOption.value || "0";
        setSelectedRole(optionValue);
    };

    const displayUserFullName = (user: User | null | undefined) => {
        if (user != null) {
            return user.lastName.toUpperCase() + " " + user.firstName + " (" + user.matricule + ")";
        }

        return "-";
    };

    let isPageNumberOutOfRange: boolean = false;
    const pageNumbers = [...new Array(totalPages)].map((_, index) => {
        const pageNumber = index + 1;
        const isPageNumberFirst = pageNumber === 1;
        const isPageNumberLast = pageNumber === totalPages;
        const isCurrentPageWithinMorePageNumbers = Math.abs(pageNumber - page) <= 4;

        if (isPageNumberFirst || isPageNumberLast || isCurrentPageWithinMorePageNumbers) {
            isPageNumberOutOfRange = false;

            if (pageNumber === page) {
                return (
                    <Pagination.Item key={pageNumber} active={true}>
                        {pageNumber}
                    </Pagination.Item>
                );
            } else {
                return (
                    <Pagination.Item key={pageNumber} onClick={() => handlePageChange(pageNumber)}>
                        {pageNumber}
                    </Pagination.Item>
                );
            }
        }

        if (!isPageNumberOutOfRange) {
            isPageNumberOutOfRange = true;
            return <Pagination.Ellipsis key={pageNumber} className="muted" />;
        }

        return null;
    });

    const handlePageChange = (page: number) => {
        setPage(page);
        handleSearch(page);
    };

    function displayAssign(assignment: Assignment, arg1: boolean): void {
        setAssignmentToValidate(assignment);
    }

    function handleRefuseAssignment(): void {
        if (assignmentToValidate) {
            processingRequest({
                status: false,
                role: selectedRole,
                id: assignmentToValidate?.id,
            })
                .then((response) => {
                    handleCloseModal();
                    handleSearch(page);
                    setMessage(t("page.administration.assignment.refuse.success"));
                })
                .catch((error) => {
                    //TODO Ramener à la page précédente ou page d'erreur
                    console.log({ error });
                });
        } else {
            //TODO Ramener à la page précédente ou page d'erreur
        }
    }

    function handleAcceptAssignment(): void {
        if (assignmentToValidate) {
            processingRequest({
                status: true,
                role: selectedRole,
                id: assignmentToValidate?.id,
            })
                .then((response) => {
                    handleCloseModal();
                    handleSearch(page);
                    setMessage(t("page.administration.assignment.accept.success"));
                })
                .catch((error) => {
                    //TODO Ramener à la page précédente ou page d'erreur
                    console.log({ error });
                });
        } else {
            //TODO Ramener à la page précédente ou page d'erreur
        }
    }

    function handleCloseModal(): void {
        setAssignmentToValidate(null);
        setHasConfirmAssignment(null);
        setSelectedRole("");
    }

    useEffect(() => {
        if (assignmentToValidate != null) {
            if (assignmentToValidate.reattachmentServiceTo == null) {
                setAvailableUserRoles(userRoles.filter((or) => or === ROLES.ROLE_AREA_MANAGER));
            } else {
                setAvailableUserRoles(userRoles.filter((or) => or !== ROLES.ROLE_AREA_MANAGER));
            }
        }
        setMessage("");
        setSuccess(null);
    }, [assignmentToValidate]);

    function cancelAssignment(): void {
        if (assignmentToValidate) {
            cancelAssignmentUser(assignmentToValidate.id)
                .then((response) => {
                    handleCloseModal();
                    handleSearch(page);
                    setMessage(t("page.administration.assignment.cancel.success"));
                })
                .catch((error) => {
                    //TODO Ramener à la page précédente ou page d'erreur
                    console.log({ error });
                });
        } else {
            //TODO Ramener à la page précédente ou page d'erreur
        }
    }

    return (
        <Container fluid="lg">
            <Row className="text-center mb-3">
                <h1>{t("page.administration.assignment.mainTitle")}</h1>
            </Row>

            {success !== null && isNonEmpty(message) && (
                <Row className="sub-body-container">
                    {success === true && <Alert variant="success">{message}</Alert>}
                    {success === false && <Alert variant="danger">{message}</Alert>}
                </Row>
            )}

            <Row className="sub-body-container pt-1">
                <div className="table-responsive">
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>{t("page.administration.assignment.table.columns.manager")}</th>
                                <th>{t("page.administration.assignment.table.columns.agent.id")}</th>
                                <th>{t("page.administration.assignment.table.columns.agent.areaService")}</th>
                                <th>{t("page.administration.assignment.table.columns.area")}</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {assignments.length === 0 && (
                                <tr>
                                    <td colSpan={6}>
                                        <div className="text-center">{t("page.administration.assignment.table.empty")}</div>
                                    </td>
                                </tr>
                            )}

                            {assignments.map((itAssignment, index) => (
                                <tr key={itAssignment.id}>
                                    <td>{pageSize * (page - 1) + (index + 1)}</td>
                                    <td>
                                        {itAssignment.manager.lastName.toUpperCase()} {itAssignment.manager.firstName} (
                                        {itAssignment.manager.matricule})
                                    </td>
                                    <td>
                                        {itAssignment.user.lastName.toUpperCase()} {itAssignment.user.firstName} ({itAssignment.user.matricule})
                                    </td>
                                    <td>
                                        <AreaBadge name={itAssignment.reattachmentAreaFrom.name} />

                                        {itAssignment.reattachmentServiceFrom && <ServiceBadge name={itAssignment.reattachmentServiceFrom.name} />}
                                    </td>

                                    <td>
                                        <AreaBadge name={itAssignment.reattachmentAreaTo.name} />

                                        {itAssignment.reattachmentServiceTo && <ServiceBadge name={itAssignment.reattachmentServiceTo.name} />}
                                    </td>

                                    <td className="text-center">
                                        <Stack direction="horizontal" gap={1}>
                                            {itAssignment.status === "INIT" && (
                                                <>
                                                    {itAssignment.reattachmentAreaTo.id === user?.reattachmentArea?.id &&
                                                    ((itAssignment.reattachmentServiceTo == null && user?.reattachmentService == null) ||
                                                        (itAssignment.reattachmentServiceTo != null &&
                                                            user?.reattachmentService != null &&
                                                            itAssignment.reattachmentServiceTo.id === user?.reattachmentService.id)) ? (
                                                        <Button variant="warning" size="sm" onClick={() => displayAssign(itAssignment, false)}>
                                                            <PencilSquare />
                                                        </Button>
                                                    ) : (
                                                        <Button
                                                            variant="outline-primary"
                                                            size="sm"
                                                            onClick={() => displayAssign(itAssignment, false)}
                                                        >
                                                            <EyeFill />
                                                        </Button>
                                                    )}
                                                </>
                                            )}
                                            {itAssignment.status === "REFUSED" && (
                                                <Button variant="outline-danger" size="sm" onClick={() => displayAssign(itAssignment, false)}>
                                                    <InfoLg />
                                                </Button>
                                            )}
                                            {itAssignment.status === "VALIDATED" && (
                                                <Button variant="outline-success" size="sm" onClick={() => displayAssign(itAssignment, false)}>
                                                    <InfoLg />
                                                </Button>
                                            )}
                                        </Stack>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>

                    <Modal size="lg" show={assignmentToValidate != null} onHide={() => handleCloseModal()}>
                        <Modal.Header closeButton>
                            <Modal.Title>Request assignment for {displayUserFullName(assignmentToValidate?.user)}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {assignmentToValidate && (
                                <>
                                    <Row>
                                        <Col className="mb-3" md="6" sm={12}>
                                            <label className="mb-0 fw-bolder">
                                                {t("page.administration.assignment.forms.field.assignment.area.label")}
                                            </label>
                                            <p className="d-block mt-2">
                                                {assignmentToValidate.reattachmentAreaTo?.name} ({assignmentToValidate.reattachmentAreaTo?.code})
                                            </p>
                                        </Col>

                                        <Col className="mb-3" md="6" sm={12}>
                                            <label className="mb-0 fw-bolder">
                                                {t("page.administration.assignment.forms.field.assignment.service.label")}
                                            </label>
                                            <p className="d-block mt-2">
                                                {assignmentToValidate.reattachmentServiceTo ? (
                                                    <>
                                                        {assignmentToValidate.reattachmentServiceTo.name} (
                                                        {assignmentToValidate.reattachmentServiceTo.code})
                                                    </>
                                                ) : (
                                                    <>N/A</>
                                                )}
                                            </p>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col className="mb-3" md="6" sm={12}>
                                            <label className="mb-0 fw-bolder">
                                                {t("page.administration.assignment.forms.field.assignment.manager.label")}
                                            </label>
                                            <p className="d-block mt-2">
                                                {assignmentToValidate.manager.firstName} {assignmentToValidate.manager.lastName} (
                                                {assignmentToValidate.manager.matricule})
                                            </p>
                                        </Col>

                                        <Col className="mb-3" md="6" sm={12}>
                                            <label className="mb-0 fw-bolder">
                                                {t("page.administration.assignment.forms.field.assignment.date.start.label")}
                                            </label>
                                            <p className="d-block mt-2">
                                                {format(new Date(assignmentToValidate.dateStart || ""), DEFAULT_DATE_FORMAT)}
                                            </p>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col className="mb-3" md="12" sm={12}>
                                            <label className="mb-0 fw-bolder">
                                                {t("page.administration.assignment.forms.field.assignment.comment.label")}
                                            </label>
                                            <p className="d-block mt-2">{assignmentToValidate.commentManager}</p>
                                        </Col>
                                    </Row>

                                    {assignmentToValidate.status !== "INIT" && (
                                        <>
                                            <Row>
                                                <hr />
                                            </Row>
                                            <Row>
                                                {assignmentToValidate.status === "VALIDATED" && (
                                                    <Col className="mb-3" md="6" sm={12}>
                                                        <label className="mb-0 fw-bolder">
                                                            {t("page.administration.assignment.forms.field.assignment.role.label")}
                                                        </label>
                                                        <p className="d-block mt-2">
                                                            {t(`business-domain.user.role.types.${assignmentToValidate.roles}`)}
                                                        </p>
                                                    </Col>
                                                )}

                                                {assignmentToValidate.status === "REFUSED" && (
                                                    <Col className="mb-3" md="6" sm={12}>
                                                        <label className="mb-0 fw-bolder">
                                                            {t("page.administration.assignment.forms.field.assignment.process.label")}
                                                        </label>
                                                        <p className="d-block mt-2">
                                                            {t("page.administration.assignment.forms.field.assignment.process.status.refuse.label")}
                                                        </p>
                                                    </Col>
                                                )}

                                                <Col className="mb-3" md="6" sm={12}>
                                                    <label className="mb-0 fw-bolder">
                                                        {t("page.administration.assignment.forms.field.assignment.date.end.label")}
                                                    </label>
                                                    <p className="d-block mt-2">
                                                        {format(new Date(assignmentToValidate.dateEnd || ""), DEFAULT_DATE_FORMAT)}
                                                    </p>
                                                </Col>

                                                {assignmentToValidate.status === "VALIDATED" && (
                                                    <Col className="mb-3" md="6" sm={12}>
                                                        <label className="mb-0 fw-bolder">
                                                            {t("page.administration.assignment.forms.field.assignment.process.label")}
                                                        </label>
                                                        <p className="d-block mt-2">
                                                            {t("page.administration.assignment.forms.field.assignment.process.status.validate.label")}
                                                        </p>
                                                    </Col>
                                                )}
                                            </Row>
                                        </>
                                    )}

                                    <Collapse in={hasConfirmAssignment !== null}>
                                        <>
                                            {hasConfirmAssignment === true && (
                                                <Row>
                                                    <Row>
                                                        <hr />
                                                    </Row>
                                                    <Row className="mt-2">
                                                        <Form.Group className="mb-3" as={Col} md="12" sm={12}>
                                                            <Form.Label className="mb-0 fw-bolder">
                                                                {t("page.administration.assignment.forms.field.role.label")}
                                                            </Form.Label>
                                                            <Form.Text className="text-muted mt-0 mb-1 d-block">
                                                                {t("page.administration.assignment.forms.field.role.description")}
                                                            </Form.Text>
                                                            <FormSelect className="w-100" name="role" onChange={handleRoleChange}>
                                                                {availableUserRoles.map((itRole) => (
                                                                    <option key={itRole} value={itRole}>
                                                                        {t(`business-domain.user.role.types.${itRole}`)}
                                                                    </option>
                                                                ))}
                                                            </FormSelect>
                                                        </Form.Group>
                                                    </Row>
                                                </Row>
                                            )}
                                        </>
                                    </Collapse>
                                </>
                            )}
                        </Modal.Body>

                        <Modal.Footer>
                            {assignmentToValidate && (
                                <>
                                    {assignmentToValidate.status === "INIT" && (
                                        <>
                                            {assignmentToValidate.reattachmentAreaTo.id === user?.reattachmentArea?.id &&
                                            ((assignmentToValidate.reattachmentServiceTo == null && user?.reattachmentService == null) ||
                                                (assignmentToValidate.reattachmentServiceTo != null &&
                                                    user?.reattachmentService != null &&
                                                    assignmentToValidate.reattachmentServiceTo.id === user?.reattachmentService.id)) ? (
                                                <>
                                                    {hasConfirmAssignment === null && (
                                                        <>
                                                            <Button variant="danger" onClick={() => setHasConfirmAssignment(false)}>
                                                                {t("page.administration.assignment.forms.submit.refuse-assignment")}
                                                            </Button>
                                                            <Button variant="warning" onClick={() => setHasConfirmAssignment(true)}>
                                                                {t("page.administration.assignment.forms.submit.accept-assignment")}
                                                            </Button>
                                                        </>
                                                    )}

                                                    {hasConfirmAssignment === true && (
                                                        <>
                                                            <Button variant="secondary" onClick={() => setHasConfirmAssignment(null)}>
                                                                {t("globals.actions.previous")}
                                                            </Button>
                                                            <Button
                                                                variant="warning"
                                                                disabled={!canConfirmRefuseAccept}
                                                                onClick={() => handleAcceptAssignment()}
                                                            >
                                                                {t("page.administration.assignment.forms.submit.confirm-accept-assignment")}
                                                            </Button>
                                                        </>
                                                    )}
                                                    {hasConfirmAssignment === false && (
                                                        <>
                                                            <Button variant="secondary" onClick={() => setHasConfirmAssignment(null)}>
                                                                {t("globals.actions.previous")}
                                                            </Button>
                                                            <Button
                                                                variant="danger"
                                                                disabled={!canConfirmRefuseAccept}
                                                                onClick={() => handleRefuseAssignment()}
                                                            >
                                                                {t("page.administration.assignment.forms.submit.confirm-refuse-assignment")}
                                                            </Button>
                                                        </>
                                                    )}
                                                </>
                                            ) : (
                                                <>
                                                    <Button variant="secondary" onClick={() => handleCloseModal()}>
                                                        {t("globals.actions.close")}
                                                    </Button>

                                                    {!wantToCancel && assignmentToValidate.manager.id === user?.id && (
                                                        <Button variant="danger" onClick={() => setWantToCancel(true)}>
                                                            {t("page.administration.assignment.forms.submit.cancel-assignment")}
                                                        </Button>
                                                    )}
                                                    {wantToCancel && assignmentToValidate.manager.id === user?.id && (
                                                        <Button variant="danger" onClick={() => cancelAssignment()}>
                                                            {t("page.administration.assignment.forms.submit.confirm-cancel-assignment")}
                                                        </Button>
                                                    )}
                                                </>
                                            )}
                                        </>
                                    )}

                                    {assignmentToValidate.status !== "INIT" && (
                                        <Button variant="secondary" onClick={() => handleCloseModal()}>
                                            {t("globals.actions.close")}{" "}
                                        </Button>
                                    )}
                                </>
                            )}
                        </Modal.Footer>
                    </Modal>
                </div>

                <Row>
                    <Col md={4}>
                        <Row>
                            <div className="justify-content-start">
                                <FormSelect defaultValue={pageSize} onChange={handlePageSizeChange}>
                                    {PAGE_SIZE.map((itemPage) => (
                                        <option key={itemPage} value={itemPage}>
                                            {itemPage} {t("globals.tables.rows")}
                                        </option>
                                    ))}
                                </FormSelect>
                            </div>
                        </Row>
                        <Row>
                            <div className="justify-content-start">{t("globals.tables.total-rows", { total: totalElements })}</div>
                        </Row>
                    </Col>
                    <Col>
                        <Pagination className="justify-content-end">
                            <Pagination.First disabled={page <= 1} onClick={() => handlePageChange(1)} />
                            <Pagination.Prev disabled={page <= 1} onClick={() => handlePageChange(page - 1)} />

                            {pageNumbers}

                            <Pagination.Next disabled={page >= totalPages} onClick={() => handlePageChange(page + 1)} />
                            <Pagination.Last disabled={page >= totalPages} onClick={() => handlePageChange(totalPages)} />
                        </Pagination>
                    </Col>
                </Row>
            </Row>
        </Container>
    );
};
