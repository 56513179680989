import { Alert, Button, Col, Container, Form, Row } from "react-bootstrap";
import { styled } from "styled-components";

// Icons
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import FormControl from "../../../components/@extends/react-bootstrap/FormControl";

import { Search as SearchIcon, XLg as Clear } from "react-bootstrap-icons";

import { useFormik, Form as FormikForm, FormikProvider } from "formik";
import * as Yup from "yup";
import { routes } from "../../../utils/routes";
import { isAlphaNumericWithoutAccent, isEmpty, isNonEmpty } from "../../../utils/stringUtils";
import { verifyVinOrNumberplate } from "../../../services/searchService";
import { useEffect, useState } from "react";

const SearchColDetails = styled(Col)`
    margin: auto;
`;

export default function Search() {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [errorValidateNumberplate, setErrorValidateNumberplate] = useState<boolean>(false);

    const searchSchema = Yup.object().shape({
        vin: Yup.string(),
        numberplate: Yup.string(),
    });

    const formik = useFormik({
        initialValues: {
            vin: "",
            numberplate: "",
        },
        initialTouched: {
            vin: true,
            numberplate: true,
        },
        validateOnChange: true,
        validateOnBlur: true,
        validationSchema: searchSchema,
        validate: (values) => {
            let errors = {};
            if (!(isEmpty(values.vin) && isEmpty(values.numberplate))) {
                if (isNonEmpty(values.vin) && !isAlphaNumericWithoutAccent(values.vin)) {
                    errors = {
                        ...errors,
                        vin: t("page.home.forms.errors.vin.bad.format"),
                    };
                }
                if (isNonEmpty(values.numberplate) && !isAlphaNumericWithoutAccent(values.numberplate)) {
                    errors = {
                        ...errors,
                        numberplate: t("page.home.forms.errors.numberplate.bad.format"),
                    };
                }
            }
            console.log({ errors });
            return errors;
        },

        onSubmit: (values, actions) => {
            actions.setSubmitting(false);

            verifyVinOrNumberplate(values)
                .then((response: boolean) => {
                    if (response) {
                        // Navigate to the results page with the search results
                        const formData = {
                            date: new Date(),
                            query: { ...values },
                        };
                        sessionStorage.setItem("formData", JSON.stringify(formData));
                        navigate(routes.vehicle.route);

                        actions.setSubmitting(false);
                        actions.resetForm({});
                    } else {
                        setErrorValidateNumberplate(true);
                    }
                })
                .catch((error) => {
                    setErrorValidateNumberplate(true);
                });
        },
    });

    const { errors, touched, values, isSubmitting, handleSubmit, handleChange, handleBlur, resetForm, isValid, setFieldValue } = formik;

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        // Mettre en majuscules la valeur avant de la mettre à jour dans le formulaire
        handleChange(e);
        setFieldValue(name, value.toUpperCase());
    };

    useEffect(() => {
        setErrorValidateNumberplate(false);
    }, [values]);
    return (
        <Container fluid="lg">
            <Row className="text-center mb-3">
                <SearchColDetails>
                    <h1>{t("page.home.mainTitle")}</h1>
                </SearchColDetails>
            </Row>

            <Row className="sub-body-container">
                <Alert show={errorValidateNumberplate} dismissible variant="warning" onClose={() => setErrorValidateNumberplate(false)}>
                    {isNonEmpty(values.numberplate) && isNonEmpty(values.vin) && (
                        <>
                            {t("page.home.forms.errors.vin-numberplate.no-result", {
                                vin: values.vin,
                                numberplate: values.numberplate,
                            })}
                        </>
                    )}

                    {isEmpty(values.numberplate) && isNonEmpty(values.vin) && <>{t("page.home.forms.errors.vin.no-result", { vin: values.vin })}</>}

                    {isNonEmpty(values.numberplate) && isEmpty(values.vin) && (
                        <>
                            {t("page.home.forms.errors.numberplate.no-result", {
                                numberplate: values.numberplate,
                            })}
                        </>
                    )}

                    {isEmpty(values.numberplate) && isEmpty(values.vin) && <>{t("page.home.forms.errors.vin.almost-one-required")}</>}
                </Alert>

                <FormikProvider value={formik}>
                    <FormikForm autoComplete="off" onSubmit={handleSubmit}>
                        <Row>
                            <Form.Group className="mb-3" as={Col} md="6" sm={12} controlId="validationNumberplate">
                                <Form.Label className="mb-0">{t("page.home.forms.numberplate.label")}</Form.Label>
                                <Form.Text className="text-muted mt-0 mb-1 d-block">{t("page.home.forms.numberplate.description")}</Form.Text>
                                <FormControl
                                    type="text"
                                    name="numberplate"
                                    value={values.numberplate}
                                    onChange={handleInputChange}
                                    onBlur={handleBlur}
                                    isValid={isNonEmpty(values.numberplate) && touched.numberplate && !errors.numberplate}
                                    isInvalid={touched.numberplate && !!errors.numberplate}
                                />
                                <Form.Control.Feedback type="invalid">{errors.numberplate}</Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group className="mb-3" as={Col} md="6" sm={12} controlId="validationCustom01">
                                <Form.Label className="mb-0">{t("page.home.forms.vin.label")}</Form.Label>
                                <Form.Text className="text-muted mt-0 mb-1 d-block">{t("page.home.forms.vin.description")}</Form.Text>
                                <FormControl
                                    type="text"
                                    name="vin"
                                    value={values.vin}
                                    onChange={handleInputChange}
                                    onBlur={handleBlur}
                                    isValid={isNonEmpty(values.vin) && touched.vin && !errors.vin}
                                    isInvalid={touched.vin && !!errors.vin}
                                />
                                <Form.Control.Feedback type="invalid">{errors.vin}</Form.Control.Feedback>
                            </Form.Group>
                        </Row>

                        <Row className="mt-4">
                            <Col md={{ offset: 3, span: 3 }} className="text-end mb-2">
                                <Button
                                    variant="outline-dark"
                                    className="w-100 rounded-0"
                                    onClick={() => {
                                        resetForm();
                                    }}
                                >
                                    <Clear className="m-1" />
                                    {t("page.home.forms.submit.clear.label")}
                                </Button>
                            </Col>
                            <Col md={3} className="text-end">
                                <Button
                                    type="submit"
                                    disabled={isSubmitting || !isValid || (isEmpty(values.vin) && isEmpty(values.numberplate))}
                                    variant="primary"
                                    className="w-100 rounded-0"
                                >
                                    <SearchIcon className="m-1" />
                                    {t("page.home.forms.submit.search.label")}
                                </Button>
                            </Col>
                        </Row>
                    </FormikForm>
                </FormikProvider>
            </Row>
        </Container>
    );
}
