import HeaderAppLogo2 from '../../assets/header-tsc-logo.png';
import HeaderAppLogo from '../../assets/logoTSC5.png';
import HeaderSafeLogo from '../../assets/header-safe-logo.png';

import { Link, useNavigate } from "react-router-dom";
import { styled } from "styled-components";
// components
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

//icons
import { PersonVcard, BoxArrowLeft, PersonCircle, RecordFill } from 'react-bootstrap-icons';
import Navigation from "../Navigation/Navigation";
import { useTranslation } from "react-i18next";
import { routes } from "../../utils/routes";
import { Badge, Button, ListGroup, OverlayTrigger, Popover } from "react-bootstrap";

import { useEffect, useState } from "react";
import { getCurrentUser } from "../../services/userService";
import { useUserContext } from '../../context/UserContext';
import { disconnect, isAuthenticated, setNotAuthenticated } from '../../services/authenticationService';
import { CurrentUserStorage } from '../../utils/models/user';
import { devices } from '../../utils/constants';


// ----------------------------------------------------------------------

const Logo = styled.img`
  display: block;
  max-width: 50px;
  height: auto;
  
  @media ${devices.xs} {
    max-width: 60px;
  }

  @media ${devices.md} {
    max-width: 90px;
  }
`;

const BadgeContainer = styled.div`
    position: relative;

    & span.badge {
        position: absolute;
        top: -15px;
        right: -21px;
    }
`;
const HeaderMain = styled.header`


`;

const HeaderWrapperNav = styled(Row)`
  border-top: 1px solid #ededed ;
`;

const ServiceTitle = styled(Link)`
    color: #095453;
    font-family: Sumana;
    font-size: 50px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-decoration: none;
`;

const ServiceTagline = styled.p`
    color: #095453;
    font-family: Sumana;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    font-size: 28px;
    margin-top: -10px;
    margin-left: 8px;
`;


const HeaderRowBody = styled(Row)`
  padding: 1rem 0;

  & .login-button {
    padding: .61em;
    width: 100%;
  }

  @media ${devices.sm} {
    padding: 1.5rem 0;
  }
`;

const HeaderColService = styled(Col)`
  margin: auto;
  margin-left: -25px !important;
  
  @media ${devices.sm} {
    margin-left: 10px !important;
  }

  @media ${devices.xl} {
    margin-left: -10px !important;
  }
`;

const ListGroupItem = styled(ListGroup.Item)`
    border-left: none;
    border-right: none;
    border-top: none;
    cursor: pointer;

    &:last-child {
        border-bottom: none;
    }

    &:hover {
        background-color: #ededed;
    }

`;
// ----------------------------------------------------------------------

function Header() {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const { userContextLogin, userContextLogout } = useUserContext();

    const logout = () => {
        disconnect()
            .then()
            .catch()
            .finally(() => {
                userContextLogout();
                setNotAuthenticated();
                navigate(routes.logIn.route);
            })
    };
    const [user, setUser] = useState<CurrentUserStorage | null>(null);

    useEffect(() => {
        const isAuth = isAuthenticated();
        if (isAuth && user == null) {
            getCurrentUser("agent")
                .then((response: any) => {
                    userContextLogin(response)
                    setUser(response);
                }).catch(() => {
                    setUser(null);
                    userContextLogout();
                    setNotAuthenticated();
                    navigate(routes.logIn.route, { replace: true });
                });
        } else if (!isAuth) {
            navigate(routes.logIn.route);
        }
    }, [user, navigate, userContextLogin, userContextLogout]); //notice the empty array here

    const popoverBottom = (
        <Popover id="popover-positioned-bottom">
            <Popover.Body className="p-0">
                <ListGroup>
                    <ListGroupItem className="bt-0 bx-0" onClick={() => navigate(routes.userAccount.route)}>
                        <PersonVcard className="me-1" />
                        {t("navigation.user.account.label")}
                        {user && user.notification > 0 && (
                            <Badge className='ms-1'>{user.notification}</Badge>
                        )}
                    </ListGroupItem>

                    <ListGroupItem className="bt-0 bx-0" onClick={() => logout()}>
                        <BoxArrowLeft className="me-1" />{t("navigation.user.logout.label")}
                    </ListGroupItem>

                </ListGroup>
            </Popover.Body>

        </Popover>
    );

    return (
        <HeaderMain id="header" role="banner" className="tsc-header">
            <div className="tsc-header__body container-lg">
                <div>
                    <HeaderRowBody className="tsc-header__body-row">

                        <Col md={9} className="tsc-header__brand tsc-enlarge-link background-default-white">

                            <Row>
                                <Col md={2} sm={2} xs={2} className="tsc-header__brand-top d-none">
                                    <div className="tsc-header__operator">
                                        <Link to="/">
                                            <Logo
                                                className="tsc-responsive-img tsc-image-header"
                                                alt="TSC"
                                                src={HeaderSafeLogo}
                                            />
                                        </Link>
                                    </div>
                                </Col>


                                <Col md={{ offset: 0, span: 2 }}
                                    sm={{ offset: 0, span: 3 }}
                                    xs={{ offset: 0, span: 2 }}
                                    className="tsc-header__brand-top">
                                    <div className="tsc-header__operator">
                                        <Link to="/">
                                            <Logo
                                                className="tsc-responsive-img tsc-image-header m-0 ms-2 ps-2"
                                                alt="TSC"
                                                src={HeaderAppLogo}
                                            />
                                        </Link>
                                    </div>
                                </Col>

                                <Col className="tsc-header__brand-top d-block d-md-none"
                                    md={{ span: 3 }}
                                    sm={{ offset: 5, span: 4 }}
                                    xs={{ offset: 4, span: 6 }} >
                                    <Row>
                                        <Navigation navClassName='sm-navigation' />
                                    </Row>
                                </Col>

                                <HeaderColService md={7} xs={12} sm={7} className="tsc-header__service tsc-header__service-desktop align-middle text-sm-start text-center d-none d-md-block ms-0 ps-0">
                                    <ServiceTitle to="/">{t("app.title")}</ServiceTitle>
                                    <ServiceTagline className="tsc-header__service-tagline">
                                        {t("app.tagline")}
                                    </ServiceTagline>
                                </HeaderColService>
                            </Row>

                        </Col>

                        <Col md={3} className="align-middle my-auto d-none d-md-block d-xl-block">

                            <Row className="">

                                {(user != null) && (
                                    <OverlayTrigger trigger={['click']} placement="bottom" overlay={popoverBottom} rootClose>
                                        <Button variant="outline-light" className="login-button border-0 text-dark">
                                            <Row>
                                                <Col xs={2} md={2} className='me-3 my-auto'>
                                                    {user.notification > 0 && (
                                                        <BadgeContainer>
                                                            <PersonCircle className='fs-2' />
                                                            <Badge>{user.notification}</Badge>
                                                        </BadgeContainer>
                                                    )}

                                                    {user.notification <= 0 && (
                                                        <PersonCircle className='fs-2' />
                                                    )}

                                                </Col>
                                                <Col xs={8} md={8} className='ms-2'>
                                                    <Row>{user.firstName} {user.lastName}</Row>
                                                    <Row className="fst-italic" style={{ fontSize: "0.8rem" }}>
                                                        {user.reattachmentService && (
                                                            <>{user.reattachmentService.name}</>
                                                        )}
                                                        {user.reattachmentService && user.reattachmentArea && (
                                                            <>&nbsp;-&nbsp;</>
                                                        )}
                                                        {user.reattachmentArea && (
                                                            <>{user.reattachmentArea.name}</>
                                                        )}
                                                    </Row>
                                                    <Row className="fst-italic" style={{ fontSize: "0.75rem" }}>{t(`business-domain.user.role.types.${user.roles[0]}`)}</Row>
                                                </Col>
                                            </Row>
                                        </Button>
                                    </OverlayTrigger>
                                )}
                            </Row>
                        </Col>

                    </HeaderRowBody>
                </div>

            </div>

            <Row className="tsc-header__nav  d-block d-md-none ">

                <HeaderWrapperNav className='border-bottom py-2'>
                    <Col className="tsc-header__service align-middle text-sm-start text-center ms-0 ps-0">
                        <ServiceTagline className="tsc-header__service-tagline m-0 ms-2 ps-2">
                            {t("app.tagline")}
                        </ServiceTagline>
                    </Col>
                </HeaderWrapperNav>
            </Row>

            <Row className="tsc-header__nav d-none d-md-block md-size">
                <HeaderWrapperNav>
                    <Navigation />
                </HeaderWrapperNav>

            </Row>


        </HeaderMain>
    )


}


export default Header;