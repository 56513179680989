
export const MAIN_ROUTE = "/";

export const HOME_ROUTE = MAIN_ROUTE;
export const LOGIN_ROUTE = MAIN_ROUTE + "log-in";
export const RESET_PASSWORD_ROUTE = MAIN_ROUTE + "reset-pass";


export const SEARCH_ROUTE = MAIN_ROUTE + "vehicle-search";
export const VEHICLE_RESULT_ROUTE = MAIN_ROUTE + "vehicle-result";

export const USER_ACCOUNT_ROUTE = MAIN_ROUTE + "user-account";
export const USER_DASHBOARD_ROUTE = MAIN_ROUTE + "user-dashboard";


export const OFFENSE_ROUTE = MAIN_ROUTE + "offense";
export const ADMINISTRATION_ROUTE = MAIN_ROUTE + "administration";
export const ADMINISTRATION_USERS_ROUTE = ADMINISTRATION_ROUTE + "/users";
export const ADMINISTRATION_USER_PROFILE_VISUALIZE_ROUTE = ADMINISTRATION_ROUTE + "/users/profile/:id";
export const ADMINISTRATION_USER_PROFILE_EDITION_ROUTE = ADMINISTRATION_ROUTE + "/users/edition/:id";
export const ADMINISTRATION_USER_CREATION_ROUTE = ADMINISTRATION_ROUTE + "/users/create";
export const ADMINISTRATION_ASSIGNMENT_ROUTE = ADMINISTRATION_ROUTE + "/assignment";

export const ADMINISTRATION_OFFENSE_TYPES_ROUTE = ADMINISTRATION_ROUTE + "/offenses-types";
export const ADMINISTRATION_TRACE_ROUTE = ADMINISTRATION_ROUTE + "/traces";
export const ADMINISTRATION_STATS_ROUTE = ADMINISTRATION_ROUTE + "/stats";
export const ADMINISTRATION_STATS_LINE_CHART_DETAILS_ROUTE = ADMINISTRATION_ROUTE + "/stats/:id";

export const DASHBOARD_ROUTE = MAIN_ROUTE + "dashboard";
export const DASHBOARD_LIST_VEHICLE_ROUTE = DASHBOARD_ROUTE + "/vehicles";



export const FAQ_ROUTE = MAIN_ROUTE + "faq";
export const LEGAL_NOTICE_ROUTE = MAIN_ROUTE + "legal-notice";
export const CONTACT_ROUTE = MAIN_ROUTE + "contact";
export const NOT_FOUND_ROUTE = MAIN_ROUTE + "notfound";

export const SECURE_ROUTE = "/secure/";
export const AGENT_ROUTE = SECURE_ROUTE + "#";



export const routes = {
    main: {
        route: MAIN_ROUTE,
        label: "route.main.label"
    },
    home: {
        route: HOME_ROUTE,
        label: "route.home.label"
    },

    logIn: {
        route: LOGIN_ROUTE,
        label: "route.logIn.label"
    },
    resetPassword: {
        route: RESET_PASSWORD_ROUTE,
        label: "route.resetPassword.label"
    },
    vehicleSearch: {
        route: SEARCH_ROUTE,
        label: "route.vehicleSearch.label"
    },
    vehicle: {
        route: VEHICLE_RESULT_ROUTE,
        label: "route.vehicle.label"
    },
    offense: {
        route: OFFENSE_ROUTE,
        label: "route.offense.label"
    },
    userAccount: {
        route: USER_ACCOUNT_ROUTE,
        label: "route.user.account.label"
    },
    administration: {
        route: ADMINISTRATION_ROUTE,
        label: "route.administration.label",
    },
    administrationUsers: {
        route: ADMINISTRATION_USERS_ROUTE,
        label: "route.administration.users.label"
    },

    administrationUserProfileVisualize: {
        route: ADMINISTRATION_USER_PROFILE_VISUALIZE_ROUTE,
        label: "route.administration.user.profile.visualize.label"
    },
    administrationUserProfileEdition: {
        route: ADMINISTRATION_USER_PROFILE_EDITION_ROUTE,
        label: "route.administration.user.profile.edition.label"
    },
    administrationUserCreation: {
        route: ADMINISTRATION_USER_CREATION_ROUTE,
        label: "route.administration.user.creation.label"
    },

    administrationAssignment: {
        route: ADMINISTRATION_ASSIGNMENT_ROUTE,
        label: "route.administration.assignment.label"
    },

    administrationOffenseTypes: {
        route: ADMINISTRATION_OFFENSE_TYPES_ROUTE,
        label: "route.administration.offense.types.label"
    },

    administrationTraces: {
        route: ADMINISTRATION_TRACE_ROUTE,
        label: "route.administration.trace.label"
    },

    administrationStats: {
        route: ADMINISTRATION_STATS_ROUTE,
        label: "route.administration.stats.label"
    },

    administrationStatsLineChartDetails: {
        route: ADMINISTRATION_STATS_LINE_CHART_DETAILS_ROUTE,
        label: "route.administration.stats.linechart.details.label"
    },

    dashboardListVehicle: {
        route: DASHBOARD_LIST_VEHICLE_ROUTE,
        label: "route.dashboard.vehicles.list.label"
    },

    agent: {
        route: AGENT_ROUTE,
        label: "route.agent.label"
    },
    faq: {
        route: FAQ_ROUTE,
        label: "route.faq.label"
    },
    legalNotice: {
        route: LEGAL_NOTICE_ROUTE,
        label: "route.legalNotice.label"
    },
    contact: {
        route: CONTACT_ROUTE,
        label: "route.contact.label"
    },
    notfound: {
        route: NOT_FOUND_ROUTE,
        label: "route.notfound.label"
    }
}

export const routesArray = Object.keys(routes).map((key) => ({
    key: key,
    ...routes[key as keyof typeof routes]
}));