import { Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import styled from "styled-components";
import { PeopleFill, PersonFill } from "react-bootstrap-icons";
import { AddUserForm } from "./AddUserForm";
import { BulkImportUserForm } from "./BulkImportUserForm";

const TabsContainer = styled(Tabs)`
    & li:nth-child(1) .nav-link {
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
        
    }

    & li:nth-child(2) .nav-link{
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
    }

    & .nav-link:not(.active){
        color: #2b2f328c;
        background-color: #e2e3e5;
        
    }
`;
const CreateUser = () => {

    const { t } = useTranslation();


    return (
        <Container fluid="lg">

            <Row className="text-center mb-3">
                <h1>{t("page.administration.user-create.mainTitle")}</h1>
            </Row>

            <Row className="sub-body-container">

                <TabsContainer
                    defaultActiveKey="create"
                    className="mb-3"
                    justify fill
                    variant="pills"
                    style={{paddingLeft: "calc(var(--bs-gutter-x) * .5)"}}
                >
                    <Tab eventKey="create" title={<><PersonFill /> {t("page.administration.user-create.forms.tabs.single.title")} </>}>
                        <AddUserForm />
                    </Tab>
                    <Tab eventKey="bulk-create" title={<><PeopleFill /> {t("page.administration.user-create.forms.tabs.bulk.title")} </>}>
                        <BulkImportUserForm />
                    </Tab>

                </TabsContainer>

            </Row>
        </Container>
    );
}

export default CreateUser;