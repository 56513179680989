import React, { useState } from "react";
import * as Yup from "yup";

import { Container, Form, Button, Row, Alert, FloatingLabel, Col } from "react-bootstrap";
import FormControl from "../../components/@extends/react-bootstrap/FormControl";
import { FormikProvider, useFormik, Form as FormikForm } from "formik";
import { connect, setIsAuthenticated } from "../../services/authenticationService";
import { Link, useNavigate } from "react-router-dom";
import { routes } from "../../utils/routes";
import { JwtAuthenticationResponse } from "../../utils/models/user";
import { ROLES } from "../../utils/constants";
import { useTranslation } from "react-i18next";

const LogIn: React.FC = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [loginSuccess, setLogginSuccess] = useState<boolean>(true);

    const LoginSchema = Yup.object().shape({
        username: Yup.string().required("Email or registration ID is required"),
        password: Yup.string().required("Password is required"),
    });

    const formik = useFormik({
        initialValues: {
            username: "",
            password: "",
        },
        validationSchema: LoginSchema,

        onSubmit: (values, actions) => {
            //Here we submitted
            const loginRequest = Object.assign({}, values);
            setLogginSuccess(true);
            connect(loginRequest)
                .then((response: JwtAuthenticationResponse) => {
                    setIsAuthenticated(response.accessToken);
                    if (response.role === ROLES.ROLE_AREA_MANAGER || response.role === ROLES.ROLE_SERVICE_ADMIN) {
                        navigate(routes.administration.route);
                    } else {
                        navigate(routes.home.route);
                    }
                })
                .catch(() => {
                    actions.setSubmitting(false);
                    setLogginSuccess(false);
                });
        },
    });

    const { handleSubmit, getFieldProps } = formik;

    return (
        <Container className="mt-5 mt-sm-0">
            {!loginSuccess && (
                <Row>
                    <Alert variant="danger">{t("page.login.forms.errors.log-failed")}</Alert>
                </Row>
            )}
            <FormikProvider value={formik}>
                <FormikForm onSubmit={handleSubmit}>
                    <Row>
                        <Form.Group controlId="username" className="mb-4">
                            <FloatingLabel label={t("page.login.forms.field.username.label")}>
                                <FormControl type="text" placeholder={t("page.login.forms.field.username.label")} {...getFieldProps("username")} />
                            </FloatingLabel>
                            <Form.Text>{t("page.login.forms.field.username.description")}</Form.Text>
                        </Form.Group>

                        <Form.Group controlId="password" className="mb-4">
                            <FloatingLabel label={t("page.login.forms.field.password.label")}>
                                <FormControl
                                    type="password"
                                    placeholder={t("page.login.forms.field.password.label")}
                                    {...getFieldProps("password")}
                                />
                            </FloatingLabel>
                            <Form.Text>{t("page.login.forms.field.password.description")}</Form.Text>
                        </Form.Group>

                        <div className="d-flex justify-content-between align-items-center">
                            <div className="form-check mb-0"></div>

                            <Link to={routes.resetPassword.route} className="text-body">
                                {t("page.login.forms.field.forgot-password")}
                            </Link>
                        </div>
                    </Row>

                    <Row className="m-auto">
                        <Col xs={{ offset: 2, span: 8 }} sm={{ offset: 3, span: 6 }} className="text-center">
                            <Button className="mt-4 w-100" variant="primary" type="submit">
                                {t("page.login.forms.submit.login.label")}
                            </Button>
                        </Col>
                    </Row>
                </FormikForm>
            </FormikProvider>
        </Container>
    );
};

export default LogIn;
