export function generateSoftColorsPalette() {
    const softColorsPalette = [];
    const softBorderColorsPalette = [];
  
    for (let i = 0; i < 100; i++) {
      const hue = (i * 137.508) % 360; // Utilisez un angle constant pour obtenir des couleurs équilibrées
      const saturation = 80; // Vous pouvez ajuster la saturation selon vos préférences
      const lightness = 60; // Vous pouvez ajuster la luminosité selon vos préférences
      const alpha = 0.6; // Vous pouvez ajuster l'opacité selon vos préférences
  
      // Convertir HSL en RGB et ajouter à la palette
      const color = `rgba(${hslToRgb(hue, saturation, lightness).join(',')}, ${alpha})`;
      const borderColor = `rgba(${hslToRgb(hue, saturation, lightness).join(',')}, 1)`;
      softColorsPalette.push(color);
      softBorderColorsPalette.push(borderColor);
    }
  
    return [softColorsPalette, softBorderColorsPalette];
  }
  
  // Fonction utilitaire pour convertir HSL en RGB
  function hslToRgb(h: number, s: number, l: number) {
    h /= 360;
    s /= 100;
    l /= 100;
    let r, g, b;
  
    if (s === 0) {
      r = g = b = l; // Achromatic
    } else {
      const hue2rgb = (p: number, q: number, t: number) => {
        if (t < 0) t += 1;
        if (t > 1) t -= 1;
        if (t < 1 / 6) return p + (q - p) * 6 * t;
        if (t < 1 / 2) return q;
        if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
        return p;
      };
  
      const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
      const p = 2 * l - q;
  
      r = hue2rgb(p, q, h + 1 / 3);
      g = hue2rgb(p, q, h);
      b = hue2rgb(p, q, h - 1 / 3);
    }
  
    return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
  }
  
 
  