import { API_BASE_URL } from '../utils/constants';
import { Owner } from '../utils/models/base';
import request from "./apiUtils";

export function buildReport(uuid: string) {

    return request({
        url: API_BASE_URL + "/report/generate-pdf",
        method: 'POST',
        body: uuid
    });
}


export const generateOwnerFullName = (owner: Owner) => {

    let fullname;
    switch (owner.type) {
        case "INDIVIDUAL":
            fullname = (owner.lastName + " " + owner.firstName);
            break;
        case "COMPANY":
        case "LEGALPERSON":
            fullname = (owner.companyName
                + (owner.companyNumer ? " (" + owner.companyNumer + ")" : ""));
            break;

        default:
            fullname = ("UNKNOW");
            break;
    }

    return fullname;
}
