import { useRef, useState } from "react";
import { Card, Dropdown, ListGroup, OverlayTrigger, Popover } from "react-bootstrap";
import { BarChartSteps, CardImage, FiletypeCsv, List, Subtract } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import html2canvas from 'html2canvas';
import { saveAs } from 'file-saver';
import { convertToCamelCase } from "../../utils/stringUtils";
import { routes } from "../../utils/routes";
import { useNavigate } from "react-router-dom";

const ListGroupItem = styled(ListGroup.Item)`
    border-left: none;
    border-right: none;
    border-top: none;
    cursor: pointer;

    &:last-child {
        border-bottom: none;
    }

    &:hover {
        background-color: #ededed;
    }

`;

interface ChartProps {
    id: string;
    title: string;
    description: string;
    children: React.ReactNode;
    hasExport?: boolean;
    hasExportCsv?: boolean;
    hasDetails?: boolean;
    handleExportCsv?: () => void;
}
const Chart = ({ id, title, description, children, hasExport = true, hasDetails = true, hasExportCsv = false, handleExportCsv }: ChartProps) => {

    const { t } = useTranslation();
    const navigate = useNavigate();

    const chartRef = useRef<HTMLDivElement | null>(null);

    const downloadChartImage = () => {
        if (chartRef.current) {
            html2canvas(chartRef.current).then((canvas) => {
                // Convert canvas to a blob
                canvas.toBlob((blob: any) => {
                    // Save blob as a file using FileSaver
                    const filename = convertToCamelCase(title) || 'chart';
                    saveAs(blob, `${filename}.png`);
                });
            });
        }
    };


    const displayChartDetails = () => {
        const chartDetailsRoute = routes.administrationStatsLineChartDetails.route.replace(":id", id);
        navigate(chartDetailsRoute);
    }

    const downloadTableCsv = () => {
        if (handleExportCsv) {
            handleExportCsv();
        }
    }

    const popoverBottom = (
        <Popover>
            <Popover.Body className="p-0">
                <ListGroup>
                    {hasExportCsv && handleExportCsv && (
                        <ListGroupItem className="bt-0 bx-0" onClick={() => downloadTableCsv()}>
                            <FiletypeCsv className="me-1" />
                            {t("page.administration.stats.chart.popover.exportCsv.label")}
                        </ListGroupItem>
                    )}

                    {hasExport && (
                        <ListGroupItem className="bt-0 bx-0" onClick={() => downloadChartImage()}>
                            <CardImage className="me-1" />
                            {t("page.administration.stats.chart.popover.export.label")}
                        </ListGroupItem>
                    )}

                    {hasDetails && (
                        <ListGroupItem className="bt-0 bx-0" onClick={() => displayChartDetails()}>
                            <Subtract className="me-1" />
                            {t("page.administration.stats.chart.popover.details.label")}
                        </ListGroupItem>
                    )}

                </ListGroup>
            </Popover.Body>
        </Popover>
    );



    return (

        <Card className="border-0" ref={chartRef}>
            <Card.Body>
                <Card.Title>
                    {title}
                    {(hasDetails || hasExport) && (
                        <OverlayTrigger trigger={['click']} placement="bottom" overlay={popoverBottom} rootClose>
                            <List size={22} style={{ cursor: "pointer" }} className="float-end" />
                        </OverlayTrigger>
                    )}

                </Card.Title>
                <Card.Subtitle className="text-muted pb-3">
                    {description}
                </Card.Subtitle>

                {children}
            </Card.Body>
        </Card >

    );

}


export default Chart;