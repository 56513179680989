import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from "chart.js";
import { Line } from "react-chartjs-2";
import { StatItem } from "../../utils/models/stats";
import { useTranslation } from "react-i18next";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

interface LineChartProps {
    vehicleLineData: StatItem;
    offenseLineData: StatItem;
    paidOffenseLineData: StatItem;
    cumulative?: boolean;
}
export function LineChart({ offenseLineData, vehicleLineData, paidOffenseLineData, cumulative }: LineChartProps) {
    const { t } = useTranslation();
    const options = {
        responsive: true,
        interaction: {
            mode: "index" as const,
            intersect: false,
        },
        stacked: false,
        plugins: {
            legend: {
                position: "bottom" as const,
            },
            title: {
                display: false,
            },
        },
        scales: {
            y: {
                type: "linear" as const,
                display: true,
                position: "left" as const,
                title: {
                    display: true,
                    text: t("page.administration.stats.chart.graph.vehicle.title"),
                },
            },
            y1: {
                type: "linear" as const,
                display: true,
                position: "right" as const,
                title: {
                    display: true,
                    text: t("page.administration.stats.chart.graph.offenses.title"),
                },
                grid: {
                    drawOnChartArea: false,
                },
            },
        },
    };

    //const labels = Object.keys(vehicleLineData.data);
    const labels = vehicleLineData.labels;

    const getData = (data: StatItem) => {
        if (cumulative) {
            const values = Object.values(data.data);

            const cumulativeValues: number[] = [];
            let cumulativeSum = data.sumBefore;

            values.forEach((value) => {
                cumulativeSum += value;
                cumulativeValues.push(cumulativeSum);
            });

            return cumulativeValues;
        } else {
            return Object.values(data.data);
        }
    };

    const dataValues = {
        labels,
        datasets: [
            {
                label: t("page.administration.stats.chart.graph.vehicle.title"),
                data: getData(vehicleLineData),
                borderColor: "rgb(32, 102, 163)",
                backgroundColor: "rgba(240, 248, 255, 0.5)",
                yAxisID: "y",
            },
            {
                label: t("page.administration.stats.chart.graph.all-offenses.title"),
                data: getData(offenseLineData), //labels.map(() => getRandomNumber(0, 500)),
                borderColor: "rgb(163, 32, 32)",
                backgroundColor: "rgb(255, 240, 240, 0.5)",
                yAxisID: "y1",
            },
            {
                label: t("page.administration.stats.chart.graph.paid-offenses.title"),
                data: getData(paidOffenseLineData), //labels.map(() => getRandomNumber(0, 500)),
                borderColor: "rgb(58, 163, 32)",
                backgroundColor: "rgb(240, 255, 240, 0.5)",
                yAxisID: "y1",
            },
        ],
    };

    return <Line options={options} data={dataValues} />;
}
