import { Badge, Col, Collapse, Container, Nav, Navbar, Offcanvas, Row, Stack } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { styled } from "styled-components";
import { devices } from "../../utils/constants";
import { BoxArrowLeft, ChevronDown, ChevronUp, Envelope, List, PersonCircle, PersonVcard, QuestionCircle, Search } from "react-bootstrap-icons";
import { routes } from "../../utils/routes";
import { useUserContext } from "../../context/UserContext";
import { disconnect, isAdmin, setNotAuthenticated } from "../../services/authenticationService";
import { SyntheticEvent, useState } from "react";
import QuickSearch from "../search/QuickSearch";
import { v4 as uuid } from "uuid";

const NavbarParent = styled(Navbar)`
    padding: 0;
    & .nav-link {
        padding: 0.5rem 1.5rem !important;

        @media ${devices.sm} {
            padding: 1rem 1.5rem !important;
        }

        &.active {
            @media ${devices.sm} {
                border-bottom: 2px solid #095453;
                color: initial;
                font-weight: normal;
            }
            color: #095453;
            font-weight: bold;
        }

        &:hover {
            background-color: rgba(237, 237, 237, 0.5);
        }
    }
`;

const BadgeContainer = styled.div`
    position: relative;

    & span.badge {
        position: absolute;
        top: -10px;
        right: -18px;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
    }
`;

const HeaderActionLink = styled(Nav.Link)`
    color: #096665;
`;

const HeaderUserActionLink = styled(Nav.Link)`
    color: #343a40;
`;

interface NavigationProps {
    navClassName?: string | undefined;
}
const Navigation = ({ navClassName }: NavigationProps) => {
    const offcanvasId = uuid();
    const offcanvasTitleId = uuid();

    const [expanded, setExpanded] = useState(false);
    const [expandedUserDetails, setExpandedUserDetails] = useState(false);

    const [showSearch, setShowSearch] = useState(false);

    const handleCloseSearch = () => setShowSearch(false);
    const handleShowSearch = () => setShowSearch(true);

    const { user, userContextLogout } = useUserContext();
    const location = useLocation();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const logout = () => {
        disconnect()
            .then()
            .catch()
            .finally(() => {
                userContextLogout();
                setNotAuthenticated();
                navigate(routes.logIn.route);
            });
    };

    function onSelectNav(eventKey: string | null, e: SyntheticEvent<unknown, Event>): void {
        console.log("Function not implemented.");
    }

    return (
        <div>
            <NavbarParent expand={"md"} collapseOnSelect role="navigation" expanded={expanded} onToggle={() => setExpanded(!expanded)}>
                <Container fluid="lg" className="justify-content-end">
                    <Search size={25} role="button" onClick={handleShowSearch} className="mx-2 d-block d-md-none" />

                    <Navbar.Toggle aria-controls={offcanvasId} className="border-0">
                        {user && user.notification > 0 ? (
                            <BadgeContainer>
                                <List size={32} />
                                <Badge>{user.notification}</Badge>
                            </BadgeContainer>
                        ) : (
                            <List size={32} />
                        )}
                    </Navbar.Toggle>
                    <Navbar.Offcanvas id={offcanvasId} aria-labelledby={offcanvasTitleId} placement="end">
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title id={offcanvasTitleId}></Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body className=" pt-0 ">
                            <Nav className={`me-auto ${navClassName ? navClassName : ""}`} activeKey={location.pathname} onSelect={onSelectNav}>
                                <div className="nav-link d-block d-md-none d-xl-none">
                                    {user && (
                                        <>
                                            <Stack direction="horizontal" onClick={() => setExpandedUserDetails(!expandedUserDetails)}>
                                                <div className="me-3 my-auto">
                                                    {user.notification > 0 && (
                                                        <BadgeContainer>
                                                            <PersonCircle className="fs-1" />
                                                            <Badge>{user.notification}</Badge>
                                                        </BadgeContainer>
                                                    )}

                                                    {user.notification <= 0 && <PersonCircle className="fs-1" />}
                                                </div>
                                                <div className="ms-2">
                                                    <Row>
                                                        {user.firstName} {user.lastName}
                                                    </Row>
                                                    <Row className="fst-italic" style={{ fontSize: "0.8rem" }}>
                                                        {user.reattachmentService && <>{user.reattachmentService.name}</>}
                                                        {user.reattachmentArea && <>{user.reattachmentArea.name}</>}
                                                    </Row>
                                                    <Row className="fst-italic" style={{ fontSize: "0.75rem" }}>
                                                        {t(`business-domain.user.role.types.${user.roles[0]}`)}
                                                    </Row>
                                                </div>

                                                <div className="ms-auto">
                                                    {expandedUserDetails && <ChevronUp role="button" onClick={() => setExpandedUserDetails(false)} />}

                                                    {!expandedUserDetails && (
                                                        <ChevronDown role="button" onClick={() => setExpandedUserDetails(true)} />
                                                    )}
                                                </div>
                                            </Stack>

                                            <Collapse in={expandedUserDetails}>
                                                <Row className="px-3">
                                                    <Col>
                                                        <Navbar.Text>
                                                            <hr className="m-0" />
                                                        </Navbar.Text>

                                                        <HeaderUserActionLink
                                                            to={routes.userAccount.route}
                                                            as={NavLink}
                                                            onClick={() => setExpanded(false)}
                                                            className="py-2 d-block HeaderSmallActionLink"
                                                        >
                                                            <PersonVcard className="m-1 me-2" />
                                                            {t("navigation.user.account.label")}
                                                            {user && user.notification > 0 && <Badge className="ms-1">{user.notification}</Badge>}
                                                        </HeaderUserActionLink>

                                                        <HeaderUserActionLink
                                                            onClick={() => logout()}
                                                            as={NavLink}
                                                            className="pt-2 d-block HeaderSmallActionLink"
                                                        >
                                                            <BoxArrowLeft className="m-1 me-2" />
                                                            {t("navigation.user.logout.label")}
                                                        </HeaderUserActionLink>
                                                    </Col>
                                                </Row>
                                            </Collapse>
                                        </>
                                    )}
                                </div>

                                <Nav.Link to={routes.home.route} as={NavLink} onClick={() => setExpanded(false)}>
                                    {t("navigation.home.label")}
                                </Nav.Link>
                                <Nav.Link to={routes.offense.route} as={NavLink} onClick={() => setExpanded(false)}>
                                    {t("navigation.offense.label")}
                                </Nav.Link>
                                {isAdmin(user) && (
                                    <Nav.Link to={routes.administration.route} as={NavLink} onClick={() => setExpanded(false)}>
                                        {t("navigation.administration.label")}
                                    </Nav.Link>
                                )}

                                <div className="d-block d-md-none d-xl-none">
                                    <Navbar.Text>
                                        <hr className="m-0" />
                                    </Navbar.Text>

                                    <HeaderActionLink to={routes.faq.route} as={Link} className="py-2 d-block d-none HeaderActionLink">
                                        <QuestionCircle className="m-1" />
                                        {t("header.need-help")}
                                    </HeaderActionLink>
                                    <HeaderActionLink to={routes.contact.route} as={Link} className="py-2 d-block d-none HeaderActionLink">
                                        <Envelope className="m-1" />
                                        {t("header.contact-us")}
                                    </HeaderActionLink>
                                </div>
                            </Nav>
                        </Offcanvas.Body>
                    </Navbar.Offcanvas>
                </Container>
            </NavbarParent>

            <Offcanvas show={showSearch} onHide={handleCloseSearch} placement="top">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Quick search</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <QuickSearch callback={handleCloseSearch} />
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    );
};

export default Navigation;
