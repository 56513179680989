import { Container, Row, Breadcrumb as BreadcrumbBase } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { styled } from "styled-components";
import { HOME_ROUTE, routes } from "../../utils/routes";
import { useTranslation } from "react-i18next";

const BreadcrumbBaseItem = styled.li`
    &,
    & > a {
        color: #444040;
        font-size: 0.85rem;
    }
`;

interface BreadcrumbItem {
    label: string;
    to: string;
}

const Breadcrumb = () => {
    const location = useLocation();
    const { t } = useTranslation();
    const pathnameSegments = location.pathname.split("/").filter((segment) => segment !== "");
    let label = "navigation.";

    const breadcrumbItems: BreadcrumbItem[] = pathnameSegments.map((segment, index) => {
        const to = `/${pathnameSegments.slice(0, index + 1).join("/")}`;
        label += segment + ".";
        return {
            label: t(label + "label"),
            to: to,
        };
    });

    const isProfilePage = () => {
        const profilePathnameSegments = routes.administrationUserProfileVisualize.route.split("/").filter((segment) => segment !== "");
        const pathnameSegments = location.pathname.split("/").filter((segment) => segment !== "");

        if (pathnameSegments.length === profilePathnameSegments.length && profilePathnameSegments.length === 4) {
            pathnameSegments.pop();
            profilePathnameSegments.pop();
            return pathnameSegments.join(".") == profilePathnameSegments.join(".");
        }
        return false;
    };

    const isOopsPage = () => {
        const oopsPathnameSegments = routes.notfound.route.split("/").filter((segment) => segment !== "");
        const pathnameSegments = location.pathname.split("/").filter((segment) => segment !== "");

        if (pathnameSegments.length === oopsPathnameSegments.length && oopsPathnameSegments.length === 1) {
            return pathnameSegments.join(".") == oopsPathnameSegments.join(".");
        }
        return false;
    };

    const isEditionPage = () => {
        const editionPathnameSegments = routes.administrationUserProfileEdition.route.split("/").filter((segment) => segment !== "");
        const pathnameSegments = location.pathname.split("/").filter((segment) => segment !== "");

        if (pathnameSegments.length === editionPathnameSegments.length && editionPathnameSegments.length === 4) {
            pathnameSegments.pop();
            editionPathnameSegments.pop();
            return pathnameSegments.join(".") == editionPathnameSegments.join(".");
        }
        return false;
    };

    if (isProfilePage()) {
        breadcrumbItems.pop();
    } else if (isEditionPage()) {
        breadcrumbItems.pop();
    } else if (isOopsPage()) {
        return <br />;
    }

    const homeRoute = HOME_ROUTE === location.pathname ? "#" : "/";

    return (
        <Container fluid="lg">
            <Row>
                <BreadcrumbBase className="my-2">
                    <BreadcrumbBaseItem className="breadcrumb-item">
                        <Link to={homeRoute}>{t("navigation.home.label")}</Link>
                    </BreadcrumbBaseItem>
                    {breadcrumbItems.map((item, index) => (
                        <BreadcrumbBaseItem key={index} className={`breadcrumb-item ${index + 1 === breadcrumbItems.length ? "active" : ""}`}>
                            {index + 1 === breadcrumbItems.length ? <>{item.label}</> : <Link to={item.to}>{item.label}</Link>}
                        </BreadcrumbBaseItem>
                    ))}
                </BreadcrumbBase>
            </Row>
        </Container>
    );
};

export default Breadcrumb;
