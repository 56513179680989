import { Col, Container, Nav, Row } from "react-bootstrap";
import { styled } from "styled-components";

import FooterAppLogo from '../../assets/logoTSC5.png';
import { useTranslation } from "react-i18next";
import { routes } from "../../utils/routes";
import { Link, NavLink as NavLinkRouter } from "react-router-dom";


const Logo = styled.img`
  display: block;
  max-width: 100%;
  height: auto;
  max-height: 75px;
`;

const RootStyle = styled.div({
    borderTop: '1px solid #095453'
});

const NavLink = styled(Nav.Link)({
    color: '#444040',
    fontSize: '0.85rem',
    textDecorationLine: 'none'

});

const Footer = () => {

    const { t } = useTranslation();

    const footerMenus = [
        {
            title: 'Sitemap',
            link: '/sitemap'
        },
        {
            title: 'Legal Notices',
            link: routes.legalNotice.route
        },
        {
            title: 'Personal Data',
            link: '/personal-data'
        },
        {
            title: 'Terms of Service',
            link: '/terms-of-service'
        },
        {
            title: 'Privacy Policy',
            link: '/privacy-policy'
        },
        {
            title: 'Cookie management',
            //link: '/cookie-management'
            link: routes.dashboardListVehicle.route
        },
    ]

    return (

        <RootStyle>

            <Container fluid="lg">
                <footer className="page-footer font-small blue pt-3 pb-2">

                    <Row>
                        <Col md={2} sm={6} xs={4} className="tsc-header__brand-top mb-3">
                            <div className="tsc-header__operator">
                                <Link to="/">
                                    <Logo
                                        className="tsc-responsive-img tsc-image-header"
                                        alt="TSC"
                                        src={FooterAppLogo}
                                    />
                                </Link>
                            </div>
                        </Col>
                        <Col className="d-block d-md-none d-xl-none m-auto" md={9} sm={6} xs={8}>
                            <p><small>&copy; {new Date().getFullYear()} {t("app.title")}</small></p>
                        </Col>
                        <Col md={10} sm={12} xs={12}>
                            <p className="d-none d-md-block d-xl-block m-auto"><small>&copy; {new Date().getFullYear()} {t("app.title")}</small></p>
                            <p><small>{t("app.footer.app-description")}</small></p>
                        </Col>

                    </Row>

                </footer>
            </Container>

        </RootStyle>
    )

}

export default Footer;