import { Alert, Badge, Button, Card, Col, Container, Form, InputGroup, Row, Stack, ToggleButton } from "react-bootstrap";
import { styled } from "styled-components";

// Icons
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { DEFAULT_DATE_FORMAT, DEFAULT_DATE_TIME_FORMAT, DEFAULT_HOUR_MINUTES_FORMAT, ROLES } from "../../utils/constants";
import { useLocation, useNavigate } from "react-router-dom";
import {
    CarFront,
    CheckAll,
    PersonLinesFill,
    ShieldExclamation,
    Search as SearchIcon,
    ChevronDoubleRight,
    ChevronDoubleLeft,
    SendCheck,
    Map as MapIcon,
    ChatRightText,
    InfoCircle,
} from "react-bootstrap-icons";
import FormControl from "../../components/@extends/react-bootstrap/FormControl";
import { KeyboardEvent, useEffect, useState } from "react";
import classNames from "classnames";
import { apiValidateNumberplate, createOffense, fetchAllOffenseTypes } from "../../services/offenseService";
import { Offense, OffenseType } from "../../utils/models/offense";
import { Location, Vehicle } from "../../utils/models/base";
import { Person } from "../../utils/models/user";
import { isNonEmpty } from "../../utils/stringUtils";
import { isNumber } from "../../utils/numberUtils";
import { searchAddressStates } from "../../services/referentielService";
import { AddressState } from "../../utils/models/referentiels";
import FormSelect from "../../components/@extends/react-bootstrap/FormSelect";
import AutocompleteSelect from "../../components/Autocomplete/AutocompleteSelect";
import { useUserContext } from "../../context/UserContext";

const OwnerColDetails = styled(Col)`
    margin: auto;
`;

const StepperNode = styled.div`
    & .node {
        margin: auto;
    }
    &.active .node {
        cursor: pointer;
        width: 45px;
        height: 45px;
        color: rgb(0, 0, 0);
        font-size: 16px;
        border-top-left-radius: 50%;
        border-top-right-radius: 50%;
        border-bottom-right-radius: 50%;
        border-bottom-left-radius: 50%;
        display: flex;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        align-items: center;
        background-color: rgb(255, 255, 255);
        border-top-width: 2px;
        border-right-width: 2px;
        border-bottom-width: 2px;
        border-left-width: 2px;
        border-top-style: solid;
        border-right-style: solid;
        border-bottom-style: solid;
        border-left-style: solid;
        border-top-color: rgb(51, 51, 51);
        border-right-color: rgb(51, 51, 51);
        border-bottom-color: rgb(51, 51, 51);
        border-left-color: rgb(51, 51, 51);
        position: relative;
    }
    &.inactive .node {
        cursor: pointer;
        width: 45px;
        height: 45px;
        font-size: 16px;
        border-top-left-radius: 50%;
        border-top-right-radius: 50%;
        border-bottom-right-radius: 50%;
        border-bottom-left-radius: 50%;
        display: flex;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        align-items: center;
        color: rgb(255, 255, 255);
        background-color: rgb(159, 157, 157);
        border-color: rgb(159, 157, 157);
    }

    &.close {
        & .node {
            cursor: pointer;
            width: 45px;
            height: 45px;
            font-size: 16px;
            border-radius: 50%;
            display: flex;
            -webkit-box-pack: center;
            justify-content: center;
            -webkit-box-align: center;
            align-items: center;
            background-color: rgb(51, 51, 51);
            color: rgb(255, 255, 255);
            border: 2px solid rgb(204, 204, 204);
        }

        & .trait span:nth-child(2) {
            background-color: rgb(102, 102, 102);
        }
    }

    &.complete {
        & .node {
            cursor: pointer;
            width: 45px;
            height: 45px;
            font-size: 16px;
            border-radius: 50%;
            display: flex;
            -webkit-box-pack: center;
            justify-content: center;
            -webkit-box-align: center;
            align-items: center;
            background-color: #198754;
            color: rgb(255, 255, 255);
            border: 2px solid #a3cfbb;
        }

        & .trait span {
            background-color: #0a3622 !important;
        }
    }

    & .trait {
        display: flex;
        width: 45px;
        flex-direction: column;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        align-items: center;
        margin-top: 10px;
        margin: auto;
    }

    & .trait span {
        margin-top: 2px;
        margin-right: 0px;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
        border-bottom-left-radius: 6px;

        &:nth-child(1) {
            background-color: rgb(102, 102, 102);
            width: 6px;
            height: 6px;
        }

        &:nth-child(2) {
            background-color: rgb(204, 204, 204);
            width: 6px;
            height: 50px;
        }
    }
`;
export default function OffenseManagement() {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [erros, setErrors] = useState<string | null>(null);

    const [step, setStep] = useState<number>(1);

    const [selectedOffenseTypes, setSelectedOffenseTypes] = useState<OffenseType[]>([]);
    const [offenseTypes, setOffenseTypes] = useState<OffenseType[]>([]);
    const [bookmarkedOffenseTypes, setBookmarkedOffenseTypes] = useState<OffenseType[]>([]);

    const [searchQueryNumberplate, setSearchQueryNumberplate] = useState("");
    const [vehicle, setVehicleInformation] = useState<Vehicle | null>(null);
    const [driver, setDriver] = useState<Person>({ firstName: "", lastName: "" });
    const [location, setLocation] = useState<Location>({
        address: "",
        state: { id: 0, label: "" },
        city: "",
        zip: null,
    });
    const [disableDriverForm, setDisableDriverForm] = useState(false);
    const [comments, setComments] = useState<string>("");
    const [offenseTime, setOffenseTime] = useState<string>(format(new Date(), DEFAULT_HOUR_MINUTES_FORMAT));

    const [offenseCreated, setOffenseCreated] = useState(false);
    const [offense, setOffense] = useState<Offense | null>(null);
    const [addressStates, setAddressStates] = useState<AddressState[]>([]);

    const [filteredOptions, setFilteredOptions] = useState<OffenseType[]>([]);
    const [allAvailableOffensesExceptBookmarks, setAllAvailableOffensesExceptBookmarks] = useState<OffenseType[]>([]);

    const steps = {
        STEP_VEHICLE: 1,
        STEP_OFFENSE_TYPE: 2,
        STEP_DRIVER: 3,
        STEP_LOCATION: 4,
        STEP_COMMENT: 5,
        STEP_OVERVIEW: 6,
    };

    const useLocationHook = useLocation();

    const offenseCompleted = (): boolean => {
        return offense != null && isNumber(offense.id) && offenseCreated;
    };

    const handleAddressSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedOption = event.target.selectedOptions[0];
        const optionValue = selectedOption.value || "0";
        const optionText = selectedOption.textContent || "";

        setLocation({
            ...location,
            state: { id: parseInt(optionValue), label: optionText },
        });
    };

    const handleSearchKeyUp = (event: KeyboardEvent<HTMLInputElement>): void => {
        if (event.code === "Enter") {
            validateNumberplate();
        }
    };

    useEffect(() => {
        try {
            fetchAllOffenseTypes()
                .then((response) => {
                    setOffenseTypes(response);
                })
                .catch((error) => {
                    //TODO Ramener à la page précédente ou page d'erreur
                    navigate("/error", {
                        replace: true,
                        state: { from: "offense", status: error.message },
                    });
                });

            searchAddressStates()
                .then((response) => {
                    setAddressStates(response);
                })
                .catch((error) => {
                    //TODO Ramener à la page précédente ou page d'erreur
                    navigate("/error", {
                        replace: true,
                        state: { from: "offense", status: error.message },
                    });
                });
        } catch (e) {
            //ERR
            //TODO Ramener à la page précédente ou page d'erreur
        }
    }, [navigate]);

    useEffect(() => {
        if (offenseTypes && offenseTypes.length > 0) {
            const updatedOffenseTypes = offenseTypes.filter((type) => type.bookmark);
            setBookmarkedOffenseTypes(updatedOffenseTypes);

            setFilteredOptions(offenseTypes);
        }
    }, [offenseTypes]);

    useEffect(() => {
        if (offenseTypes && offenseTypes.length > 0) {
            const updatedOffenseTypes = offenseTypes.filter((type) => !selectedOffenseTypes.includes(type));
            setFilteredOptions(updatedOffenseTypes);
        }
    }, [selectedOffenseTypes]);

    useEffect(() => {
        if (addressStates && addressStates.length > 0)
            setLocation({
                address: "",
                state: addressStates[0],
                city: "",
                zip: null,
            });
    }, [addressStates]);

    const stepStatus = (index: number) => {
        if (offenseCompleted()) {
            return "complete";
        }
        if (index < step) {
            return "close";
        } else if (index === step) {
            return "active";
        }
        return "inactive";
    };
    const goNextStep = () => {
        //handle Validate step
        setStep(step + 1);
    };

    const goPreviousStep = () => {
        //handle Validate step
        setStep(step >= 2 ? step - 1 : 1);
    };

    const submitForm = () => {
        createOffense({
            driver: disableDriverForm ? null : driver,
            location: location,
            offenseTypes: selectedOffenseTypes,
            numberplate: vehicle ? vehicle.numberplate : "",
            time: offenseTime,
            timeOffset: new Date().getTimezoneOffset(),
            date: format(new Date(), DEFAULT_DATE_FORMAT),
            comment: comments,
        })
            .then((response) => {
                setOffense(response);
                setOffenseCreated(true);
            })
            .catch((error) => {
                //TODO Ramener à la page précédente ou page d'erreur
            });
    };

    const handleSelectOffenseTypeChecked = (offenseType: OffenseType): boolean => {
        return selectedOffenseTypes.find((obj) => obj.id === offenseType.id) !== undefined;
    };

    const handleSelectOffenseType = (isNowCheck: boolean, offenseType: OffenseType) => {
        if (!isNowCheck) {
            const filteredList = selectedOffenseTypes.filter((obj) => obj.id !== offenseType.id);

            setSelectedOffenseTypes(filteredList);
        } else {
            const filteredList = [offenseType, ...selectedOffenseTypes];
            setSelectedOffenseTypes(filteredList);

            if (!bookmarkedOffenseTypes.includes(offenseType) && !allAvailableOffensesExceptBookmarks.includes(offenseType)) {
                const filteredList = [...allAvailableOffensesExceptBookmarks, offenseType];
                setAllAvailableOffensesExceptBookmarks(filteredList);
            }
        }
    };

    const validateNumberplate = () => {
        innerValidateNumberplate(searchQueryNumberplate);
    };
    const innerValidateNumberplate = (numberplate: string) => {
        if (numberplate)
            apiValidateNumberplate({ numberplate: numberplate })
                .then((response) => {
                    setVehicleInformation(response.vehicle);
                    setErrors(null);
                })
                .catch((error) => {
                    setErrors(t("page.offense.warning.unkonwn-numberplate"));
                });
    };

    // Fonction pour créer la Map regroupée par OffenseGroup
    const groupOffenseTypesByLevel = (types: OffenseType[]) => {
        const groupMap = new Map<string, OffenseType[]>();

        types.forEach((type) => {
            const groupKey = type.group.name;

            if (groupMap.has(groupKey)) {
                groupMap.get(groupKey)?.push(type);
            } else {
                groupMap.set(groupKey, [type]);
            }
        });

        return groupMap;
    };

    const isFormNotValid = (): boolean => {
        return (
            vehicle == null ||
            vehicle === undefined ||
            (!disableDriverForm && (!isNonEmpty(driver.lastName) || !isNonEmpty(driver.lastName))) ||
            !isNonEmpty(location.address) ||
            !isNonEmpty(location.state.label) ||
            !isNonEmpty(location.city) ||
            !isNonEmpty(offenseTime) ||
            selectedOffenseTypes.length === 0
        );
    };

    useEffect(() => {
        const vNumberplate: string = useLocationHook.state?.vNumberplate;
        if (vNumberplate != null) {
            setSearchQueryNumberplate(vNumberplate);
            innerValidateNumberplate(vNumberplate);
        }
    }, []);

    const { user } = useUserContext();
    const [creationBlocked, setCreationBlocked] = useState<boolean>(false);
    useEffect(() => {
        if (user && user.roles[0] === ROLES.ROLE_AREA_MANAGER) {
            setCreationBlocked(true);
        }
    }, [user]);

    return (
        <Container fluid="lg">
            <Row className="text-center mb-3">
                <OwnerColDetails>
                    <h1>{t("page.offense.mainTitle")}</h1>
                </OwnerColDetails>
            </Row>

            {creationBlocked && (
                <Row>
                    <Alert variant="warning" className="">
                        <InfoCircle className="me-1" />
                        {t("page.offense.warning.creationForAgentOnly")}
                    </Alert>
                </Row>
            )}

            <Row className="sub-body-container">
                <Col sm="2" md="1" className="border-end p-0 d-none d-sm-block">
                    <Stack className="mx-auto" direction="vertical" gap={3}>
                        <StepperNode className={classNames("mes-auto", stepStatus(steps.STEP_VEHICLE))}>
                            <div className="node">
                                <CarFront />
                            </div>
                            <div className="trait">
                                <span></span>
                                <span></span>
                            </div>
                        </StepperNode>

                        <StepperNode className={classNames("mes-auto", stepStatus(steps.STEP_OFFENSE_TYPE))}>
                            <div className="node">
                                <ShieldExclamation />
                            </div>
                            <div className="trait">
                                <span></span>
                                <span></span>
                            </div>
                        </StepperNode>

                        <StepperNode className={classNames("mes-auto", stepStatus(steps.STEP_DRIVER))}>
                            <div className="node">
                                <PersonLinesFill />
                            </div>
                            <div className="trait">
                                <span></span>
                                <span></span>
                            </div>
                        </StepperNode>

                        <StepperNode className={classNames("mes-auto", stepStatus(steps.STEP_LOCATION))}>
                            <div className="node">
                                <MapIcon />
                            </div>
                            <div className="trait">
                                <span></span>
                                <span></span>
                            </div>
                        </StepperNode>

                        <StepperNode className={classNames("mes-auto", stepStatus(steps.STEP_COMMENT))}>
                            <div className="node">
                                <ChatRightText />
                            </div>
                            <div className="trait">
                                <span></span>
                                <span></span>
                            </div>
                        </StepperNode>

                        <StepperNode className={classNames("mes-auto", stepStatus(steps.STEP_OVERVIEW))}>
                            <div className="node">
                                <CheckAll />
                            </div>
                        </StepperNode>
                    </Stack>
                </Col>

                <Col sm="10" md="11" className="">
                    <Alert variant="danger" dismissible show={erros != null} onClose={() => setErrors(null)}>
                        {erros}
                    </Alert>

                    {step === steps.STEP_VEHICLE && (
                        <>
                            <Row>
                                <h3 className="mb-2">
                                    <CarFront className="me-1 d-inline d-sm-none" />
                                    {t("page.offense.forms.vehicle.header.label")}
                                </h3>
                                <Form.Group className="mb-3" as={Col} md="12" sm={12} controlId="validationNumberplate">
                                    <Form.Label className="mb-0">
                                        {t("page.offense.forms.vehicle.field.numberplate.label")} <span className="required-asterisk">*</span>
                                    </Form.Label>
                                    <Form.Text className="text-muted mt-0 mb-1 d-block">
                                        {t("page.offense.forms.vehicle.field.numberplate.description")}
                                    </Form.Text>
                                    <InputGroup>
                                        <FormControl
                                            required
                                            type="text"
                                            name="numberplate"
                                            value={searchQueryNumberplate}
                                            onChange={(e) => setSearchQueryNumberplate(e.target.value.toUpperCase())}
                                            onKeyUp={handleSearchKeyUp}
                                        />

                                        <Button
                                            variant="primary"
                                            className="rounded-0"
                                            onClick={() => validateNumberplate()}
                                            disabled={!isNonEmpty(searchQueryNumberplate)}
                                        >
                                            <SearchIcon className="m-1" />
                                            {t("page.offense.forms.vehicle.submit.search.label")}
                                        </Button>
                                    </InputGroup>
                                </Form.Group>
                            </Row>

                            {vehicle && (
                                <>
                                    <hr />
                                    <Alert variant="light" onClose={() => setVehicleInformation(null)} dismissible>
                                        <Row>
                                            <Col>
                                                <label className="fw-bold">{t("business-domain.vehicle.model")}</label>
                                                <div>
                                                    <p className="mb-0 brand col-value">{vehicle.brand}</p>
                                                    <p className="small col-value">
                                                        {t("business-domain.vehicle.fiscal-horsepower.label")} : {vehicle.cvPower}{" "}
                                                        {t("business-domain.vehicle.fiscal-horsepower.unit")}
                                                    </p>
                                                </div>
                                            </Col>
                                            <Col>
                                                <label className="fw-bold">{t("business-domain.vehicle.registration")}</label>
                                                <div>
                                                    <p className="mb-0 col-value">
                                                        {t("business-domain.vehicle.numberplate")} : {vehicle.numberplate}
                                                    </p>
                                                    <p className="small col-value">
                                                        {t("business-domain.vehicle.first-registration")} :{" "}
                                                        {format(new Date(vehicle.firstRegistration), DEFAULT_DATE_FORMAT)}
                                                    </p>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Alert>
                                </>
                            )}

                            <Row className="mt-4">
                                <Col md={{ offset: 9, span: 3 }} className="text-end">
                                    <Button variant="dark" className="w-100 rounded-0 mb-2" onClick={() => goNextStep()} disabled={vehicle == null}>
                                        <ChevronDoubleRight className="m-1" />
                                        {t("page.offense.forms.submit.next.label")}
                                    </Button>
                                </Col>
                            </Row>
                        </>
                    )}

                    {step === steps.STEP_OFFENSE_TYPE && (
                        <>
                            <Row>
                                <h3 className="mb-2">
                                    <ShieldExclamation className="me-1 d-inline d-sm-none" />
                                    {t("page.offense.forms.offense-type.header.label")}
                                </h3>

                                <div>
                                    <AutocompleteSelect
                                        label={t("page.offense.forms.offense-type.field.types.label")}
                                        description={t("page.offense.forms.offense-type.field.types.description")}
                                        options={filteredOptions}
                                        onSelect={(itOffTp) => handleSelectOffenseType(true, itOffTp)}
                                    />

                                    {(bookmarkedOffenseTypes.length > 0 || allAvailableOffensesExceptBookmarks.length > 0) && (
                                        <Card className="mb-2">
                                            <Card.Body>
                                                <Row>
                                                    <div>
                                                        {bookmarkedOffenseTypes.map((itOffTp) => (
                                                            <ToggleButton
                                                                type="checkbox"
                                                                onChange={(e) => handleSelectOffenseType(e.currentTarget.checked, itOffTp)}
                                                                className="me-2 mb-3"
                                                                variant="outline-primary"
                                                                id={`checkbox${itOffTp.id}`}
                                                                key={`bk-${itOffTp.id}`}
                                                                value={itOffTp.id}
                                                                checked={handleSelectOffenseTypeChecked(itOffTp)}
                                                            >
                                                                {itOffTp.name}
                                                            </ToggleButton>
                                                        ))}
                                                    </div>
                                                </Row>
                                                {bookmarkedOffenseTypes.length > 0 && allAvailableOffensesExceptBookmarks.length > 0 && <hr />}
                                                <Row>
                                                    <div>
                                                        {allAvailableOffensesExceptBookmarks.map((itOffTp) => (
                                                            <ToggleButton
                                                                type="checkbox"
                                                                onChange={(e) => handleSelectOffenseType(e.currentTarget.checked, itOffTp)}
                                                                className="me-2 mb-3"
                                                                variant="outline-success"
                                                                id={`checkbox${itOffTp.id}`}
                                                                key={`sl-${itOffTp.id}`}
                                                                value={itOffTp.id}
                                                                checked={handleSelectOffenseTypeChecked(itOffTp)}
                                                            >
                                                                {itOffTp.name}
                                                            </ToggleButton>
                                                        ))}
                                                    </div>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    )}
                                </div>
                            </Row>

                            <Row className="mt-4">
                                <Col md={{ offset: 6, span: 3 }} className="text-end">
                                    <Button variant="outline-dark" className="w-100 rounded-0 mb-2" onClick={() => goPreviousStep()}>
                                        <ChevronDoubleLeft className="m-1" />
                                        {t("page.offense.forms.submit.previous.label")}
                                    </Button>
                                </Col>
                                <Col md={3} className="text-end">
                                    <Button
                                        variant="dark"
                                        className="w-100 rounded-0 mb-2"
                                        onClick={() => goNextStep()}
                                        disabled={selectedOffenseTypes.length === 0}
                                    >
                                        <ChevronDoubleRight className="m-1" />
                                        {t("page.offense.forms.submit.next.label")}
                                    </Button>
                                </Col>
                            </Row>
                        </>
                    )}
                    {step === steps.STEP_DRIVER && (
                        <>
                            <Row>
                                <h3 className="mb-2">
                                    <PersonLinesFill className="me-1 d-inline d-sm-none" />
                                    {t("page.offense.forms.offense-driver.header.label")}
                                </h3>

                                <Form.Group className="mb-3" as={Col} md="6" sm={12}>
                                    <Form.Label className="mb-0">{t("page.offense.forms.offense-driver.field.firstname.label")}</Form.Label>
                                    <Form.Text className="text-muted mt-0 mb-1 d-block">
                                        {t("page.offense.forms.offense-driver.field.firstname.description")}
                                    </Form.Text>
                                    <FormControl
                                        disabled={disableDriverForm}
                                        type="text"
                                        name="firstname"
                                        value={driver.firstName}
                                        onChange={(e) => setDriver({ ...driver, firstName: e.target.value })}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3" as={Col} md="6" sm={12}>
                                    <Form.Label className="mb-0">{t("page.offense.forms.offense-driver.field.lastname.label")}</Form.Label>
                                    <Form.Text className="text-muted mt-0 mb-1 d-block">
                                        {t("page.offense.forms.offense-driver.field.lastname.description")}
                                    </Form.Text>
                                    <FormControl
                                        disabled={disableDriverForm}
                                        type="text"
                                        name="lastname"
                                        value={driver.lastName}
                                        onChange={(e) => setDriver({ ...driver, lastName: e.target.value })}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3" as={Col} md="12" sm={12}>
                                    <Form.Check
                                        id="disableDriverForm"
                                        type="switch"
                                        label={t("page.offense.forms.offense-driver.field.no-driver.label")}
                                        checked={disableDriverForm}
                                        onChange={(e) => setDisableDriverForm(e.target.checked)}
                                    />
                                </Form.Group>
                            </Row>

                            <Row className="mt-4">
                                <Col md={{ offset: 6, span: 3 }} className="text-end">
                                    <Button variant="outline-dark" className="w-100 rounded-0 mb-2" onClick={() => goPreviousStep()}>
                                        <ChevronDoubleLeft className="m-1" />
                                        {t("page.offense.forms.submit.previous.label")}
                                    </Button>
                                </Col>
                                <Col md={3} className="text-end">
                                    <Button
                                        variant="dark"
                                        className="w-100 rounded-0 mb-2"
                                        onClick={() => goNextStep()}
                                        disabled={!disableDriverForm && (!isNonEmpty(driver.firstName) || !isNonEmpty(driver.lastName))}
                                    >
                                        <ChevronDoubleRight className="m-1" />
                                        {t("page.offense.forms.submit.next.label")}
                                    </Button>
                                </Col>
                            </Row>
                        </>
                    )}

                    {step === steps.STEP_LOCATION && (
                        <>
                            <Row>
                                <h3 className="mb-2">
                                    <MapIcon className="me-1 d-inline d-sm-none" />
                                    {t("page.offense.forms.offense-location.header.label")}
                                </h3>

                                <Form.Group className="mb-3" as={Col} md={12}>
                                    <Form.Label className="mb-0">
                                        {t("page.offense.forms.offense-location.field.address.label")} <span className="required-asterisk">*</span>
                                    </Form.Label>
                                    <Form.Text className="text-muted mt-0 mb-1 d-block">
                                        {t("page.offense.forms.offense-location.field.address.description")}
                                    </Form.Text>
                                    <FormControl
                                        className="py-3"
                                        type="text"
                                        name="address"
                                        value={location.address}
                                        onChange={(e) => setLocation({ ...location, address: e.target.value })}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3" as={Col} md={6}>
                                    <Form.Label className="mb-0">
                                        {t("page.offense.forms.offense-location.field.state.label")} <span className="required-asterisk">*</span>
                                    </Form.Label>
                                    <Form.Text className="text-muted mt-0 mb-1 d-block">
                                        {t("page.offense.forms.offense-location.field.state.description")}
                                    </Form.Text>
                                    <FormSelect className="w-100 py-3" name="state" onChange={handleAddressSelectChange}>
                                        {addressStates.map((state) => (
                                            <option key={state.id} value={state.id}>
                                                {state.label}
                                            </option>
                                        ))}
                                    </FormSelect>
                                </Form.Group>
                                <Form.Group className="mb-3" as={Col} md={6}>
                                    <Form.Label className="mb-0">
                                        {t("page.offense.forms.offense-location.field.city.label")} <span className="required-asterisk">*</span>
                                    </Form.Label>
                                    <Form.Text className="text-muted mt-0 mb-1 d-block">
                                        {t("page.offense.forms.offense-location.field.city.description")}
                                    </Form.Text>
                                    <FormControl
                                        className="py-3"
                                        type="text"
                                        name="city"
                                        value={location.city}
                                        onChange={(e) => setLocation({ ...location, city: e.target.value })}
                                    />
                                </Form.Group>
                            </Row>

                            <Row className="mt-4">
                                <Col md={{ offset: 6, span: 3 }} className="text-end">
                                    <Button variant="outline-dark" className="w-100 rounded-0 mb-2" onClick={() => goPreviousStep()}>
                                        <ChevronDoubleLeft className="m-1" />
                                        {t("page.offense.forms.submit.previous.label")}
                                    </Button>
                                </Col>
                                <Col md={3} className="text-end">
                                    <Button
                                        variant="dark"
                                        className="w-100 rounded-0 mb-2"
                                        onClick={() => goNextStep()}
                                        disabled={!isNonEmpty(location.state.label) || !isNonEmpty(location.city) || !isNonEmpty(location.address)}
                                    >
                                        <ChevronDoubleRight className="m-1" />
                                        {t("page.offense.forms.submit.next.label")}
                                    </Button>
                                </Col>
                            </Row>
                        </>
                    )}

                    {step === steps.STEP_COMMENT && (
                        <>
                            <Row>
                                <h3 className="mb-2">
                                    <ChatRightText className="me-1 d-inline d-sm-none" />
                                    {t("page.offense.forms.offense-additional-information.header.label")}
                                </h3>

                                <Form.Group className="mb-3" as={Col} md={12}>
                                    <Form.Label className="mb-0">
                                        {t("page.offense.forms.offense-additional-information.field.time.label")}{" "}
                                        <span className="required-asterisk">*</span>
                                    </Form.Label>
                                    <Form.Text className="text-muted mt-0 mb-1 d-block">
                                        {t("page.offense.forms.offense-additional-information.field.time.description")}
                                    </Form.Text>
                                    <FormControl
                                        className="w-100"
                                        name="date"
                                        type="time"
                                        value={offenseTime}
                                        onChange={(e) => setOffenseTime(e.target.value)}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3" as={Col} md={12}>
                                    <Form.Label className="mb-0">
                                        {t("page.offense.forms.offense-additional-information.field.comment.label")}
                                    </Form.Label>
                                    <Form.Text className="text-muted mt-0 mb-1 d-block">
                                        {t("page.offense.forms.offense-additional-information.field.comment.description")}
                                    </Form.Text>
                                    <FormControl
                                        className="w-100 textarea"
                                        as="textarea"
                                        name="comment"
                                        value={comments}
                                        onChange={(e) => setComments(e.target.value)}
                                        maxLength={249}
                                    />
                                </Form.Group>
                            </Row>

                            <Row className="mt-4">
                                <Col md={{ offset: 6, span: 3 }} className="text-end">
                                    <Button variant="outline-dark" className="w-100 rounded-0 mb-2" onClick={() => goPreviousStep()}>
                                        <ChevronDoubleLeft className="m-1" />
                                        {t("page.offense.forms.submit.previous.label")}
                                    </Button>
                                </Col>
                                <Col md={3} className="text-end">
                                    <Button
                                        variant="dark"
                                        className="w-100 rounded-0 mb-2"
                                        onClick={() => goNextStep()}
                                        disabled={!isNonEmpty(offenseTime)}
                                    >
                                        <ChevronDoubleRight className="m-1" />
                                        {t("page.offense.forms.submit.next.label")}
                                    </Button>
                                </Col>
                            </Row>
                        </>
                    )}

                    {step === steps.STEP_OVERVIEW && (
                        <>
                            <div>
                                <h3 className="mb-2">
                                    <CheckAll className="me-1 d-inline d-sm-none" />
                                    {t("page.offense.forms.offense-overview.header.label")}
                                </h3>

                                {vehicle && (
                                    <Alert variant="light">
                                        <Alert.Heading>
                                            <CarFront /> {t("page.offense.forms.vehicle.header.label")}
                                        </Alert.Heading>
                                        <hr />
                                        <Row>
                                            <Col>
                                                <label className="fw-bold">{t("business-domain.vehicle.model")}</label>
                                                <div>
                                                    <p className="mb-0 brand col-value">{vehicle.brand}</p>
                                                    <p className="small col-value">
                                                        {t("business-domain.vehicle.fiscal-horsepower.label")} : {vehicle.cvPower}{" "}
                                                        {t("business-domain.vehicle.fiscal-horsepower.unit")}
                                                    </p>
                                                </div>
                                            </Col>
                                            <Col>
                                                <label className="fw-bold">{t("business-domain.vehicle.registration")}</label>
                                                <div>
                                                    <p className="mb-0 col-value">
                                                        {t("business-domain.vehicle.numberplate")} : {vehicle.numberplate}
                                                    </p>
                                                    <p className="small col-value">
                                                        {t("business-domain.vehicle.first-registration")} :{" "}
                                                        {format(new Date(vehicle.firstRegistration), DEFAULT_DATE_FORMAT)}
                                                    </p>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Alert>
                                )}

                                <Alert variant="light">
                                    <Alert.Heading>
                                        <ShieldExclamation /> {t("page.offense.forms.offense-type.header.label")}
                                    </Alert.Heading>
                                    <hr />
                                    <Row>
                                        {Array.from(groupOffenseTypesByLevel(selectedOffenseTypes)).map(([group, types]) => (
                                            <div key={group}>
                                                <h6>{group}</h6>

                                                <ul className="ps-0">
                                                    {types.map((aType) => (
                                                        <Badge className="me-1 mb-2" key={aType.id} bg="danger">
                                                            {aType.name}
                                                        </Badge>
                                                    ))}
                                                </ul>
                                            </div>
                                        ))}
                                    </Row>
                                </Alert>

                                <Alert variant="light">
                                    <Alert.Heading>
                                        <PersonLinesFill /> {t("page.offense.forms.offense-driver.header.label")}
                                    </Alert.Heading>
                                    <hr />
                                    <Row>
                                        {disableDriverForm && (
                                            <Row>
                                                <Col>{t("page.offense.forms.offense-overview.driver.not-provided")}</Col>
                                            </Row>
                                        )}

                                        {!disableDriverForm && (
                                            <>
                                                <Col>
                                                    <label className="fw-bold">{t("page.offense.forms.offense-driver.field.firstname.label")}</label>
                                                    <div>
                                                        <p className="mb-0 brand col-value">{driver.firstName}</p>
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <label className="fw-bold">{t("page.offense.forms.offense-driver.field.lastname.label")}</label>
                                                    <div>
                                                        <p className="mb-0 brand col-value">{driver.lastName}</p>
                                                    </div>
                                                </Col>
                                            </>
                                        )}
                                    </Row>
                                </Alert>

                                <Alert variant="light">
                                    <Alert.Heading>
                                        <MapIcon /> {t("page.offense.forms.offense-location.header.label")}
                                    </Alert.Heading>
                                    <hr />
                                    <Row>
                                        <Col md={12}>
                                            <label className="fw-bold">{t("page.offense.forms.offense-location.field.address.label")}</label>
                                            <div>
                                                <p className="mb-0 brand col-value">{location.address}</p>
                                            </div>
                                        </Col>
                                        <Col md={12}>
                                            <label className="fw-bold">{t("page.offense.forms.offense-location.field.state.label")}</label>
                                            <div>
                                                <p className="mb-0 brand col-value">{location.state.label}</p>
                                            </div>
                                        </Col>

                                        <Col md={6}>
                                            <label className="fw-bold">{t("page.offense.forms.offense-location.field.city.label")}</label>
                                            <div>
                                                <p className="mb-0 brand col-value">{location.city}</p>
                                            </div>
                                        </Col>
                                    </Row>
                                </Alert>

                                <Alert variant="light">
                                    <Alert.Heading>
                                        <ChatRightText /> {t("page.offense.forms.offense-additional-information.header.label")}
                                    </Alert.Heading>
                                    <hr />
                                    <Row>
                                        <Col md={12}>
                                            <label className="fw-bold">
                                                {t("page.offense.forms.offense-additional-information.field.time.label")}
                                            </label>
                                            <div>
                                                <p className="mb-0 brand col-value">{offenseTime}</p>
                                            </div>
                                        </Col>
                                        <Col md={12}>
                                            <label className="fw-bold">
                                                {t("page.offense.forms.offense-additional-information.field.comment.label")}
                                            </label>
                                            <div>
                                                {comments ? (
                                                    <p className="mb-0 brand col-value">{comments}</p>
                                                ) : (
                                                    <p className="mb-0 brand col-value">
                                                        {t("page.offense.forms.offense-overview.additional-information.comment-not-provided")}
                                                    </p>
                                                )}
                                            </div>
                                        </Col>
                                    </Row>
                                </Alert>
                            </div>

                            <Row className="mt-4">
                                {!offenseCreated && (
                                    <>
                                        <Col md={{ offset: 6, span: 3 }} className="text-end">
                                            <Button variant="outline-dark" className="w-100 rounded-0 mb-2" onClick={() => goPreviousStep()}>
                                                <ChevronDoubleLeft className="m-1" />
                                                {t("page.offense.forms.submit.previous.label")}
                                            </Button>
                                        </Col>
                                        <Col md={3} className="text-end">
                                            <Button
                                                disabled={creationBlocked || isFormNotValid()}
                                                variant="dark"
                                                className="w-100 rounded-0 mb-2"
                                                onClick={() => submitForm()}
                                            >
                                                <SendCheck className="m-1" />
                                                {t("page.offense.forms.submit.finish.label")}
                                            </Button>
                                        </Col>
                                        {creationBlocked && (
                                            <Col xs={12} md={12} className="text-center">
                                                <Alert variant="warning">
                                                    <InfoCircle className="me-1" />
                                                    {t("page.offense.warning.creationForAgentOnly")}
                                                </Alert>
                                            </Col>
                                        )}
                                    </>
                                )}
                            </Row>

                            {offenseCreated && offense != null && (
                                <Alert variant="success">
                                    The fine has been reported for the vehicle registered as{" "}
                                    <span className="fw-bold">{offense.vehicle.numberplate}</span>, on the date of{" "}
                                    <span className="fw-bold">{format(new Date(offense.date), DEFAULT_DATE_TIME_FORMAT)}</span>, at the following
                                    address:{" "}
                                    <span className="fw-bold">
                                        {offense.address}, {offense.city}
                                        {offense.zip ? ` - ${offense.zip}` : ""} ({offense.state.label})
                                    </span>
                                    .
                                </Alert>
                            )}
                        </>
                    )}
                </Col>
            </Row>
        </Container>
    );
}
